import { theme } from '@theme';

export const pendingProductTile = {
  display: 'flex',
  alignItems: 'center',
  background: theme.palette.common.lightGrey6,
  boxShadow: '0 0 6px 0 rgba(4, 37, 87, 0.15)',
  borderRadius: '8px',
  padding: '17px',
  height: '100%',
  position: 'relative'
};

export const pendingProductTileProductName = {
  fontSize: '14px',
  '@media screen and (max-width: 770px)': {
    fontSize: '12px'
  },
  fontWeight: 600,
  color: theme.palette.common.darkGrey2,
  marginTop: '15px'
};

export const pendingProductTileFitWithContent = {
  maxWidth: 'calc(100% - 30px)'
};

export const pendingProductTileSkills = {
  marginTop: '5px',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'flex-start'
};

export const pendingProductTileTime = {
  color: theme.palette.common.darkBlue2,
  flex: 1,
  '> p': {
    fontWeight: 600,
    fontSize: '12px'
  },
  display: 'flex',
  alignItems: 'center'
};

export const pendingProductTileSkill = {
  display: 'flex',
  alignItems: 'center',
  '> p': {
    color: theme.palette.common.darkBlue2,
    fontSize: '12px',
    fontWeight: 400
  }
};

export const pendingProductTileAction = {
  marginTop: '25px',
  marginBottom: '15px',
  width: '145px',
  height: '32px',
  fontSize: '14px',
  '@media screen and (max-width: 400px)': {
    width: '138px',
    fontSize: '13px'
  }
};

export const CounterBox = {
  height: '20px',
  background: 'rgba(158, 0, 126, 0.1)',
  borderRadius: '4px',
  padding: '2px 5px',
  color: theme.palette.common.violet,
  fontSize: '12px',
  fontWeight: 700,
  position: 'absolute',
  right: '15px',
  top: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};
