import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { LoginWrapper } from './components/LoginWrapper/LoginWrapper';
import { Callback } from './components/Callback/Callback';
import { useEffect, useState } from 'react';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { AppContext } from './context/App.context';
import { AxiosResponse } from 'axios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Dashboard } from './components/Dashboard/Dashboard';
import {
  Auth,
  countryISO3ToISO2,
  ErrorSnackbar,
  EventTypes,
  extractAxiosErrorMessage,
  GlobalInterceptor,
  Loader,
  sopClient,
  User
} from '@barracuda/shared/src';
import { Head } from './components/Head/Head';
import { AdminPageWrapper } from './components/AdminPageWrapper/AdminPageWrapper';
import { GettingReadyPageWrapper } from './components/GettingReadyPageWrapper/GettingReadyPageWrapper';
import { HelpCenterWrapper } from './components/HelpCenterWrapper/HelpCenterWrapper';
import { PrivacyPolicy } from './components/PrivacyPolicy/PrivacyPolicy';
import { TermsOfUse } from './components/TermsOfUse/TermsOfUse';
import { CookiePolicy } from './components/CookiePolicy/CookiePolicy';
import ReactGA from 'react-ga4';
import { HomepageWrapper } from './components/HomepageWrapper/HomepageWrapper';
import { ExploreTestsWrapper } from './components/ExploreTests/ExploreTestsWrapper';
import './i18n';
import { JoinWrapper } from './components/JoinWrapper/JoinWrapper';
import { Language } from '@barracuda/shared/src/interface/language.interface';
import { DashboardUploadId } from './components/DashboardUploadId/DashboardUploadId';
import { OneTrustBanner } from './components/OneTrust/OneTrustBanner';
import { ResultsSurvey, ResultsSurveyVariant } from './components/ResultsSurvey/ResultsSurvey';
import { PageNotFound } from './components/PageNotFound/PageNotFound';
import { SUPPORTED_LANGUAGES } from './app.utils';
import { Initializer } from './components/Initializer/Initializer';
import { HeaderNavbar } from './components/HeaderNavbar/HeaderNavbar';
import { ProtectedLoggedInRoute } from './components/ProtectedLoggedInRoute/ProtectedLoggedInRoute';
import { HotjarTracker } from './components/HotjarTracker/HotjarTracker';
import { SideBarNavigation } from './components/SideBarNavigation/SideBarNavigation';
import { useOneTrust } from './hooks/useOneTrust';
import { sendSignedInGAEvent, setUpGa } from './utils/ga';
import { HelpCenter } from './components/HelpCenter/HelpCenter';
import { ActOnSpecifiedCommercialTransactions } from './components/ActOnSpecifiedCommercialTransactions/ActOnSpecifiedCommercialTransactions';

const detectPathNameLanguage = (): Language => {
  const storedLanguage = localStorage.getItem('language') as Language;
  const prefixesMap = SUPPORTED_LANGUAGES.reduce((prev, current) => {
    return prev.set(`/${current}`, current);
  }, new Map<string, Language>());
  const langKey = Array.from(prefixesMap.keys()).find((item) =>
    location.pathname.toLowerCase().startsWith(item.toLowerCase())
  );
  if (langKey) return prefixesMap.get(langKey) || storedLanguage || SUPPORTED_LANGUAGES[0];
  return storedLanguage || SUPPORTED_LANGUAGES[0];
};

const isQrCodeFlow = location.pathname.includes('verification');

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem('token') && !Auth.isTokenExpired(localStorage.getItem('token'))
  );
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User>();
  const [eCommerceCountryISO2, setECommerceCountryISO2] = useState<string>();
  const [error, setError] = useState<{ open: boolean; message?: string }>({
    open: false,
    message: ''
  });
  const [emailPreviewData, setEmailPreviewData] = useState({ company: '', email: '' });
  const [forceAccountExistMode, setForceAccountExistMode] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [cart, setCart] = useState();
  const [testTaken, setTestTaken] = useState(false);
  const [language, setLanguage] = useState<Language>(detectPathNameLanguage());
  const [availableTests, setAvailableTests] = useState<number>(0);
  const [resultsSurveyOpen, setResultsSurveyOpen] = useState(false);
  const [resultsSurveyVariant, setResultsSurveyVariant] = useState<ResultsSurveyVariant>();
  const [resultsSurveyLicenseId, setResultsSurveyLicenseId] = useState<string>();
  const [code, setCode] = useState<string | undefined>();
  const [ipCountry, setIpCountry] = useState<string | undefined>();
  const { hasActiveOneTrustGroup, oneTrustGroups } = useOneTrust();

  const fetchUser = async () => {
    try {
      const user: AxiosResponse<User> = await sopClient.getUserData();
      const { data } = user;
      const IPCountry = await sopClient.getUserIPCountry();
      if (data) {
        setUser(data);
        setIsLoggedIn(true);
        ReactGA.set({ userId: data.externalId });
        setECommerceCountryISO2(countryISO3ToISO2(data.countryOfResidence.isoCode || IPCountry));
      }
    } catch (err: any) {
      setIsLoggedIn(false);
      setError({ open: err.response?.status !== 401, message: extractAxiosErrorMessage(err) });
      localStorage.removeItem('token');
    }
  };

  useEffect(() => {
    setUpGa();

    if (isLoggedIn && !window.location.href.includes('/shares/')) {
      fetchUser();
    } else {
      ReactGA.event(EventTypes.WEBSITE_VISITED);
    }
  }, [localStorage]);

  useEffect(() => {
    if (user) {
      sendSignedInGAEvent(EventTypes.WEBSITE_VISITED, user.externalId);
    }
  }, [user]);

  useEffect(() => {
    if (user && isLoggedIn) {
      if (user?.countryOfResidence?.isoCode) {
        setECommerceCountryISO2(countryISO3ToISO2(user?.countryOfResidence?.isoCode));
      } else {
        sopClient
          .getUserIPCountry()
          .then((IPCountry) => setECommerceCountryISO2(countryISO3ToISO2(IPCountry)));
      }
    }
  }, [user, isLoggedIn]);

  const detectUserCountryByIP = async () => {
    const country = await sopClient.getUserIPCountry();
    setIpCountry(country);
  };

  useEffect(() => {
    detectUserCountryByIP();
  }, []);

  const handleRedirectCallback = (value: boolean) => {
    setIsLoggedIn(value);
    localStorage.removeItem('token');
  };

  const redirectToSignIn = (value = true) => {
    setForceAccountExistMode(value);
  };

  const onSurveySubmit = () => {
    setResultsSurveyOpen(false);
    setResultsSurveyVariant(undefined);
  };

  const OTConsentUpdate = () => {
    const performanceCookiesAllowed = hasActiveOneTrustGroup(oneTrustGroups.PERFORMANCE_COOKIES);
    ReactGA.gtag('consent', 'update', {
      analytics_storage: performanceCookiesAllowed ? 'granted' : 'denied'
    });
  };

  const subRoutes: JSX.Element = (
    <Routes>
      <Route path='' element={<HomepageWrapper />} />
      <Route path='explore-tests' element={<ExploreTestsWrapper />} />
      <Route path='getting-ready' element={<GettingReadyPageWrapper />} />
      <Route path='help-center' element={<HelpCenterWrapper />} />
      <Route path='help-center-new' element={<HelpCenter />} />
      <Route path='privacy-policy' element={<PrivacyPolicy />} />
      <Route
        path='act-on-specified-commercial-transactions'
        element={<ActOnSpecifiedCommercialTransactions />}
      />
      <Route path='terms-of-use' element={<TermsOfUse />} />
      <Route path='cookie-policy' element={<CookiePolicy />} />
      <Route path={'shares/:code/*'} element={<AdminPageWrapper setCode={setCode} />} />
      <Route path={'shares-mts/:code/*'} element={<AdminPageWrapper setCode={setCode} />} />
      <Route
        path='join'
        element={
          <ProtectedLoggedInRoute>
            <JoinWrapper
              setForceAccountExistMode={setForceAccountExistMode}
              forceAccountExistMode={forceAccountExistMode}
              key='join'
            />
          </ProtectedLoggedInRoute>
        }
      />
      <Route
        path='login'
        element={
          <ProtectedLoggedInRoute>
            <LoginWrapper
              setForceAccountExistMode={setForceAccountExistMode}
              forceAccountExistMode={forceAccountExistMode}
              key='login'
            />
          </ProtectedLoggedInRoute>
        }
      />
      <Route
        path='reset-password'
        element={
          <ProtectedLoggedInRoute>
            <LoginWrapper key='reset-password' />
          </ProtectedLoggedInRoute>
        }
      />
      <Route path='callback' element={<Callback />} />
      <Route
        path='dashboard/*'
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route path='verification' element={<DashboardUploadId />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );

  const createRoutes = SUPPORTED_LANGUAGES.map((lang, index) => (
    <Route key={`supported-languages-${index}`} path={`/${lang}/*`} element={subRoutes} />
  ));

  const oneTrustApiKey = process.env.REACT_APP_ONETRUST_API_KEY;
  return (
    <HelmetProvider>
      <HotjarTracker
        siteId={process.env.REACT_APP_HOTJAR_SITE_ID}
        version={process.env.REACT_APP_HOTJAR_VERSION}
      />
      {oneTrustApiKey && <OneTrustBanner apiKey={oneTrustApiKey} consentUpdate={OTConsentUpdate} />}
      <Head title={`${process.env.REACT_APP_GENERAL_PRODUCT_NAME}`} />
      {!document.location.pathname.includes('verification') && (
        <Helmet>
          <script type='text/javascript' src='/scripts/botsonic.js'></script>
        </Helmet>
      )}
      <GlobalInterceptor redirectToSignIn={redirectToSignIn} callback={handleRedirectCallback} />
      <Loader loading={loading} />
      <AppContext.Provider
        value={{
          isLoggedIn,
          setIsLoggedIn,
          user,
          setUser,
          error,
          setError,
          loading,
          setLoading,
          emailPreviewData,
          setEmailPreviewData,
          cart,
          setCart,
          testTaken,
          setTestTaken,
          eCommerceCountryISO2,
          setECommerceCountryISO2,
          language,
          setLanguage,
          isDrawerOpen,
          setDrawerOpen,
          availableTests,
          setAvailableTests,
          resultsSurveyOpen,
          setResultsSurveyOpen,
          resultsSurveyVariant,
          setResultsSurveyVariant,
          resultsSurveyLicenseId,
          setResultsSurveyLicenseId,
          ipCountry
        }}
      >
        <Router basename={process.env.PUBLIC_URL}>
          <Initializer />
          {!isQrCodeFlow && <HeaderNavbar code={code} />}
          <SideBarNavigation />
          <Routes>
            {createRoutes}
            <Route key='page-not-found' path='*' element={<PageNotFound />} />
          </Routes>
        </Router>
        <ErrorSnackbar error={error} handleClose={() => setError({ ...error, open: false })} />
        {resultsSurveyVariant && (
          <ResultsSurvey
            isOpen={resultsSurveyOpen}
            variant={resultsSurveyVariant}
            onSubmitted={onSurveySubmit}
            licenseId={resultsSurveyLicenseId}
          />
        )}
      </AppContext.Provider>
    </HelmetProvider>
  );
}

export default App;
