import { OrderCancelledPopup } from '../OrderCancelledPopup/OrderCancelledPopup';
import { DialogWrapper } from '@barracuda/shared/src';
import { useContext, useEffect, useState } from 'react';
import useCancelledOrder from '../../hooks/useCancelledOrder';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/App.context';

export const CancelledOrderWatcher = () => {
  const { user } = useContext(AppContext);
  const { t } = useTranslation();
  const [orderCancelledPopupOpen, setOrderCancelledPopupOpen] = useState(false);
  const [cancelledOrder, setCancelledOrder] = useState<string | undefined>(undefined);
  const { getCancelledOrderIds, removeOrderIdFromPending } = useCancelledOrder();

  useEffect(() => {
    const cancelledOrderInterval = setInterval(() => {
      if (orderCancelledPopupOpen) {
        return;
      }
      getCancelledOrderIds(user?.profile?.PENDING_ORDERS).then((ids) => {
        if (Array.isArray(ids)) {
          if (ids.length) {
            setCancelledOrder(ids[0]);
            setOrderCancelledPopupOpen(true);
          } else {
            clearInterval(cancelledOrderInterval);
          }
        } else {
          setCancelledOrder(undefined);
        }
      });
    }, 10_000);

    return () => {
      clearInterval(cancelledOrderInterval);
    };
  }, [user, orderCancelledPopupOpen]);

  const handleCloseOrderCancelledPopup = () => {
    setOrderCancelledPopupOpen(false);
    removeOrderIdFromPending(cancelledOrder, user?.profile?.PENDING_ORDERS);
  };

  if (!cancelledOrder) {
    return null;
  }

  return (
    <DialogWrapper
      key='orderCancelledPopup'
      title={t('cancelledOrder.title')}
      subtitle={t('cancelledOrder.caption')}
      open={orderCancelledPopupOpen}
    >
      <OrderCancelledPopup
        orderNumber={cancelledOrder}
        handleClose={handleCloseOrderCancelledPopup}
      />
    </DialogWrapper>
  );
};
