import { theme } from '../../styles/theme';
import { OrderStatuses } from '../../interface/common.interfaces';

export const ordersNotificationWrapper = {
  borderRadius: '4px',
  border: `1px solid ${theme.palette.common.lightGrey1}`,
  background: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-wrap',
  position: 'relative',
  zIndex: '100',
  marginBottom: '8px',
  paddingRight: '36px',
  minHeight: '40px',
  '@media screen and (min-width: 720px)': {
    marginRight: '1rem'
  },
  a: {
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
};

export const ordersNotificationPipe = (type: OrderStatuses.CANCELLED | OrderStatuses.PENDING) => ({
  width: '3px',
  backgroundColor:
    type === 'PENDING' ? theme.palette.common.darkGrey2 : theme.palette.common.darkRed,
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  position: 'absolute',
  left: '1px',
  top: '1px',
  bottom: '1px'
});

export const ordersNotificationClose = {
  position: 'absolute',
  right: '14px',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer'
};
