import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import {
  dashboardUploadIdFourthImageStyles,
  dashboardUploadIdPutYourIdTextTipsRecommendationText,
  dashboardUploadIdSubheader,
  dashboardUploadIdThirdImageStyles,
  dashboardUploadIdTipsWrapper
} from '../DashboardUploadId.styles';
import { Img } from '../../../../../shared/src/components/Primitive';
import missingDetailsImage from '../../../assets/images/idv-missing-details.png';
import blurryImage from '../../../assets/images/idv-blurry.png';
import partiallyCoveredImage from '../../../assets/images/idv-partially-covered.png';
import expiredImage from '../../../assets/images/idv-expired.png';
import leftArrowBack from '../../../assets/images/left-arrow-back.svg';
import { leftArrowBackStyle } from './DashboardUploadIdGuidelines.style';

export const DashboardUploadIdGuidelines: FC<{ onBackClick: () => void }> = ({ onBackClick }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: '24px', position: 'relative' }}>
      <Img src={leftArrowBack} sx={leftArrowBackStyle} onClick={onBackClick} />
      <Box sx={{ marginTop: '125px' }}>
        <Typography
          sx={[dashboardUploadIdSubheader, dashboardUploadIdPutYourIdTextTipsRecommendationText]}
        >
          {t('mobileUploadId.guidelines')}
        </Typography>
        <Typography sx={{ textAlign: 'center' }}>
          <Trans i18nKey='mobileUploadId.takeAClearPhoto' components={{ bold: <b /> }}></Trans>
        </Typography>
        <Box sx={dashboardUploadIdTipsWrapper}>
          <Box>
            <Img width={116} src={missingDetailsImage} />
            <Typography>{t('mobileUploadId.missingDetails')}</Typography>
          </Box>
          <Box>
            <Img width={116} src={blurryImage} />
            <Typography>{t('mobileUploadId.blurry')}</Typography>
          </Box>
        </Box>
        <Box sx={dashboardUploadIdTipsWrapper}>
          <Box sx={dashboardUploadIdThirdImageStyles}>
            <Img width={116} src={partiallyCoveredImage} />
            <Typography>{t('mobileUploadId.partiallyCovered')}</Typography>
          </Box>
          <Box sx={dashboardUploadIdFourthImageStyles}>
            <Img width={116} src={expiredImage} />
            <Typography>{t('mobileUploadId.expired')}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
