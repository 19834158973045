export const translationENG = {
  privacyPolicy: {
    header: 'Privacy policy',
    downloadButton: 'Download Privacy Policy',
    subheader: 'Review our Privacy Policy below',
    content: {
      header: 'Pearson Online Products Privacy Policy',
      description:
        "This privacy policy explains how the Pearson family (Pearson Education, Inc, Pearson Education Limited and their group companies that are listed here) use data. We have a consistent approach across our business where possible, but data collection and processing is sometimes different depending on the products and services that you use. That is why we have included privacy information in the user journeys of our products and services (e.g. “just in time notices” at the point of data collection) which should be read together with this policy so that you have a clear understanding of how we collect and use your ‘personal data’ and 'personally identifiable information' – which is information that can be used on its own or with other data to identify, contact, or locate a person (for example your name, nickname or an online identifier).",
      '1': {
        question: '1. Who Are We?',
        paragraph1: 'For any product and service, you can contact us at:',
        paragraph2: 'Data Protection Officer for the EU and UK: 80 Strand, London, WC2R 0RL',
        paragraph3:
          'EU Representative: Pearson Deutschland GmbH, Kaiserstraße 44, 60329 Frankfurt am Main, Germany',
        paragraph4Part1: 'You may also email us at ',
        paragraph4Link: 'dataprivacy@pearson.com',
        paragraph4Part2: '.',
        paragraph5:
          'The specific “controller” for a relevant product or service will be described in the terms of use for that product or service. For products and services in the United Kingdom and Europe, this is likely to be Pearson Education Limited or Pearson UK Limited.'
      },
      '2': {
        question: '2. What Personal Data do we collect?',
        paragraph1:
          'This will generally depend on the product or service so please see the just in time notices and any FAQs that can be accessed within that product and service.',
        paragraph2:
          'There are a few activities that we’d like to make you particularly aware of (and we will inform you when these processing activities are relevant). We collect:',
        bullet1:
          'Registration information that we use to set up an account (e.g., your name (and preferred name), country, city, date of birth, email address, username and password). For individuals over 18, information associated with the purchase of a product or service (e.g. postal address, phone number and payment information). This information is mandatory and necessary for us to fulfil the contract. There is additional optional registration information that can be provided, including gender, native language and we might also capture the reason you have signed up for a product or service – this is all for aggregated survey purposes.',
        bullet2:
          'Activity and usage information generated in the course of your using our products services, such as preferences, responses to interactive exercises, assessment activity and results, grades or scores. You can also upload a profile picture which would be used on certificates issued where relevant to the product or service.',
        bullet3:
          'Your government ID that you upload (e.g. passport / drivers licence) to extract your name, photograph and expiration date and compare this to a selfie you will be asked to take for the purpose of verifying the authenticity of your ID and matching it to the name and profile picture you give us when you create your profile. This relies on face matching technology using artificial intelligence to ensure that your photographs match but will be shared with Pearson’s team of human reviewers if a mismatch is identified (so you won’t be automatically denied access to our services)',
        bullet4:
          'We collect “special category data” through video recording of you, images of you, and taking audio recordings of your spoken responses and background noise taken during tests. We do this to collect your responses so that you can receive a score, but also to verify that the correct person is taking the test and for proctoring the test to check that you are not cheating. This includes use of face and voice matching technology provided by our service providers, using artificial intelligence to ensure that the test responses match the reference voice and photograph we have on file for you and that there aren’t any cheating “flags” raised during your test. This technology doesn’t involve real-time surveillance and flags do not track emotional behaviours like your facial movements during the test, but will track whether the recording matches against the data we hold on file for you based on objective pre-defined criteria. Pearson’s human reviewers will review any mismatch in IDs and/or where potential cheating has been identified and in those cases, we will not release a test completion certificate and will either (i) issue a free retest; or (ii) where cheating has clearly taken place and this has been confirmed through human review, we may suspend your account. You have the right to appeal any account suspensions, see our websites for our security requirements and policies.',
        bullet5:
          'Recordings including images of you, and the audio recordings of your spoken responses and background noise taken during your practice tests so that you can complete the test and receive a score and so that we can fully simulate the test environment for you during practice tests. Note that during these practice tests, we will not be using the technology mentioned above to verify your identity or to identify cheating flags.',
        bullet6:
          'Location information (including IP address) which we use to provide you with relevant content based on the country you are logging in from. Also other device and cookie information (where we have your permission if required by law), login data and device information to provide more personalized content better suited to your needs as a user. Please see the cookie policy accessible when you access the product or service for more information.',
        paragraph3:
          'Please note that where we use our service providers’ artificial intelligence technologies for matching during the test, they may use the data captured about you for the purposes of improving their technology. Where this activity takes place it will be signposted, and it is subject to their own privacy notice and we will link to that privacy notice.'
      },
      '3': {
        question: '3. What is the purpose and lawful basis for collecting personal data?',
        paragraph1:
          'Ultimately, we collect personal data for the purpose of bringing you the best possible experience when using our products and services helping you track your lifetime of learning – whether that be to facilitate account registration, communicate with you, market research and service improvement, personalising your experience, managing integrations with third party technologies and to comply with laws and regulations and defend and exercise our legal rights. Our basis for doing this is:',
        bullet1:
          'to <strong>perform a contract</strong> (e.g. to provide the product / services purchased or accessed);',
        bullet2:
          'with your <strong>consent</strong> (e.g. if you are over 18, for marketing activities), which can be withdrawn at any time; and/o',
        bullet3:
          'to comply with our <strong>legal obligations</strong> (e.g. we might have regulatory obligations to report or share information); and/or',
        bullet4:
          'for a variety of business purposes which are in our <strong>legitimate interests</strong> as a commercial provider of educational products and services (as described in further detail on a service-by-service basis via the just in time notices).',
        paragraph2:
          'If we process sensitive / special categories of personal data (for example biometrics or health data) this requires an additional lawful basis which will either be on the basis of <strong>substantial public interest</strong> (to prevent fraud or cheating) and on the basis of law which is appropriate to the aim pursued or your <strong>consent.</strong>'
      },
      '4': {
        question: '4. When do we collect information?',
        paragraph1: 'We collect your information in three key ways:',
        bullet1:
          '<strong>Directly</strong> when you (or your parent/guardian) provide information e.g. by registering to use a product or service, contact us (online or offline) or purchase a subscription for one of our products and services.',
        bullet2:
          '<strong>Directly</strong> when you interact with a product or service or when you engage with our emails.',
        bullet3:
          '<strong>Indirectly</strong> from specified third parties. This will depend on the product or service so please see the specific statements in the user journeys. This includes information from schools / similar institutions (if you have a school access code to access one of our products or services). It also includes information from Google where you sign-up using a Google account for authentication purposes. We may also obtain your data from third parties so we can update, confirm or add to the information we collect, such as the validation of postal addresses or credit card information.',
        paragraph2:
          '<strong>Note:</strong> some of our products and services are available for children (which we define as individuals under 18). If that is the case, we will present information on our data processing in a child friendly way or ask the parent / guardian to explain the relevant points where they sign-up on their behalf.'
      },
      '5': {
        question:
          '5. How long will we store your personal data or personally-identifiable information?',
        paragraph1:
          'We shall be storing your information as long as is reasonably necessary to fulfil the purposes for which we are processing your personal data, which may be for the entire duration you use our products and services and a reasonable period after (in general after a year or so of inactivity we will email users that their account will be deleted after six months if there is no further activity). There are some shorter periods, e.g. we store certain images and audios for six months after a test is completed. We will always retain for longer if the law requires this e.g. invoices and certain payment information for 6 years plus the relevant financial year in which the invoice was issued. This will depend on the product or service so please see the FAQs that can be accessed within that product and service.',
        paragraph2:
          'Wherever possible, we anonymise personal information (so that no individual learner is likely to be identified, either directly or indirectly) in order to analyse types of learner, how our products and services are used, to help with strategic development, to conduct educational research and to develop new products and services and improve existing ones.'
      },
      '6': {
        question: '6. Disclosures and transfers',
        paragraph1: 'Disclosures to Group Companies',
        paragraph2:
          'We may disclose personal data or personally-identifiable information to other companies within the Pearson family, so that we can analyse, test and develop new exciting features for our products and services. With your consent (where required by law), we would share your personal data with the Pearson family so that they can inform you about the relevant products and services that our group companies offer - these would only be products and services which we think you would be interested in.',
        paragraph3: 'Disclosures to Businesses, Partners and Trusted Organisations',
        paragraph4:
          'For certain products and services, we work closely with employers and educational institutions who are making the service available to staff and learners. They may have their own privacy notices for their processing of your personal data as a controller, so please make sure you request this and read them.',
        paragraph5: 'Other Disclosures',
        paragraph6:
          'We may disclose personal data or personally-identifiable information in the following circumstances:',
        bullet1:
          'when we share it with external providers and data processors such as data centres, web hosts, cloud storage and cloud software providers, customer support providers, payment processors, debt collectors, accountants, and insurers;',
        bullet2:
          'when we share it with prospective sellers or buyers of our business or assets (and their professional advisors) to investors and our professional advisors, including in the event that we experience financial difficulties;',
        bullet3:
          'for learners under 18, with the parent / guardian that purchased access to the product or service on behalf of the learner;',
        bullet4:
          'when you give us consent or request us to share your data (for example, to send certificates confirming completion of a test to an employer and/or institution or as part of a virtual classroom so they can provide feedback on assessments. This data sharing can be revoked by users at any time); and',
        bullet5:
          'when we are required or requested to share it with regulators, law enforcement, courts and other relevant parties for the purpose of enforcement action, legal or contractual compliance and reporting purposes. Also to enforce our terms of use, to ensure your safety and that of other learners and to protect our right and property or those of others.',
        paragraph7: 'Data transfers',
        paragraph8:
          'We are part of a global organisation and use global service providers. Accordingly, data may be processed and shared outside of the country in which you live, however, we will only do this in compliance with local laws and where we have appropriate measures to ensure that the recipient of your data the same standard of protection as required by applicable data protection laws.',
        paragraph9:
          'Where you are a resident of the European Union or the United Kingdom, we will only transfer your personal data: (a) to countries that have been deemed to provide an adequate level of protection by the European Commission for personal data listed here (or equivalent in the United Kingdom); and/or (b) where we have specific contracts approved by the European Commission and information Commissioner’s Office which give personal data the same protection.'
      },
      '7': {
        question: '7. What right do I have?',
        paragraph1:
          'Depending on the country you are in, you may have different rights in relation to your personal data.',
        paragraph2: 'Where you are a resident of the European Union or the United Kingdom:',
        paragraph3:
          '<strong>RIGHT TO OBJECT TO PROCESSING.</strong> You can object to processing of your personal data where we are relying on a legitimate interest at any time. If you object, we must stop that processing unless we can demonstrate compelling legitimate grounds for the processing that override your interests, rights and freedoms, or we need to process the personal data for the establishment, exercise or defence of legal claims. Where we rely upon legitimate interest as a basis for processing we believe that we can demonstrate such compelling legitimate grounds, but we will consider each case on an individual basis. You can also object to processing of your personal data for direct marketing purposes at any time. If you object, we will stop that processing.',
        bullet1:
          '<strong>Right of access.</strong> You can ask us for copies of your personal data. There are some exemptions, which means you may not always receive all the information we process. These exemptions include situations where the information requested also includes personal data about somebody else.',
        bullet2:
          '<strong>Right to rectification.</strong> You can ask us to rectify information you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.',
        bullet3:
          '<strong>Right to erasure.</strong> You can ask us to erase your personal data in certain circumstances.',
        bullet4:
          '<strong>Right to restriction of processing.</strong> You can ask us to restrict the processing of your information in certain circumstances',
        bullet5:
          '<strong>Right to withdraw consent.</strong> Where we are relying on consent to process your personal data, you can withdraw it at any time. This will not affect the lawfulness of any processing carried out before you withdraw your consent.',
        bullet6:
          '<strong>Right to data portability.</strong> This only applies to information you have given us. You can ask that we transfer the information you gave us from one organisation to another, or give it to you. The right only applies if we are processing information based on performance of a contract and/or your consent.',
        paragraph4:
          'You are not required to pay any charge for exercising your rights, although we may charge a reasonable fee if your request is unfounded, repetitive or excessive. We have one month to respond to you (unless you have made a number of requests or your request is complex, in which case we may take up to an extra two months to respond but we will inform you where this is the case). Where we ask you for proof of identification, the one-month time limit does not begin until we have received this. If we require any clarification on the scope of the request, the one-month deadline is paused until we receive that information.',
        paragraph5:
          'You also have the right to make a complaint at any time to a supervisory authority, including your local authority. Of course, we would appreciate the chance to deal with your concerns before you approach a supervisory authority.',
        paragraph6:
          'California residents should read our Supplemental Privacy Statement for California Residents in conjunction with this Privacy Notice which explains our commitment to comply with California law for personal information of California residents.'
      },
      '8': {
        question: '8. Changes to this privacy policy',
        paragraph1:
          'We may update this policy or our specific statements from time to time. We will always include the date of a new version so that you know when there has been a change. Where required by law, we will notify you of any material changes.',
        paragraph2: 'This policy was last updated 06 December 2022.'
      }
    }
  },
  termsOfUse: {
    header: 'Terms of Use',
    downloadButton: 'Download Terms of Use',
    subheader: 'Review our Universal Terms of Service and End User License Agreement',
    preSection: {
      header: 'Universal Terms of Service and End User License Agreement',
      paragraph1:
        'By clicking "I accept" or "I agree" or using the Services, you are entering into this Universal Terms of Service and End User License Agreement (“UTOS”), which is a binding contract with Pearson Education, Inc. for the benefit of itself and its affiliates (collectively, “we,” “us,” or "Pearson").',
      paragraph2:
        'These UTOS set forth the general terms and conditions of your use of this website and all related Services. By accessing or using the Services you agree to the terms of the UTOS, and represent that you: (1) have read and understood the terms; (2) agree to use the Services in compliance with the UTOS, all applicable local, state, national, and international laws and regulations, and the academic rules or policies of your Institution; and (3) are an Authorized User. You may not use the Services if you do not agree with the UTOS or if you are not an Authorized User. If you have any questions about these UTOS, please contact us at <a target="_blank" href="https://pearson.tfaforms.net/3048">Pearson Support</a>.',
      paragraph3:
        'The section headings and italicized summaries contained below are for your convenience only, and do not have any legal meaning or effect.'
    },
    content: {
      '1': {
        question: '1. Data privacy, collection, & use',
        paragraph1:
          "Pearson's data collection and use practices for the Services are explained in the Privacy Notice applicable to each Service. We will collect, process and use your personal information in accordance with the applicable Privacy Notice and the applicable laws and regulations."
      },
      '2': {
        question: '2. We may occasionally need to change the terms of these UTOS',
        paragraph1:
          'We may occasionally need to update the rules in these UTOS. But if we make meaningful changes, we’ll let you know. By continuing to use the Services, you agree to follow the updated rules.',
        paragraph2:
          'Pearson may make changes to these UTOS from time to time for any reason. Typically, these changes are made to conform to current practices, to comply with changing regulatory requirements, or for other similar purposes. If Pearson modifies these UTOS in a manner that materially changes the terms or scope of the Services made available to you (“<strong>Service Change</strong>”), we will make reasonable efforts to notify you by either contacting you via email or posting reasonable notice in connection with the Services to alert you to such changes. Your continued use of the Services after reasonable notice of such changes to the UTOS has been provided will constitute your consent to the revised UTOS terms, which shall apply to any use of the Services following reasonable notice. If you deem any Service Change to have a material impact on you, you: (1) must so notify Pearson at <a target="_blank" href="https://pearson.tfaforms.net/3048">Pearson Support</a> within thirty days after receiving notice of such change; and (2) may terminate your Subscription and your use of the Services. If you have any questions about these UTOS, please contact us at <a target="_blank" href="https://pearson.tfaforms.net/3048">Pearson Support</a>.'
      },
      '3': {
        question: '3. Your license to use the Services',
        paragraph1:
          'Please enjoy using the features and functionality of the Services for your own personal educational purposes (as permitted by these UTOS) until your Subscription ends.',
        paragraph2:
          'Pearson grants to you a limited, non-transferable, non-exclusive, non-sublicensable license to use the Services for their intended purpose and subject to the terms and restrictions set forth in these UTOS, the AUP, the applicable Privacy Notices, and any additional terms that may be established by your Institution or Third-Party Service.',
        paragraph3:
          'You may use, display, and, when such functions are available on the Services, reformat, download, and print, Materials obtained through the Services solely for your own personal and non-commercial educational purposes.',
        paragraph4:
          'You are responsible for meeting the then-current hardware, operating system, Internet browser, and other technical requirements necessary to properly use and access the Services. All rights not specifically granted herein are reserved by Pearson. You acknowledge that the license granted under these UTOS does not provide you with title to or ownership of the Services, or the Materials contained therein, but only a right of limited use subject to the terms and conditions of these UTOS.'
      },
      '4': {
        question: '4. What we mean by the “Services”',
        paragraph1:
          'By “Services,” we mean Pearson’s websites, digital products, and everything that powers them. While Pearson or its licensors own them, we want you to use and enjoy the Services according to these rules.',
        paragraph2:
          'This website and its related applications, products, services, technology, content, and other intellectual property (collectively the "<strong>Services</strong>") are owned or licensed by Pearson or its licensors. All websites, course materials, and other content provided through the Services (collectively, "<strong>Materials</strong>"), and any supporting software, applications, and systems (collectively "<strong>Applications</strong>") are the exclusive property of Pearson and/or its licensors. The Services may be delivered to you through the Internet via your browser or app (mobile or otherwise) and may include enrollment ("<strong>Enrollment</strong>") in one or more online courses ("<strong>Course(s)</strong>") provided through the Services for use in connection with classes and programs offered by your school, employer, university, or other educational institution ("<strong>Institution</strong>"). While your User Account may continue for an indefinite period of time during which you may enroll in other Courses, your access to those specific Courses provided through the Services is provided on a subscription basis for a limited period of time (each a "<strong>Subscription</strong>"). The Services may be provided directly by Pearson or accessed through a third-party integration with accounts managed by your Institution or an integrated third-party service provider ("<strong>Third-Party Service</strong>"). The Services may link to or provide options to access third-party websites or applications.'
      },
      '5': {
        question: '5. Who is authorized to use the Services?',
        paragraph1:
          'To use certain Services, you’ll need to create a Pearson account. But don’t create an account or self-register for the Services if you are a minor, UNLESS you’re enrolled in college.',
        paragraph2:
          'The Services are available only to individuals, including but not limited to administrators or other representatives of Institutions, and students, who have gained lawful access to the Services directly from Pearson or its Third-Party Service provider or through the Institution with which such individual is affiliated ("<strong>Authorized User</strong>"). In order to access certain Services, you may be required to register for a user account ("<strong>User Account</strong>") by providing your first name, last name, valid email address (and in the case of Enrollments, your Institution and Course name or code), and designating a secret and unique username and password ("<strong>Account Credentials</strong>"), as well as any additional information that may be required by your Institution. In some cases, your User Account may already be established for you by your Institution, or by way of direct access from an integrated Third-Party Service, such as a learning management system, each of which may have additional terms of use or requirements for account access. Pearson may use your Account Credentials to validate your account prior to providing access to the Services each time you access the Services.',
        paragraph3:
          'You may have only one active User Account at any given time. You agree to provide accurate and truthful information when creating a User Account and to promptly update such information should it change.',
        paragraph4:
          'You may not self-register for or otherwise access the Services if you are under the age of 13 unless your parent or legal guardian has established an account or registered on your behalf. If you are under the age of majority pursuant to the laws in your jurisdiction (typically under the age of 18), you may not register or use the Services without first obtaining permission from your parent or guardian. Where a parent or legal guardian registers or establishes a User Account on your behalf as a minor they will be contracting with Pearson on your behalf until you reach the applicable age of majority in your jurisdiction, at which point the contract will transfer to you. However, if you are under the applicable age of majority and enrolled at an institution of higher education, you may self-register for the Services.',
        paragraph5:
          'Pearson reserves the right, in its sole and absolute discretion, to suspend or terminate your User Account, and your right to use or otherwise access any Service is automatically terminated, if you violate these UTOS or the <a target="_blank" href="https://loginstatic.pearson.com/html/AcceptableUsePolicy.html">AUP</a>. Furthermore, Pearson also reserves the right to discontinue, temporarily or permanently, the Services, or any part of the Services at any time.'
      },
      '6': {
        question: '6. Acceptable use of the Services',
        paragraph1:
          'Use of the Services is subject to Pearson’s <a target="_blank" href="https://loginstatic.pearson.com/html/AcceptableUsePolicy.html">Acceptable Use Policy</a> (“AUP”). By accessing or using the Services you agree to comply with the terms of the AUP. Pearson reserves the right, at its sole discretion, to suspend or terminate access to and use of the Services with respect to any user that Pearson reasonably believes has undertaken, or participated in, any activities that violate the terms of AUP, or whose actions have, or are likely to, otherwise cause harm to Pearson, the Services, the Service Network (as defined in the AUP), or other users, or for any other reason at the sole and absolute discretion of Pearson. Additionally, Pearson may suspend or terminate your access at any time at the request of your Institution.'
      },
      '7': {
        question: '7. Please keep your account credentials secret & safe',
        paragraph1:
          'You agree to: (1) keep your Account Credentials secret; (2) not let others log into your User Account; and (3) let us know if someone else gets hold of your Account Credentials.',
        paragraph2:
          'Your User Account is for your personal use only. Unless we provide an approved mechanism for such use, allowing others to access or use the Services through your User Account is strictly prohibited and you are responsible for all uses of the Services associated with your User Account, whether the use is made by you personally or by a third party.',
        paragraph3:
          'In order to protect the security of your Account Credentials and the Services, it is important that you maintain the confidentiality of your Account Credentials. You agree not to: (1) use Account Credentials other than your own; or (2) to disclose your Account Credentials to any third party or service, other than an authorized Third-Party Service. You agree to take reasonable steps to protect the secrecy of your Account Credentials and immediately notify Pearson at <a target="_blank" href="https://pearson.tfaforms.net/3048">Pearson Support</a> of any known or suspected loss, disclosure, or theft of your Account Credentials and/or access codes you obtain in connection with the Services.'
      },
      '8': {
        question: '8. Pearson or its licensors own the Services',
        paragraph1:
          'While we or our licensors own the Services and all related intellectual property, we want you to use and enjoy them as explained in these UTOS.',
        paragraph2:
          'All websites, course materials, and other content provided through the Services (collectively, "<strong>Materials</strong>"), and any supporting software, applications, and systems (collectively "<strong>Applications</strong>") are the exclusive property of Pearson and/or its licensors, and are protected by U.S. and international copyright and other intellectual property laws. All rights not expressly granted herein are reserved. Except as may be set forth in these UTOS or the <a target="_blank" href="https://loginstatic.pearson.com/html/AcceptableUsePolicy.html">AUP</a>, the reproduction, redistribution, modification, publication, or adaptation of Materials or Applications, in whole or in part, without the express written consent of Pearson and/or its licensors is strictly prohibited. The Services may allow you to copy or download certain Materials, but please remember that the availability of this functionality does not mean that the above restrictions do not apply.',
        paragraph3:
          'Unless otherwise indicated, trademarks, service marks, and trade names (collectively "<strong>Marks</strong>") that appear on the Services are the property of Pearson or its licensors. Any trademarks not owned by Pearson that appear in the Services are the property of their respective owners. You agree not to misuse or disparage any Marks associated with the Services or use the Marks (or any confusingly similar marks) in such a way that would misrepresent the ownership of the Marks or otherwise confuse the public as to the source or origin of any products or services. You should not use any Mark without obtaining the written consent of the Mark owner, using appropriate notice and attribution of the owner\'s trademark rights, and using the Marks in accordance with applicable usage guidelines as provided by a Mark’s respective owner. Any such permitted use of the Marks by you shall be to the benefit of the respective Mark owner.'
      },
      '9': {
        question: '9. User suggestions will be owned by Pearson',
        paragraph1:
          'If you suggest ways we can improve the Services, Pearson will own all intellectual property rights in your submissions, and may use your ideas without compensation.',
        paragraph2:
          'Pearson always welcomes suggestions and comments regarding the Services. Suggested improvements, additions, or other comments may be submitted by you to Pearson, whether through the Services, online, offline, verbally, or in writing ("<strong>User Suggestions</strong>"). Regardless of the manner of submission, you hereby acknowledge that all right, title, and interest and any other intellectual property rights (including but not limited to the rights set forth in Articles 27 and 28 of Copyright Act in Japan) in the User Suggestions shall become the exclusive property of Pearson and may be used for its business purposes at Pearson’s sole and absolute discretion without any payment or accounting to you. This policy is intended to avoid the possibility of future misunderstandings when products and services developed by Pearson might seem to others to be similar to their own User Suggestions.'
      },
      '10': {
        question: '10. How we’ll use any content you upload',
        paragraph1:
          'You agree that we may use any content you upload to the Services (in accordance with the applicable Privacy Notice, the applicable laws and regulations, of course), and that you have authority to grant us that right.',
        paragraph2:
          'The Services may provide functionalities for Authorized Users to create, upload, or post questions, responses, comments, ideas, articles, information, data, text, multimedia content, chat conversations or logs, messages, and other materials or submissions ("<strong>User Content</strong>"). By creating, posting, uploading, or linking to User Content, you grant to Pearson a perpetual, royalty-free, worldwide right and license to use, host, license, store, reproduce, display, transmit, adapt, and distribute such User Content and any derivative works created from such User Content (such as those resulting from changes we make so that your User Content works better with the Services) in connection with the Services. Further, you acknowledge and agree that your User Content shall be available to other users on the same terms as granted by you to Pearson under these UTOS and specifically this Section 10. Pearson shall not be responsible or liable for the deletion, destruction, damage, loss, or failure to store any User Content; provided, however, that, this shall not apply if above deletion, destruction, damage, loss, or failure to store any User Content is caused by Pearson’s willful misconduct or negligence. By posting User Content that is owned by third parties, you represent that you have been granted the right to grant to Pearson the right to make such User Content available to users to the same extent as the Materials provided with the Services. You agree to provide proper copyright notices in connection with any User Content in which you or a third party assert a right of copyright. Additional terms and options may be presented through the user interface available on the Services for posting or uploading User Content, which shall supplement and have the same force and effect as the terms of these UTOS.'
      },
      '11': {
        question: '11. Access to third-party websites & content is at your own risk',
        paragraph1:
          'If the Services link or redirect you to non-Pearson websites or services, you agree to use them at your own risk.',
        paragraph2:
          'You may have linked to the Services from a third-party website and/or the Services may provide links to other third-party websites or resources that are outside of Pearson’s control (collectively, "<strong>Third-Party Sites</strong>"). Pearson assumes no responsibility for third-party content ("<strong>Third-Party Content</strong>"), services, or applications that may be accessed by way of links on the Services to Third-Party Sites. Pearson does not endorse or guarantee the accuracy, integrity, or quality of Third-Party Sites or Third-Party Content and disclaims all liability for any errors, omissions, violation of third-party rights, or illegal conduct arising from such content or sites. The inclusion of a link to any Third-Party Site in the Services does not imply that the owners of such Third-Party Sites have sponsored or endorsed the Services. Pearson is not responsible for the availability of Third-Party Sites accessed through links from the Services, and any such Third-Party Sites are not covered by these UTOS or any other Pearson terms of policies. Should you discover that a link to a Third-Party Site is no longer functional please contact us at <a target="_blank" href="https://pearson.tfaforms.net/3048">Pearson Support</a>. Any links which directs users to inappropriate content or Prohibited User Content should be reported to us at <a href="mailto:pearsondmca.agent@pearson.com">pearsondmca.agent@pearson.com</a>.'
      },
      '12': {
        question: '12. How to submit a DMCA notice',
        paragraph1:
          'If you have any copyright concerns about content available via the Services, or wish to submit a DMCA notice, please read the below for detailed instructions.',
        paragraph2:
          'If you have any copyright concerns about any materials posted on the Services by others, or otherwise want to report inappropriate content on the Services, please let us know. We comply with the provisions of the Digital Millennium Copyright Act applicable to Internet service providers (17 U.S.C. Section 512). Unless otherwise stated in any specific DMCA designation provided by Pearson, please provide us with written notice ("<strong>Notice</strong>") by contacting our Designated Agent at the following address:',
        paragraph3: 'DMCA Designated Agent',
        paragraph4: 'Pearson Education, Inc.',
        paragraph5: '221 River Street',
        paragraph6: 'Hoboken, NJ 07030',
        paragraph7:
          'email: <a href="mailto:pearsondmca.agent@pearson.com">pearsondmca.agent@pearson.com</a>',
        paragraph8:
          'If you are a copyright owner or authorized agent of a copyright owner and believe in good faith that copyrighted work has been copied, adapted, reproduced, or exhibited through the Services in a manner that constitutes copyright infringement, you may submit written notification of the claimed infringing activity to our Designated Agent. To be effective, the Notice must include the following:',
        bullet1:
          'A physical or electronic signature of the owner, or a person authorized to act on behalf of the owner ("<strong>Complaining Party</strong>"), of an exclusive right that is allegedly being infringed upon; Information reasonably sufficient to permit Pearson to contact the Complaining Party, such as an address, telephone number, and if available, an electronic mail address;',
        bullet2:
          'Identification of the allegedly infringing material on the Services ("<strong>Infringing Material</strong>"), and information reasonably sufficient to permit Pearson to locate such material on the Services; Identification of the copyrighted work claimed to have been infringed upon ("<strong>Infringed Material</strong>"), or if multiple copyrighted works on the Services are covered by a single Notice, a list of each copyrighted work claimed to have been infringed (please be specific as to which Infringing Material is infringing on which Infringed Material);',
        bullet3:
          'A statement that the Complaining Party has a good faith belief that use of Infringing Material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and',
        bullet4:
          'A statement that the information in the Notice is accurate, and, under penalty of perjury, that the Complaining Party is the owner or is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.'
      },
      '13': {
        question: '13. How to report other prohibited content',
        paragraph1:
          'Please notify us of any content you find in the Services that is infringing, offensive, or is otherwise prohibited under these UTOS or the AUP. We don’t want it here either.',
        paragraph2:
          'Pearson also encourages you to report to <a href="mailto:pearsondmca.agent@pearson.com">pearsondmca.agent@pearson.com</a> any Prohibited User Content. To ensure Pearson can quickly respond to the issue, your email should include: the originating Pearson page URL; the linked page URL; a description of the content in question and the basis upon which you believe the content is prohibited or otherwise inappropriate; and the contact information through which Pearson may best reach you if you are willing to further assist Pearson in its investigation.'
      },
      '14': {
        question: '14. Availability of the Services',
        paragraph1:
          'You understand that the Services: (1) need Internet access to work; (2) may occasionally be offline for maintenance or emergencies; and (3) have features and functionality that may change over time.',
        paragraph2:
          'The scope and availability of the Services may vary according to: (1) additional terms presented at the time of purchase or registration; (2) the specific Course or Service for which you’ve enrolled or registered; and/or (3) any licensing terms between Pearson and your Institution. Pearson is not responsible for limitations of access resulting from any Third-Party Service or as the result of certain account settings established by your Institution.',
        paragraph3:
          "You are responsible for obtaining Internet access in order to use the Services. You may access a Course available through the Services only for the duration of the Subscription purchased (typically one or two semesters depending on the Course), whether or not the Services are used, and subject to Pearson's right to terminate access under these UTOS. Institution access may be provided for a different time period.",
        paragraph4:
          "While Pearson makes reasonable efforts to ensure the availability of the Services to Authorized Users with active Subscriptions, the Services may be unavailable for limited periods of time due to scheduled routine maintenance, emergency maintenance or factors beyond Pearson's control, such as disruptions of Internet services or unforeseen threats to the integrity or security of the Services.",
        paragraph5:
          'Pearson may at any time, with or without notice to you, restrict the use and accessibility of the Services and/or discontinue any portion, feature, or content of the Services. Pearson will make reasonable efforts to provide Authorized Users with active Subscriptions notice of significant changes to the Services whenever reasonably feasible. Information and updates about changes to the Services and its availability are posted regularly at <a target="_blank" href="https://pearson.tfaforms.net/3048">Pearson Support</a>.'
      },
      '15': {
        question: '15. The Services come with no warranties',
        paragraph1:
          'The Services’ availability and performance occasionally may be less than perfect, and you nonetheless agree to use them “as-is” and at your own risk.',
        paragraph2:
          'You expressly acknowledge and agree that: (1) your use of the Services is at your own risk; (2) the Services are provided on an "as is" and "as available" basis; (3) you have not relied on any statement, information, or advice from Pearson\'s representatives or resellers that would be deemed to be a warranty of these Services or form the basis for any liability of Pearson; and (4) to the fullest extent permitted under applicable law, Pearson and its licensors and contractors expressly disclaim all warranties and conditions of any kind, including those created by course of performance, course of dealing, usage of trade, and whether express or implied, including, but not limited to the implied warranties of merchantability, fitness for a particular purpose and non-infringement.',
        paragraph3:
          'Neither Pearson nor its licensors make any representation or warranty that: (1) the Services or your use thereof will be uninterrupted or error-free; (2) the results that may be obtained from the use of the Services will be accurate or reliable; or (3) the Services will meet your requirements.',
        paragraph4:
          'Any material uploaded to or downloaded or otherwise obtained from the Services is done at your own discretion and risk; neither Pearson nor its licensors shall be liable, and you will be solely responsible, for any and all loss, or corruption, of data uploaded or inputted by you through the use of the Services, and for any and all servicing, repair, or correction of and any damage to your hardware and software that may result from the use of the Services.',
        paragraph5:
          'Some jurisdictions do not allow the exclusion of implied warranties or consumer guarantees, so the above exclusions may not apply to you. Notwithstanding the foregoing, above no-warranties clause stipulated this Section shall not apply in the event of willful misconduct or negligence on the part of Pearson.'
      },
      '16': {
        question: '16. Pearson’s liability to you is limited',
        paragraph1:
          'If something goes wrong with the Services, Pearson’s liability to you is limited, as is your ability to bring claims.',
        paragraph2:
          'Nothing in these UTOS is intended to exclude or limit any condition, warranty, right, or liability that may not be lawfully excluded or limited under applicable law. Accordingly, only the limitations that are lawful in your jurisdiction will apply to you and in such instances our liability will be limited to the maximum extent permitted.',
        paragraph3:
          "In no event shall Pearson or its employees, officers, agents, contractors, or licensors be liable for any compensatory, incidental, indirect, special, punitive, or consequential damages of any kind - including, without limitation any claims or damages based on your use of or inability to use the Services, any third-party websites, or applications accessed through the Services, damage to property, thirdparty claims, loss of profits, goodwill, data, or other intangible losses, losses caused by your reliance on any content or information provided through the Services, and/or any other losses of any kind arising from your use of the Services; provided, however, that this shall not apply in the event of willful conduct or negligence on the part of Pearson. Pearson's maximum aggregate liability in connection with the Services will in no event exceed the total fees paid by you for the specific Services from which the loss arises; provided, however, that this shall not apply in the event of willful conduct or gross negligence on the part of Pearson. The limitations set forth in this section shall apply whether such liability is asserted on the basis of contract, tort, or otherwise, even if Pearson has been advised of the possibility of any such loss or damage, and/or if any of the limited remedies in these UTOS fail their essential purpose.",
        paragraph4: ''
      },
      '17': {
        question: '17. You are responsible for any damage you cause Pearson',
        paragraph1:
          'You agree not to use the Services in violation of these rules, or for any unlawful purpose. But if you DO, you agree to compensate Pearson for any damage you cause.',
        paragraph2:
          'As a condition of your use of the Services, you warrant and represent to Pearson that: (1) you are an Authorized User; (2) you will, at all times, comply with all applicable local, state, national or international laws or regulations, and/or the academic rules or policies of your Institution in using the Services; and (3) you will not use the Services for any purpose that is unlawful or prohibited by these UTOS or the <a target="_blank" href="https://loginstatic.pearson.com/html/AcceptableUsePolicy.html">AUP</a>. To the fullest extent permitted by applicable law, you agree to defend, indemnify and hold harmless Pearson and its employees, officers, agents, contractors, and licensors from any claims, damages, expenses, or liabilities arising from or in any way related to any violation of these UTOS or unauthorized use of the Services.'
      },
      '18': {
        question:
          '18. If we get into a dispute, these are the laws that will apply & the courts where claims may be brought',
        paragraph1:
          'Any litigation over the Services will be resolved in the courts of New York, Ontario, Australia, or England (depending on where you live).',
        paragraph2:
          'You agree that these UTOS shall not be governed by the United Nations Convention on Contracts for the International Sale of Goods and that any and all actions, disputes or controversies relating to these UTOS or your use of the Services (each a "<strong>Claim</strong>") shall be subject to the terms of this provision.',
        paragraph3:
          'If you reside in the United States you: (1) submit to the personal and exclusive jurisdiction and venue of the courts located within the County of New York, State of New York with respect to any Claim; and (2) agree that any Claim will be governed by and construed subject to the laws of the State of New York, without giving effect to its conflict of law principles.',
        paragraph4:
          'If you reside in Canada you: (1) submit to the personal and exclusive jurisdiction and venue of the courts located within the province of Ontario; and (2) agree that any Claim will be governed by and construed subject to laws of Ontario and the laws of Canada applicable therein, without giving effect to its conflict of law principles.',
        paragraph5:
          'If you reside in the European Union (“<strong>EU</strong>”) you may submit a Claim against us in your own country or in England and Wales but agree that any Claim will be governed by and construed subject to the laws of England and Wales subject to conflict of law principles.',
        paragraph6:
          'If you reside in Australia you: (1) submit to the personal and exclusive jurisdiction and venue of the courts located within the State of Victoria; and (2) agree that any Claim will be governed by and construed subject to the laws of the State of Victoria, without giving effect to its conflict of law principles.',
        paragraph7:
          'If you reside outside of the US, the EU, Australia, and Canada you: (1) submit to the personal and exclusive jurisdiction and venue of the courts located within England and Wales; and (2) agree that any Claim will be governed by and construed subject to the laws of England and Wales, without giving effect to its conflict of law principles.'
      },
      '19': {
        question: '19. You agree to comply with export laws',
        paragraph1:
          'You may not use the Services if you: (1) are located in a country designated by the U.S. Government as a “terrorist supporting” country; or (2) are listed on any U.S. Government list of prohibited or restricted parties. You also promise not to export the Services to countries requiring a U.S. export license.',
        paragraph2:
          'Your use of Services, the Applications, and related technology are subject to U.S. or international control or export laws and regulations (“<strong>Export Laws</strong>”). Without limitation, you agree to strictly comply with all such Export Laws and acknowledge that you have the responsibility to obtain authorization to export, re-export, or import the Application and related technology, as may be required. You represent and warrant that: (1) you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (2) you are not listed on any U.S. Government list of prohibited or restricted parties. You agree not to transfer, directly or indirectly, by electronic means or otherwise, any Services to any country, or to any agent, representative, or foreign national of any country, for which the U.S. Government or any agency thereof requires an export license or otherwise restricts such exports. You will indemnify and hold Pearson harmless from any and all claims, losses, liabilities, damages, fines, penalties, costs and expenses (including, but not limited to, attorney’s fees) arising from or relating to any breach by you of your obligations under this section.'
      },
      '20': {
        question: '20. Terminating your Subscription & access to the Services',
        paragraph1:
          'You or Pearson may cancel your Subscription at any time for any reason set forth below:',
        paragraph2:
          '<strong>Trial Access.</strong> On a case-by-case basis, Services may be made available to you for a trial review period, typically for up to fifteen (15) days ("<strong>Review Period</strong>"). During the Review Period, you will be provided access to the Services without charge. If your Review Period is time limited, however, access to the Services offered for trial review will be terminated if payment is not made prior to the end of the Review Period. Termination of a Service does not automatically terminate your User Account. Further, you expressly acknowledge and agree that if at any time during the Review Period, Pearson, in its sole discretion, determines that you are utilizing the Review Period as a substitute or proxy for a paid Service, it may immediately terminate or suspend your access to the applicable Service for which you’ve been granted a Review Period. In the event of any suspension or termination of your access to such a Service, you acknowledge and agree that: (1) the Service, its functionality, activities, materials, or any results generated by your use thereof may not be available to you or any other person; and (2) neither you nor any other person are authorized to access or use any results generated by your use of the suspended or terminated Course, its functionality, activities, or materials regardless of whether such results are available within the Course or the Services. If your access to a Course is terminated due to you not purchasing a Course Subscription, and then Pearson subsequently reinstates your access to the Course upon later receiving payment, any such reinstated access shall remain subject to the terms of these UTOS.',
        paragraph3:
          '<strong>Termination by You.</strong> Subject to account restrictions that may be imposed by your Institution or a Third-Party Service through which access to the Services is provided, you may terminate your User Account at any time by notifying Pearson of your decision to do so. Your satisfaction with the Services is important to us. If you have any concerns or complaints about the Services or wish to terminate your access to the Services, please contact us at <a target="_blank" href="https://pearson.tfaforms.net/3048">Pearson Support</a>.',
        paragraph4:
          '<strong>Termination by Pearson.</strong> Pearson may, in its sole discretion, with or without advance notice, and/or at the request of your Institution, suspend or terminate your access to, or your rights to use, all or any part of the Services for any conduct or use (whether by you or anyone else having access to the Services under your User Account) that Pearson reasonably believes violates the UTOS or AUP.',
        paragraph5:
          '<strong>Effect of Termination.</strong> Whether termination is initiated by you, your Institution or Pearson, the terms of the UTOS and AUP, and Sections 10, 20, and 21 of these UTOS shall survive any such termination. Any User Account information, data, settings or specifications, or customizations of a Service or Subscription specific to your User Account may be permanently lost upon termination of a Service or Subscription, whether by you, Pearson, or your Institution. User Content and other user information associated with your use of the Services may still be accessible by your Institution or, to the extent it is posted in a public forum, to other users of the Services for which such information was posted, even after termination.'
      },
      '21': {
        question: '21. Your exclusive remedies under these UTOS',
        paragraph1:
          'If something goes wrong, your only remedies under these UTOS are: (1) restoration of access to the Services; or (2) a pro-rata refund of fees.',
        paragraph2:
          "Pearson's entire liability to you, and your sole and exclusive remedy, regarding the use of the Services shall be either, in Pearson’s sole discretion: (1) restoration of access to the Services to which you have subscribed; or (2) a pro rata refund of any fees paid directly by you to Pearson for the particular Service or Subscription.",
        paragraph3:
          'When available, refunds will only be made if requested by you in writing within the first 15 days after Enrollment or registration. Refunds can only be provided for purchases made directly from Pearson\'s website by an Authorized User. Refunds are not available for lost or stolen access codes, or any purchases made through third parties (for example, your campus bookstore). If you are dissatisfied with a Service or wish to request a refund, please contact us at <a target="_blank" href="https://pearson.tfaforms.net/3048">Pearson Support</a>.',
        paragraph4:
          'If you reside in Australia, the Services come with warranties and guarantees that cannot be excluded under the Australian Consumer Law (Consumer Guarantees). The remedy you are entitled to if the Services fail to meet the Consumer Guarantees will depend on whether the failure to comply with the Consumer Guarantees is major or minor.'
      },
      '22': {
        question: '22. A few additional, miscellaneous terms',
        paragraph1:
          'These UTOS are personal to you, and you may not assign, transfer or delegate your User Account or your rights or obligations under these UTOS or the <a target="_blank" href="https://loginstatic.pearson.com/html/AcceptableUsePolicy.html">AUP</a> (collectively, the “<strong>Terms</strong>”) to anyone. Pearson may assign or delegate its rights or obligations under the Terms, in whole or in part, subject to Pearson\'s right and obligations under the Terms and any agreement it may have with your Institution. In the event that any provision of the Terms is held by a court of competent jurisdiction to be invalid or unenforceable for any reason, the remainder of the Terms shall remain valid and enforceable according to its terms. The Terms comprise the entire agreement between Pearson and you with respect to the Services and cannot be modified by you absent a signed written agreement. Section headings and summaries in the Terms are for your convenience only and do not have any legal meaning or effect. If Pearson waives or fails to enforce any term or condition of the Terms on any one or more occasions, whether by conduct or otherwise, its waiver or failure to enforce such terms will not prevent Pearson from enforcing any terms or condition of the Terms at any other time. It is the express wish of the parties that the Terms and all related documents be drawn up in English. C’est la volonté expresse des parties que la présente convention ainsi que les documents qui s’y rattachent soint rédigés en anglais.'
      }
    },
    preSection2: {
      header: `{{productName}} Supplemental Terms and Conditions`
    },
    content2: {
      '1': {
        question: '1. Introduction',
        bullet1: `These {{productName}} Supplemental Terms and Conditions (“VPEC Supplemental Terms and Conditions”) are incorporated into and form part of the contract between Pearson Education Ltd (“Pearson”, “we”, “our” or “us”) and a candidate (“Candidate”, “you” or “your”) who has registered for {{productName}} (“VPEC”) with us or one of our affiliate companies worldwide.`,
        bullet2:
          'Candidates must agree to these VPEC Supplemental Terms and Conditions before completing the purchase of a test. You acknowledge that you have not relied on any statement, promise, representation, assurance or warranty made or given by or on behalf of Pearson which is not set out in these VPEC Supplemental Terms and Conditions.',
        bullet3:
          'These VPEC Supplemental Terms and Conditions, the Universal Terms of Service and End User License Agreement and the VPEC Official Guide for Test Takers (‘the Guide’) constitute the entire agreement between us and a Candidate in relation to VPEC.'
      },
      '2': {
        question: '2. VPEC Official Guide',
        bullet1:
          'The Guide, which is available here, and all the supporting resources that are available through the <a target="_blank" href="{{ url }}">{{ url }}</a> (for example, FAQs and videos) contain details of the rules and regulations that apply to VPEC, including details of preparing and taking a test. When you register for or take a VPEC test, you represent that you have read and understood and agree to be bound by the Guide.',
        bullet2:
          'You agree that if you do not comply with the rules set out by the Guide and supporting materials, we may take action including, but not limited, to not certifying your results.'
      },
      '3': {
        question: '3. Privacy',
        bullet1:
          'At all stages during the registration, scheduling and test taking process Pearson will collect personal data from and about you. All your personal data will be collected, transmitted, and stored in a safe and secure way and will be used only for the purposes stated in the privacy statement which can be viewed <a target="_blank" href="{{ url }}">here</a>.'
      },
      '4': {
        question: '4. Confidentiality',
        bullet1:
          'Candidates may not take copies of test questions and answers from the test or share any items or answers from their test with others; this includes a prohibition on any non-commercial use of test items and answers in study guides or practice sessions.'
      },
      '5': {
        question: '5. Cancellation and Refund Policy',
        bullet1:
          'You may request a full refund on your test provided no more than 90 days have elapsed since purchase and the test has not commenced. We will refund or credit the test price (plus any GST/VAT/consumption tax/government levy) using the method of payment used to purchase the test and remove the test from your application.',
        bullet2:
          'However, except in the case of willful misconduct or gross negligence on the part of Pearson, <u>you cannot cancel a test and request a refund once the test has commenced (including for reasons of equipment failure) and regardless of when the test was purchased. The test is deemed to have commenced after the set-up check has been completed</u>.',
        bullet3:
          'If you are unable to complete the test due to a system failure then Pearson may, at its sole discretion, offer you a replacement test or refund.',
        bullet4:
          'You will need to report any issues to our Customer Service team for investigation using <a target="_blank" href="https://pearson.tfaforms.net/3048">this form</a>.',
        bullet5:
          'Nothing in this cancellation or refund policy supersedes any rights you may have under consumer law or regulations, or where Pearson is the sole cause of any technical fault.'
      },
      '6': {
        question: '6. Limitations of Liability',
        bullet1:
          'Pearson does not limit or exclude its liability for death or personal injury caused by its negligence or that of its employees or agents or for fraudulent misrepresentation.',
        bullet2:
          'Subject to paragraph 6 (a) above, and to the fullest extent permitted by applicable law, Pearson shall under no circumstances be liable to you, whether in contract, tort (including negligence) or otherwise, for; (i) any loss of business, loss of profit or loss of opportunity or for any indirect or consequential loss incurred by you as a consequence of Pearson’s acts or omissions relating VPEC or your test; or (ii) any act or omission by or on behalf of a test center, its employees or agents',
        bullet3:
          'Pearson’s total liability to you in respect of any and all other costs or losses incurred by you shall not exceed the amount paid by you to Pearson in relation to the relevant test, unless otherwise required by law.',
        bullet4:
          'In the event of our failure to perform, or delay in our performance of, any of our obligations in relation to providing VPEC that is caused by circumstances outside our reasonable control, we shall be entitled to a reasonable extension of time for performing those obligations.'
      },
      '7': {
        question: '7. General Provisions',
        bullet1:
          'We may revise these VPEC Supplemental Terms and Conditions from time to time. Any material change will be brought to your attention by email to the contact email address you have provided to us.',
        bullet2:
          'To the extent there is any conflict, these VPEC Supplemental Terms and Conditions take precedence, followed by the Universal Terms of Service and End User License Agreement and then the VPEC Official Guide for Test Takers',
        bullet3:
          'If any of these VPEC Supplemental Terms and Conditions are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law',
        bullet4:
          'If we fail to insist upon strict performance of any of your obligations under any of these VPEC Supplemental Terms and Conditions, or if we fail to exercise any of the rights or remedies to which we are entitled, this shall not constitute a waiver of such rights or remedies and shall not relieve you from compliance with such obligations. A waiver by us of any default shall not constitute a waiver of any subsequent default.'
      },
      '8': {
        question: '8. Contact details',
        bullet1:
          'You can contact our Customer Service team with any issues using <a target="_blank" href="https://pearson.tfaforms.net/3048">this form</a>.'
      }
    }
  },
  cookiePolicy: {
    downloadButton: 'Download Cookies Policy',
    header: 'Cookies policy',
    subheader:
      'We’ve updated our Cookies policy. Changes include a new section about Do Not Sell My Personal Information and the California Consumer Privacy Act and updates to the List of Cookies.',
    preSection: {
      header: 'Universal Terms of Service and End User License Agreement',
      paragraph1: `This Cookies Policy provides information about the cookies used with this site, pearson.com/en-us.html, which is presented by Pearson Education, Inc., 221 River Street, Hoboken, New Jersey 07030, in collaboration with its subsidiaries and affiliated companies (Pearson). Please read this Cookies Policy in conjunction with the <a href='{{privacyPolicyLink}}'><strong>Privacy Policy</strong></a> for this site.`,
      paragraph2:
        'Like many other websites, this site uses cookies to store user preferences, make the website more user-friendly, collect information about site usage and performance, and facilitate targeted messaging and marketing.'
    },
    content: {
      paragraph1: {
        header: 'Do Not Sell My Personal Information and the California Consumer Privacy Act',
        description:
          'Several states including CA, CO, CT, NV, UT and VA give residetns varying rights to opt out of the sale of their Personal Information, sharing of their Personal Information, or both.'
      },
      paragraph2: {
        description:
          'Pearson <strong>does not</strong> sell or share, and <strong>has not</strong> sold or shared, any Personal Information collected through the Services within the last twelve (12) months.'
      },
      paragraph3: {
        header: 'Disabling or blocking cookies',
        description:
          'Cookies may be blocked or disabled through internet browser settings on each computer and browser used by a user. In addition, a user can use opt-out tools for ad networks and firms who participate in the Network Advertising Initiative at <a href="http://optout.networkadvertising.org">http://optout.networkadvertising.org/</a> or the Digital Advertising Alliance at <a href="http://optout.aboutads.info">http://optout.aboutads.info/</a>.'
      },
      paragraph4: {
        description:
          'To effectively manage cookies via browser settings or through these ad industry opt-out tools, opt-out and set cookie preferences on all browsers and all devices used. In addition, check and reset preferences after clearing cache or cookies.'
      },
      paragraph5: {
        description:
          'Please note that disabling or blocking cookies may reduce the functionality of this and other sites.'
      },
      paragraph6: {
        description:
          'The following provides information about viewing and changing browser settings to disable or block cookies:'
      },
      paragraph7: {
        description:
          "Google Chrome </br> Click on the spanner icon (top menu in browser) and select ‘Settings’ </br> Click the 'Under the Bonnet' tab on the left, then select the 'Content settings' button </br> Under the cookies section, choose the option that best suits your needs."
      },
      paragraph8: {
        description:
          "Microsoft Internet Explorer </br> Click on 'Tools' (top menu in browser) and select 'Internet options' </br> Click on the 'Privacy' tab </br> Move the privacy slider to the level of privacy you require. Settings above Medium will disable </br> cookies."
      },
      paragraph9: {
        description:
          "Mozilla Firefox </br> Click on 'Tools' (top menu in browser) and select ‘Options’ </br> Select the Privacy icon </br> In the history section choose 'Use custom settings for history' from the dropdown menu </br> Cookies can be enabled or disabled by clicking on the appropriate check boxes."
      },
      paragraph10: {
        description:
          "Safari </br> Click on the Cog icon (top menu in browser) and select 'Preferences' </br> Click on Privacy tab and choose the option that best suits your needs."
      },
      paragraph11: {
        description:
          "Opera </br> Click on the 'Tools' at the top of your browser window and select 'Preferences' </br> Select the 'Advanced' tab and choose Cookies from the list on the left </br> Choose the option that best suits your needs"
      },
      paragraph12: {
        description:
          "Safari on OSX </br> Click on 'Safari' at the top of your browser window and select 'Preferences' </br> Click on the 'Privacy' tab </br> Adjust the level of cookie acceptance here."
      },
      paragraph13: {
        description:
          "Firefox </br> Click on 'Firefox' at the top of your browser window and select 'Preferences' </br> Select the Privacy icon </br> In the history section choose 'Use custom settings for history' from the dropdown menu </br> Cookies can be enabled or disabled by clicking on the appropriate check boxes"
      },
      paragraph14: {
        header: 'List of cookies',
        description:
          'Cookies generally fall into four categories: (1) strictly necessary cookies; (2) performance cookies; (3) functionality cookies; and (4) behaviorally targeted advertising/marketing cookies.'
      },
      paragraph15: {
        description:
          'The cookies used in connection with this site, pearson.com/us, includes cookies in each of these categories and may include (but not be limited to) those listed below.'
      },
      paragraph16: {
        description:
          'Please note that cookies usage on other Pearson sites, including our online product and services, may differ and may exclude, for example, behaviorally targeted marketing cookies.'
      },
      paragraph17: {
        header: 'Strictly Necessary Cookies',
        bullet1: {
          description:
            'Session Based: To collect information on use of the site for analysis and to improve its operation or identify and respond to problems.',
          subbullet1:
            '<strong>AWSALB, AWSELB</strong> – Cookie set by AWS to create “sticky sessions',
          subbullet2: '<strong>YSESSIONID</strong> – Hybris Session ID',
          subbullet3:
            '<strong>acceleratorSecureGUID</strong> – Hybris secure guid cookie evaluates if a user has a valid session',
          subbullet4:
            '<strong>cookie-notification</strong> – Set when user closes the cookie notification popup'
        },
        bullet2: {
          description:
            'Geo Targeting: Used to help identify the user’s country or state, so that we can display information relevant to that territory or jurisdiction.',
          subbullet1:
            '<strong>geotargetsite</strong> – Tracks user location based on IP via Akamai',
          subbullet2:
            '<strong>geotargetchosenlocale</strong> – Tracks when user makes a choice to navigate to a store in a specific country',
          subbullet3:
            '<strong>system-msg</strong> – Used for global messaging component in the header'
        },
        bullet3: {
          description:
            'Google Analytics: Gather data on how users use and interact with the site. Used to analyze how well our sites are performing and optimize customer experience.',
          subbullet1: '<strong>_utma</strong> – Google Analytics cookie to track unique visitors',
          subbullet2:
            '<strong>_utmb</strong> – Google Analytics cookie to track visitor behavior and measure site performance',
          subbullet3:
            '<strong>_utmc</strong> – Google Analytics cookie to track visitor behavior and measure site performance',
          subbullet4:
            '<strong>_utmt</strong> – Google Analytics cookie to throttle request rate for the service',
          subbullet5:
            '<strong>_utmv</strong> – Google Analytics cookie used for user classification',
          subbullet6: '<strong>_utmz</strong> – Google Analytics cookie',
          subbullet7: '<strong>_ga</strong> – Google Analytics cookie to distinguish users',
          subbullet8:
            '<strong>_gat_UA-93818630</strong> – Identifier for Google Analytics Account Number',
          subbullet9: '<strong>_gid</strong> – Google Analytics cookie to distinguish users',
          subbullet10:
            '<strong>_rollupGa</strong> – Google Analytics cookie to store a unique client identifier'
        }
      },
      paragraph18: {
        header: 'Performance Cookies',
        bullet1: {
          description: 'Optimize: Multivarient testing tool. Used to optimize customer experience.'
        },
        bullet2: {
          description:
            'hotjar: Records heatmaps of user interaction with site. Used to analyze how well the sites is performing and optimize customer experience.'
        },
        bullet3: {
          description:
            'LiveIntent: Cross-device identification tool. Used for website analytics and to optimize user experience.'
        }
      },
      paragraph19: {
        header: 'Functionality Cookies',
        bullet1: {
          description: 'Maritz: Used to deploy customer satisfaction surveys.'
        }
      },
      paragraph20: {
        header: 'Behaviorally targeted advertising cookies',
        bullet1: {
          description:
            "Pardot: Marketing platform used for customer and campaign tracking. Used to identify customers' interests and response to targeted campaigns. Enabled only to collect information for Pearson’s use."
        },
        bullet2: {
          description:
            'Facebook: Social Media marketing. Used to track and measure marketing campaigns deployed on the Facebook platform. Facebook tracks customer behavior through these tools.'
        },
        bullet3: {
          description:
            'Twitter: Social Media marketing. Used to track and measure marketing campaigns deployed on the Twitter platform.'
        },
        bullet4: {
          description:
            'Linked In: Social Media marketing. Used to track and measure marketing campaigns deployed on the Linked In platform.'
        },
        bullet5: {
          description:
            'Google Ads: Search media marketing. Used to track and measure marketing campaigns within Google search and related Google services.'
        },
        bullet6: {
          description:
            'Salesforce: Sales support tool. Used to track and measure customer usage of support tools. Enabled only to collect information for Pearson’s use.'
        },
        bullet7: {
          description:
            'Pearson Education: Sales support tool. Used to track and measure customer ecommerce usage.'
        }
      },
      paragraph21: {
        header: 'Changes',
        description:
          'We may revise this Cookies Policy through an updated posting. We will identify the effective date of the revision in the posting. Often updates may be made to provide greater clarity or to comply with changes in regulatory requirements. If the updates involve material changes, we will provide notice of the change through a conspicuous notice on this site or other appropriate way. Continued use of the site after the effective date of a posted revision evidence acceptance. Please contact us if you have any questions, concerns or any objection about any changes.'
      },
      paragraph22: {
        header: 'Contact us',
        description:
          'Please <a target="_blank" href="https://pearson.tfaforms.net/3048">contact us</a> about this Cookies Policy, or with any requests or questions relating to the privacy of personal information collected or processed by Pearson.'
      },
      paragraph23: {
        header: 'Last Update: December 13, 2022'
      }
    }
  },
  results: {
    header: 'My results',
    noTestsTakenDescription: 'Complete a test, then check here to see how you performed!',
    myTopScoreHeader: 'MyTopScore™',
    gseScore: 'Overall score',
    viewDetails: 'View Details',
    skillScoresTableHeaders: ['Tests', 'Test ID', 'Date', 'GSE Score', 'CEFR'],
    myTopScoreTableHeaders: [
      'Test',
      'Test ID',
      'Date taken',
      'Speaking',
      'Listening',
      'Reading',
      'Writing',
      'Overall score',
      'CEFR',
      ''
    ],
    resultsTablesHeaders: [
      'Tests',
      'Date',
      'Status',
      'Speaking',
      'Listening',
      'Reading',
      'Writing',
      'Overall score',
      'CEFR',
      ''
    ],
    resultsPracticeTableHeaders: [
      'Tests',
      'Date',
      'Speaking',
      'Listening',
      'Reading',
      'Writing',
      'Overall level',
      ''
    ],
    latestTest: 'Latest test',
    yourSpeakingWasUnscorable: 'Your speaking test was unscorable. Please review the details below',
    somethingWentWrong:
      'Something went wrong. For more information and a free retake, please check the test details.',
    unableToCertifyScore:
      'Unfortuantely, your score could not be certified.<br /> Please check the test for details.',
    appealBeingReviewed:
      'Your appeal is being reviewed by our team. Please check back later to see your final score.',
    testBeingReviewed:
      'Your test is being reviewed by our team. Please check back later to see your final score.',
    uploading:
      'Your responses are being uploaded in the background. Once all the responses are uploaded the certification process will start.',
    thisTestHasStarted: 'This test is in progress. After the test, you’ll find your score here.',
    testWasNotCompleted:
      'This test was exited early. Unfortunately, we can’t provide you with a score.',
    allYourTests: 'All tests',
    allYourPracticeTests: 'All practice tests',
    allResults: 'All results',
    yourTests: 'Tests',
    yourPracticeTests: 'Practice tests',
    resultDetails: 'Result Details',
    expiresIn: 'Expires in: {{daysToExpire}} days',
    expired: 'Expired',
    downloadingAssets: 'Downloading assets',
    resultBadge: {
      certified: 'Certified',
      unscorable: 'Unscorable',
      pendingCertification: 'Pending review',
      unabletoCertify: 'Unable to certify',
      speakingUnscorable: 'Speaking unscorable',
      appealed: 'Appealed',
      started: 'Started',
      incomplete: 'Incomplete',
      uploading: 'Uploading',
      ruleViolation: 'Rule violation',
      inProgress: 'In progress',
      completed: 'Completed',
      error: 'Unable to process',
      lateHangup: 'Late Hangup'
    },
    reasonsforDeniedCertification: 'Reasons for Denied Certification',
    reason: 'Reason {{number}}',
    skillScores: 'Skill Scores',
    understandingYourSkills: 'Understanding Your skills',
    overallScore: 'Overall score',
    overallScoreDescription: `The Overall score of the test represents the ability to understand spoken English
 and speak it intelligibly at a native-like conversational pace on everyday topics.
 Scores are based on a weighted combination of four diagnostics subscores.`,
    gse: 'GSE',
    gseDescription:
      'The Global Scale of English (GSE) is a standardized, granular scale from 10 to 90, which measures English language proficiency. Visit <a>English.com/gse</a> to learn more.',
    mtsDescription:
      'To calculate <italic>MyTopScore</italic>, we used the test taker’s best scores in each skill from tests they took in the past 6 months',
    overallDescription:
      'The Overall score of the test represents the ability to understand spoken and written English and respond appropriately in speaking and writing on academic and workplace topics, at an appropriate pace and in intelligible English. Scores are reported in the range from 10 to 90 on the GSE.',
    sampleResponses: 'Sample Responses',
    downloadCertificate: 'Download Certificate',
    writingSampleTitle: 'Writing Sample',
    writingSampleText: 'Read a sample from the candidate’s writing test',
    speakingSampleTitle: 'Speaking Sample',
    speakingSampleText: 'Listen to a sample from the candidates speaking test',
    speakingSample: 'Speaking Sample',
    writingSample: 'Writing Sample',
    speakingSkill: 'speaking skill',
    writingSkill: 'writing skill',
    scoreSummary: 'Score Summary',
    viewMyScore: 'View my score',
    viewMyScores: 'View my scores',
    yourScore: 'Your score',
    skillOverwiev: 'Skill Overview',
    tipsAndRecommendations: 'Tips and Recommendations',
    readSample: 'Read Sample',
    listentoSample: 'Listen to sample',
    certificateOverallHeader: 'English Proficiency',
    sampleResponseTitle: '{{firstName}}`s Sample Responses',
    samplePreviewDescription: 'Below is a sample of {{fullName}}`s {{skill}}',
    emailPreview: 'Email preview',
    shareResult: 'Share result',
    shareYourResults: 'Share your results',
    shareYourResultsHeader: 'Who do you want to share your scores with?',
    shareYourResultsDescription:
      'When you choose to share the score via the platform, the person you nominate will have access to:',
    shareYourResultsCopyOfCertificate: 'A copy of your certificate',
    shareYourResultsSamples: 'Your speech and writing samples',
    organizationName: 'Organization name',
    specifyOrganization: 'Specify organization',
    emailAddress: 'Email address',
    yourScoreHasBeenSent:
      'Your score has been sent to the email you provided. You will get a notification in your email when the score is viewed.',
    yourScoreHasBeenSentToEndCustomer: 'Your score has been sent to ',
    previewTheEmailLinkDescription: 'You can preview the email that you sent by clicking',
    invalidEmailAddress: 'invalid email address',
    previewEmail: 'Preview Email',
    shareResults: 'Share results',
    atLevel: 'At level',
    aboveLevel: 'Above level',
    noTestsDescription: 'After you take a test, you’ll find your scores here',
    belowLevel: 'Below level',
    levelScale: [
      'Below Beginner',
      'Beginner',
      'Elementary',
      'Intermediate',
      'Upper intermediate',
      'Advanced',
      'Mastery'
    ],
    highlightedScores: '*Highlighted scores were used to calculate <span>MyTopScore</span>',
    credlyBadgeDialogTitle: 'Accept your Credly badge',
    credlyBadgeDialogRedeemedTitle: 'Share your Credly badge',
    credlyBadgeDialogRedeemedDescription: 'Congratulations, you accepted your Credly badge.',
    credlyBadgeDialogDescription: `Congratulations! You have earned a Credly badge for your latest {{productName}} test results.`,
    credlyBadgeDialogRedeemedListDescription:
      'Log into your profile in Credly to start sharing your skills!',
    credlyBadgeDialogListDescription: 'Share your badge in 3 easy steps:',
    credlyBadgeDialogSteps: [
      'Look out for an email from Credly',
      'Use the link in the email to create your Credly profile and accept your badge',
      'Then share your badge to show everyone your skills!'
    ],
    credlyBadgeDialogButton: 'Learn more about Credly',
    credlyBadgeDialogRedeemedButton: 'Go to Credly',
    noResultsText: 'After you take a test, you’ll find your scores here',
    shareResultsEmailSentSuccess: 'Success!',
    yourScores: 'Your Scores',
    couldNotBeCertified: 'Your test results could not be certified',
    retakeCompleted: 'This retake has been completed',
    seeAllResults: 'See all results'
  },
  testID: 'Test ID',
  testName: 'Test name',
  shareDetails: {
    sharedWith: 'Shared with',
    sharing: 'Sharing',
    name: 'Name',
    shareDetailsHeaders: ['Test ID', 'Date shared', 'Score', 'Shared with', 'Sharing']
  },
  menuLabels: {
    dashboard: 'Dashboard',
    results: 'Results',
    account: 'Account',
    gettingReady: 'Getting ready',
    helpCenter: 'Help center',
    exploreTests: 'Explore tests',
    contactUs: 'Contact us',
    logOut: 'Log out'
  },
  speaking: 'Speaking',
  listening: 'Listening',
  reading: 'Reading',
  writing: 'Writing',
  dontHaveAnyTests: "You don't have any tests",
  share: 'share',
  rowsPerPage: 'rows per page',
  english: 'English',
  japanese: 'Japanese',
  date: 'Date',
  expirationDate: 'Expiration Date',
  account: {
    dontHaveAnyTestsWithShares:
      'After sharing your scores, you’ll find them displayed in this section',
    account: 'Account',
    accounts: 'Accounts',
    off: 'OFF',
    on: 'ON',
    areYouSure: 'Are you sure?',
    yesRevoke: 'Yes, Revoke',
    cancel: 'Cancel'
  },
  pagination: {
    of: 'of'
  },
  view: 'View',
  noSampleTextToPreview: 'There is no sample text to preview.',
  done: 'Done',
  thisShouldBeAValidEmailAddress: 'This should be a valid email address',
  send: 'Send',
  here: 'here',
  success: 'Success',
  score: {
    header: 'Score',
    scoreReportHeader: 'Certificate',
    scores: 'Scores',
    lookup: 'Certificate Lookup'
  },
  play: 'Play',
  exam: {
    speakLabel: 'Speak...',
    listenLabel: 'Listen...',
    fetch: 'Fetch exam'
  },
  layout: {
    next: 'Next',
    back: 'Back',
    agree: 'Agree and proceed'
  },
  directoryLayout: {
    startTest: 'Start Test',
    exitTest: 'Exit Test'
  },

  ageRestriction: {
    title: 'Age restriction',
    thankYouForInterest: `Thank you for your interest. We are sorry to inform you that you are not eligible to use the {{productName}} product due to an age restriction.`,
    pleaseNote: 'Please note that you must be above 14 years to create an account.',
    backToHomepage: 'Back to Homepage'
  },
  signUp: {
    header: 'Sign up to <br /> certify your <br /> English',
    mobileHeader: 'Sign up to certify your English',
    advantages: [
      'Try practice tests for free!',
      'Once you feel ready, buy and take a test',
      'Share your certified results after 48 hours'
    ],
    description:
      'You’re one step away from taking advantage of everything your account has to offer:',
    shouldBeValidEmail: 'This should be a valid email address',
    withGoogle: 'Sign Up With Google',
    orSignUpWith: 'or Sign up with',
    password: 'Password',
    privacyPolicy: 'Privacy Policy',
    charactersLength: '8 characters',
    consentLabel: 'I have read and agree to the Terms of use and acknowledge the Privacy Policy.',
    offersDescription:
      'We would like to send you personalized offers based on your profile and preferences.',
    offersConsentLabel: 'I am happy to receive personalized offers via email.',
    consentSummaryDescription: 'If you change your mind, you can unsubscribe at any time.',
    agreeingText: 'By clicking on Sign up you are agreeing to our',
    cookiePolicy: 'Cookie Policy',
    alreadyHaveAccount: 'Already have an account?',
    signIn: 'Sign In',
    agreementsDescription: `Please read our <termsOfUseComponent>Terms of Use</termsOfUseComponent> and
 <privacyPolicyComponent>Privacy Policy</privacyPolicyComponent> which explains how we will use your data.`,
    termsOfUse: 'Terms of Use',
    consentIsMandatory: 'Consent is mandatory',
    agreeToAll: 'I agree to all',
    agreementTitle: 'Consent Confirmation and Privacy Policy Review',
    ageConsent: 'I am at least 14 years old.',
    termsAndUseConsent: 'I agree to the <consentLink>Terms and Use</consentLink>.',
    collectPIConsent:
      'I agree to the <consentLink>collection and use of my personal information</consentLink>.',
    collectUIIConsent: 'I agree to the collection and use of my Unique Identification Information.',
    collectSIConsent: 'I agree to the collection and use of my Sensitive Information.',
    provisionPIConsent:
      'I agree to the <consentLink>provision of my personal information to designated third parties</consentLink>.',
    transferPIConsent:
      'I agree to the <consentLink>overseas transfer of my personal information</consentLink>.',
    marketingPurposesConsent:
      'I agree to the collection and use of my personal information for marketing purposes.',
    marketingEmailConsent:
      'I agree to receive personalised offers and marketing communication via email.',

    collectUIIConsentTooltip:
      'We collect your Unique Identification Information, such as your national ID or passport and a headshot to match your ID, strictly for identity verification to prevent fraud.',
    collectSIConsentTooltip:
      "We collect your biometric data, including samples of your voice and image, exclusively for identity verification. This prevents fraud by matching the test taker's image and voice to the image and voice of a verified identity. This is vital for conducting the test fairly and securely.",
    provisionPIConsentTooltip:
      'We share specific personal information with trusted partners to deliver our service and enhance quality. Please click here for comprehensive details on how your data is used.',
    transferPIConsentTooltip:
      'Your data will be transferred to and stored in the USA and accessed globally by Pearson employees as necessary for the provision of services. Please click here for comprehensive details of the transfer of your personal information.',
    marketingPurposesConsentTooltip:
      'By agreeing, you allow us to collect and use your personal information exclusively for marketing purposes and to send you tailored updates, promotions and offers from Pearson. You can unsubscribe at any time by clicking the link in your email.',
    countrySpecificPolicies: 'CONSENT CONFIRMATION',
    beforeProceed: 'Before you proceed...',
    differentCountryDetected:
      'It looks like you’re in a different country/region. Please agree to the following country-specific Terms & Conditions and Privacy policies'
  },

  versantByPearson: 'VERSANT BY PEARSON ENGLISH CERTIFICATE',
  downloadPearsonEnglishSkillsCertificate: `Download the {{productName}} app to take a test.`,
  signIn: {
    headerWeb: 'Welcome <br /> back!',
    description:
      'You’re one step away from taking advantage of<br/> everything your account has to offer:',
    signUpAndCertify: 'Sign up and certify your level of English proficiency.',
    signInWithGoogle: 'Sign In With Google',
    orSignInWith: 'or Sign in with',
    emailOrPasswordErrorMessage:
      'The email and/or password you have entered is \nincorrect. Please try again or',
    resetPassword: 'Reset Password.',
    // prettier-ignore
    advantages: [
      'Buy tests',
      'Take a practice test',
      'View results',
      'Share your best score'
    ],
    dontHaveAccountYet: "Don't have an account yet?!"
  },
  startYourLanguageJourneyHere: 'Start your language journey here',
  join: 'Join',
  signInText: 'Sign in',
  thisIsRequired: 'This is required',
  invalidEmail: 'invalid email address',
  email: 'Email',
  fieldIsRequired: 'This field is required',
  password: 'Password',
  signUpText: 'Sign up',
  createAnAccount: 'Create an account',
  watchTheVideo: 'Watch the video',
  resetPassword: {
    header: 'Reset my password',
    sendLink: 'Send link',
    rememberedPassword: 'Suddenly remembered your password?',
    checkYourEmail: 'Great! Now check your email',
    sentLinkMessage: `If the email you provided is associated with a valid user account, you will receive an email soon. Use the link in your email to create a new password and access your profile.`,
    signUpFirst: "If you don't receive an email, please <a>Sign up</a> first.",
    backToSignIn: 'Back to Sign In',
    notToWorryText: `Not to worry, it happens to all of us! Simply enter your email address below and we’ll
 send you a reset link.`,
    nCharacters: '{{n}} characters',
    confirmPassword: 'Confirm Password',
    passwordsMustBeEqual: 'Password and confirm password must be the same',
    updatePassword: 'Update Password',
    passwordHasBeenUpdated: 'Your password has been updated!',
    signInToAccount: `Sign In to your account with your email and new password below to access your tests,
 scores and profile.`,
    createNewPassword: 'Create a new password',
    settingNewPasswordDescription:
      'You can now create a new password below to continue to your account.',
    oldPasswordNotMatch: 'Old password does not match!',
    sameOldPassword: 'New password and old password should not be the same!',
    chooseAnotherPassword: 'The password was already used, please choose a different password.'
  },
  atLeast: 'At least',
  oneLowercaseLetter: '1 lowercase letter',
  oneUppercaseLetter: '1 uppercase letter',
  oneNumber: '1 number',
  specialCharacter: '1 special character',
  tellUsMoreForm: {
    getToKnowYou: 'Get to know you',
    tellUsMore: 'Tell us a bit more about yourself',
    preferredName: 'Preferred name',
    fieldRequired: 'This field is required',
    age: 'Age',
    country: 'Country of residence',
    city: 'City of residence',
    next: 'Next',
    describe: 'Which one best describes you?',
    done: 'You’re all done, thank you!',
    getReady: 'get ready',
    iAmStudent: 'University or post secondary student',
    iAmGraduate: 'I am a graduate',
    iAmYoungProfessional: 'I am a young professional',
    iAmProfessional: 'I am a mid/senior career professional',
    iAmSecondaryStudent: 'I am a secondary student'
  },
  and: 'and',
  changePassword: {
    youCanNowCreate: `You can now create a new password below.`,
    oldPassword: 'Old Password',
    newPassword: 'New Password',
    confirmPassword: 'Confirm Password',
    resetYourPassword: 'Reset your password',
    save: 'Save',
    success: 'Success!',
    passwordChanged: 'Your password has been changed successfully.',
    forgotYourPassword:
      'Forgot your password? Not to worry, it happens to all of us! Simply enter your email address below and we’ll send you a reset link.',
    sendLink: 'Send link',
    email: 'Email',
    checkEmail: 'Great! Now check your email',
    weHaveSentLink:
      'We have sent you a link to reset your password. Use the link in your email to create a new password and access your account',
    alreadyUsedPasswordError: 'The password was already used, please choose a different password.',
    oldPasswordDoesntMatchError: 'Old password does not match!',
    oldAndNewPasswordsAreEqualError: 'New password and old password should not match!',
    confirmPasswordError: 'Confirmed password and new password are not the same!'
  },
  exploreTestsPage: {
    exploreResources: 'Get ready for the test',
    exploreResourcesButton: 'Explore test resources',
    watchOurWalkthroughs:
      'Watch step-by-step videos, check system requirements, or browse frequently asked questions',
    englishSkillsCertificate: 'English Skills Certificate - 4 Skills',
    listeningAndSpeaking: 'Speaking & Listening',
    readingAndWriting: 'Reading & Writing',
    practiceForFree: 'Practice for free',
    buyNow: 'Buy now',
    checkYourTest: 'Your pre-test checklist',
    takeTest: 'WHAT YOU’LL NEED',
    chooseYourTest: 'Our English tests',
    tagsAll: ['Speaking', 'Listening', 'Reading', 'Writing'],
    tagsListeningSpeaking: ['Speaking', 'Listening'],
    tagsReadingSpeaking: ['Reading', 'Writing'],
    ourEnglishSkillsCertificate: `<bold>{{productName}}™</bold> gives you the flexibility to assess only the English skills that you need to prove.`,
    exploreTestsWrapperTypeOfTestsHeader: 'Certify your English in all 4 skills',
    forSuccessfulTest:
      'Before you take a test, make sure you’re all set! Here are our test requirements.',
    checkSystemReq: 'Check system requirements',
    twoSkillsTests: 'or certify 2-skills',
    requirements: {
      system: 'System',
      checkYourSetUp: 'You can perform a ',
      quickSystemCheck: 'quick system check',
      windowOrMacComp: 'Windows or Mac computer',
      speakerOrMicrophone: 'Speakers and microphone',
      reliableInternet: 'Reliable internet',
      security: 'Security',
      governmentId: "Government ID - a passport or driver's licence is preferred",
      singleMonitor: 'A single monitor or display – any extra displays must be disabled',
      frontFacingCamera: 'A front-facing camera',
      environment: 'Environment',
      sixtyMinutes: 'Up to 60 minutes, depending on the test you choose',
      quietWellLit: 'A well-lit space',
      privateSpaceWith: 'Privacy - you’ll need to be alone and uninterrupted'
    },
    min: 'min'
  },
  languageSwitcher: {
    ENG: 'EN',
    JPN: 'JP',
    KOR: 'KO',
    english: 'English',
    japanese: 'Japanese',
    korean: 'Korean'
  },
  takenOn: 'Taken on',
  validThrough: 'Valid through',
  homePage: {
    cta: {
      text: 'Certify your English skills now',
      describe: 'Take our 1-hour test anytime, and get accurate results in minutes'
    },
    ctaFlexible: {
      text: 'Find the right test for you',
      describe:
        'Test only what matters to you, whether that’s reading and writing, speaking and listening, or all 4 skills!'
    },
    ctaMyTopScore: {
      text: 'Shine with MyTopScore',
      describe: `With MyTopScore, you get to share your best performance.<margin />
 When you take more than one test, we automatically select your highest scores in each skill so you can stand out from the crowd.`
    },
    ctaShowYourCredly: {
      text: 'Earn a Credly digital badge',
      describe: `With the <bold>{{productName}}</bold>, you’ll also get an official Credly badge. Add it to your CV, email signature, social media profiles, and online portfolios to let others know you have real-world English skills.`
    },
    enterYourEmail: 'Enter your email',
    exploreTests: 'Explore tests',
    discoverCredly: 'Discover credly',
    exploreResources: 'Explore resources',
    learnAboutTopscore: 'Learn about MyTopScore',
    pearsonTestsAreTrusted: 'Pearson tests are trusted by leading institutions and employers',
    fasterWayToShowEmployers: `{{productName}} is...`,
    featuresOfProduct: {
      fastTitle: 'Fast',
      fastDescription:
        'Get your results in minutes, and your shareable certified score within 48 hours',
      convenientTitle: 'Convenient',
      convenientDescription:
        'No test center or traveling needed. Take a test online, anywhere, anytime',
      authenticTitle: 'Authentic',
      authenticDescription:
        'Evaluate your true English communication skills – not your ability to pass a test'
    }
  },
  allRightsReserved: '© 2024 Pearson All rights reserved.',
  footerLinks: {
    termsOfUse: 'Terms of use',
    privacy: 'Privacy',
    cookies: 'Cookies',
    AOSCT: 'Act on Specified Commercial Transactions'
  },
  generatedOn: 'Generated on',
  shareOnCredly: 'Your Credly badge',
  skillsLabel: 'Skills',
  myOverallProficiency: 'My Overall Proficiency',
  gseScore: 'GSE score',
  cefrScore: 'CEFR',
  profilePage: {
    close: 'Close',
    profileTab: 'Profile',
    orderHistoryTab: 'Order history',
    sharedScoresTab: 'Shared scores',
    searchPlaceholder: 'Search',
    allSentScores: 'Scores you’ve shared',
    backToAllSentScores: 'Back to scores you’ve shared',
    turnSharingOff: 'Stop sharing',
    shareDetailsTooltipTitle: `When consent is withdrawn all emails that had access to your scores
 will no longer be able to view your certificate.
 Note that this action cannot be undone.`,
    scoreNotAvailable:
      'These scores can no longer be accessed by the receipients you had initially shared them with',
    scoresTableHeaders: ['Organization', 'Email', 'Date shared', 'Status'],
    dateShared: 'Date shared',
    email: 'Email',
    allOrderHistoryTableHeaders: [
      'Order Name',
      'Date purchased',
      'Order status',
      'Total paid',
      ' '
    ],
    allOrderHistoryTableHeadersShortcut: ['Order Name', '', '', 'Total paid'],
    allSentScoresTableHeaders: ['Test name', 'Test ID', 'Score', 'Date generated', 'Shared with'],
    viewed: 'Viewed',
    notViewed: 'Not viewed',
    automaticShareInfoTooltip:
      'This score is automatically visible by the organization that invited you to sit this test. You cannot disable sharing in this instance.',
    dontHaveAnyHistoryOrders: "You don't have any order history",
    requestRefund: 'Request a refund',
    processingOrder: 'Processing order...',
    youAreAllSet: 'We’ve initiated your refund',
    noLongerRefundable: 'This test cannot be refunded',
    noAvailableTestsForRefund: 'No tests eligible for a refund',
    testsAlreadyRefundedDescription: 'This order was already refunded',
    refundInProgressTitle: 'Want to request another refund?',
    refundInProgressDescription:
      'For this order, please wait till we’ve processed your previous refund request',
    testsExpiredForRefundDescription: 'Tests on this order were already taken or have expired',
    cannotRequestARefund:
      'If it has been over 90 days since you bought the test or if you have already started it, you will not be eligible for a refund. ',
    requestARefund: 'Request a refund',
    jitNotice: 'The test has been started and cannot be refunded.',
    downloadReceipt: 'Download receipt',
    weHaveInitiatedYourRefund:
      'Depending on your bank, it may take up to 10 days for the funds to appear in your account',
    selectProducts: 'Select the items you wish to cancel for a refund',
    paid: 'Paid',
    fulfilled: 'Fulfilled',
    refunded: 'Refunded',
    partiallyRefunded: 'Partially refunded',
    error: 'Error',
    done: 'Done',
    refund: 'Request refund',
    pending: 'Pending',
    denied: 'Denied',
    cancelled: 'Declined',
    titleAllOrders: 'All your orders',
    recentOrders: 'Recent orders',
    accordionHeaders: ['Item', 'Quantity', 'Cost'],
    accordionHeadersShortcut: ['Item', 'Cost'],
    refundPopupHeaders: ['Item', 'Date purchased', 'Cost'],
    basicInformation: 'Basic information',
    edit: 'Edit',
    demographicDetails: 'Demographic Details',
    fullName: 'Full name',
    dateOfBirth: 'Date of birth',
    gender: 'Gender',
    countryOfResidence: 'Country of residence',
    cityOfResidence: 'City',
    citizenship: 'Nationality',
    languages: 'Languages',
    securityAndPrivacy: 'Security & privacy',
    managePasswordHeader: 'Manage password',
    managePreferencesHeader: 'Manage preferences',
    managePreferencesSave: 'Save preferences',
    managePreferencesPersonalInfoHeader: 'Personal information',
    managePreferencesMarketingHeader: 'Marketing communications',
    managePreferencesText: 'Customize your personalized content according to your preferences',
    managePasswordText: 'Change your password',
    privacyPolicyHeader: 'Privacy policy',
    privacyPolicyText: 'Review Pearson Online Products Privacy Policy',
    termsOfUseHeader: 'Terms of use',
    termsOfUseText: 'Review Pearson Online Products Terms of Use',
    editProfileTitle: 'Edit your profile information',
    changeYourPassword: 'Change your password',
    editProfileWebTips:
      '<p><bold>Given name(s):</bold> enter your first, middle and any other names, as they appear on your ID</p> <p><bold>Surname:</bold> enter your surname or family name exactly as it appears on your ID.</p>',
    editProfileFormDescription:
      "To take the test, you will need a valid photo ID. Please enter your name <bold>exactly</bold> as it appears on your ID. If your ID is not in English, don't worry! Just enter your name in the language of your ID.",
    editProfileFormGivenName: 'Given name(s)',
    editProfileFormGivenNameTooltip:
      'Please make sure that your name matches exactly what is in your government issued ID.',

    editProfileFormSurname: 'Surname',
    editProfileFormSurnameTooltip:
      'Please make sure that your surname matches exactly what is in your government issued ID.',
    countryOfCitizenship: 'Country of Citizenship',
    countryOfCitizenshipTooltip:
      'Please note that your citizenship must match the citizenship in the ID that you will provide during the test.',
    startTypingPlaceholder: 'Start typing ...',
    editProfileFormEmail: 'Email',
    next: 'Next',
    firstLanguage: 'First language',
    scoreSharingHeader: 'Score sharing details',
    sharedWithPeople_zero: '0 people',
    sharedWithPeople_one: '1 person',
    sharedWithPeople_other: '{{count}} people'
  },
  noOptions: 'No options',
  english4SkillsTestName: 'English Certificate | 4-Skills',
  myTopScoreSeparated: 'My Top Score',
  yourTopScoreIsCalculatedFrom:
    'Your <boldItalic>myTopScore</boldItalic> is calculated from your top scores in each skill. Take a test to see your best score here!',
  dashboardPage: {
    welcome: 'Welcome <highlight>{{name}}</highlight>',
    welcomeTitle: 'Welcome {{name}}',
    welcomeBack: 'Welcome back, <highlight>{{name}}</highlight>',
    welcomeBackTitle: 'Welcome back, {{name}}!',
    headerText:
      'You’re just a few steps away from getting your <bold>English skills certified!</bold>',
    beforeStartModalSubtitle: 'Practice for free',
    beforeStartModalTitle: 'Before you start',
    beforeStartModalText: 'To take a test, here’s what you need to do:',
    beforeStartModalTextPractice: 'To take a practice test, here’s what you need to do:',
    beforeStartModalText2: 'Ready? Use a computer to download and install the test application.',
    beforeStartModalListItem1: 'Download the test application <bold> (2 min)</bold>',
    beforeStartModalListItem2: 'Set up your test environment <bold> (2 min)</bold>',
    beforeStartModalListItemPractice3: 'Take the practice test <bold> (15 - 30 min)</bold>',
    beforeStartModalListItem3: 'Take the test <bold> (30 - 60 min)</bold>',
    backToDashboard: 'Back to Dashboard',
    retakeTestButton: 'Retake test',
    finalParagraph: 'Ready? Use a computer to download and install the test application.',
    finalParagraphProductName:
      'Ready? Use a computer to download and install the <bold>{{productName}}</bold> application.',
    beforeStartModalButton: 'Download application',
    retake: 'Retake',
    SoftProfileAllDoneText:
      'To take the test, you will need to download the <bold>English Certificate</bold> app on your desktop.',
    SoftProfileAllDoneButton: 'Go to Dashboard',
    getReady: 'Get ready',
    youAreAllDone: 'You’re all done, thank you!',
    accordionTitle: 'Buy an English test',
    accordionSubtitle: 'Certify the skills you need to prove',
    accordionPracticeTitle: 'Practice for free',
    accordionPracticeSubtitle: 'Try a practice test',
    accordionTakeTestTitle: 'Take a test',
    accordionTakeTestButtonText: 'Start test',
    accordionLatestTestResult: 'Latest test: <span>{{score}}</span>',
    accordionShareResultsTitle: 'Share results',
    accordionShareResultsSubtitle:
      'Send your results to organizations and share your Credly badge ',
    accordionLatestTestTitle: 'View results',
    badgeMyTopScore: 'My Top Score',
    badgeValidThrough: 'Valid through: <bold>{{date}}</bold>',
    badgeViewDetails: 'View details',
    badgeNoTopScoreText:
      'Your <bold>myTopScore</bold> is calculated from your top scores in each skill. Take a test to see your best score here!',
    credlyBadgeTitle: 'Accept your Credly badge',
    credlyBadgeTitleRedeemed: 'Share your Credly badge',
    credlyBadgeLi1: 'Look out for an email from Credly',
    credlyBadgeLi2: 'Use the link in the email to create your Credly profile and accept your badge',
    credlyBadgeLi3: 'Then share your badge to show everyone your skills!',
    credlyBadgeButtonRedeemed: 'Go to Credly',
    credlyBadgeButton: 'Learn more about Credly',
    credlyBadgeRedeemedCongratulationsText: 'Congratulations, you accepted your Credly badge.',
    credlyBadgeRedeemedCongratulationsTextLine2:
      'Now it’s time to share it with others! Simply head to your Credly profile to get started.',
    credlyBadgeTaketestToGetCredly:
      'Take a test to get a Credly badge and share it with your contacts!',
    takeTestAccordionNoTestsText: 'Buy a test to certify your English skills',
    takeTestAccordionAvailableTestsTitle: 'Available Tests <span>{{availableTests}} test(s)</span>',
    takeTestAccordionPendingProductsTitle:
      'Pending orders <span>{{pendingProductsQuantity}} test(s)</span>',
    takeTestAccordionSignInButton: 'Browse tests ',
    formDownloadAccordionTitle: 'Before you start',
    formDownloadAccordionSubtitle: 'Take a test',
    formDownloadAccordionPracticeSubtitle: 'Practice for free',
    startJourneyStepperTitle: 'Here’s how to get started',
    startJourneyStepperStep1Description: 'Download the test application',
    startJourneyStepperTimeRequired: '{{time}} min',
    startJourneyStepperStep2Description: ' Set up your test environment',
    startJourneyStepperStep3Description: 'Verify your identity',
    startJourneyStepperStep4Description: 'Start the test',
    latestTestBoxTitle: 'Latest Test',
    fourSkillsTestTitle: 'English 4 Skills Test',
    practiceSubtitle: 'Practice for free',
    practiceTitle: 'Before you start',
    speakingAndListeningTestTitle: 'Speaking & Listening Test',
    readingAndWritingTestTitle: 'Reading & Writing Test',
    whatsNextTile: {
      whatYouCanDoNext: 'Here’s what you can do next:',
      takePracticeTest: 'Take a practice test',
      buyATest: 'Buy a test',
      startTest: 'Take a test',
      viewResults: 'View results',
      shareResults: 'Share results',
      redeemCode: 'Got a test to redeem? <action>Enter activation code</action>'
    },
    min: 'min',
    welcomeDialogPopupTitle: `Welcome to {{productName}}`,
    resultsAccordionTableHeaders: ['Tests', 'Date', 'Status', 'Overall score', 'CEFR', ''],
    practiceTestLabelButtonText: 'Practice',
    productTileButtonText: 'Ready to start',
    identifyIntroSection: {
      whatYouNeed: 'Here’s what you need',
      yourId: 'Official ID with your photo',
      governmentId:
        'This is a government-issued ID, such as an identity card or a passport. It must be valid for at least the next 3 months.',
      mobilePhone: 'Smartphone (optional) - recommended',
      weRecommend:
        'To take a clear photo of your ID, we recommend using a smartphone instead of your webcam',
      nextPage: 'Next'
    },
    practiceTestList: {
      errorMessage: 'There was an issue fetching available practice tests'
    },
    resultTestPanel: {
      errorMessage: "Sorry, we can't load your tests right now. Please try again.",
      btnErrorMsg: 'Try again'
    },
    backToAllResults: 'Back to all results',
    shareScoresDownloadCertificate: 'Share scores/Download certificate',
    startTest: 'Start test',
    downloadScoreCard: 'Download score card',
    buyTest: 'Buy test',
    productTileCounter: '{{items}} items',
    seeAllResults: 'See all results',
    noScore: 'No score',
    redeemActivationCodeTile: {
      text: 'Got a test to redeem? Enter your activation code below',
      buttonText: 'Redeem test'
    },
    takeTestAccordionPendingProductsRefresh: 'Refresh',
    viewOrderDetails: 'View order details'
  },
  helpCenterPage: {
    header: 'Help center',
    headerText: 'Find out how we can can help you in your journey with {{productName}} tests.',
    sidebarText: 'Have another question? <a>Contact Us</a> at any time for assistance.',
    activeCategory: 'About the test',
    categories: 'Categories'
  },
  survey: {
    caption1: 'Terrible',
    caption2: 'Not so great',
    caption3: 'Good',
    caption4: 'Great!',
    caption5: 'Outstanding!!',
    subheader: 'BEFORE YOU GO',
    header: 'Rate your experience',
    headerAlternative: 'Thank you for your feedback!',
    subheader2: 'Tell us how your test experience was',
    textFieldLabel: 'Tell us more',
    button: 'Submit',
    buttonAlternative: 'Go to Dashboard',
    reason: {
      subheader: 'SHARE YOUR FEEDBACK',
      header: 'Before you go...',
      subheader2: 'Please let us know what happened:',
      textFieldLabel: `Anything you'd like to share?`
    },
    leaveOnEnvSetup: {
      reason1: 'I had issues with the test setup',
      reason2: "I don't have time to take the test now"
    },
    leaveOnIDVerification: {
      reason1: 'I had issues with the ID verification',
      reason2: "I don't have time to take the test now"
    }
  },
  dateGenerated: 'Date generated',
  sharedWith: 'Shared with',
  testCode: 'Test code',
  testTakerId: 'Test Taker ID',
  stopSharing: 'Stop sharing',
  allSharedScores: 'All shared scores',
  allScoresYouShared: 'All scores you’ve shared',
  doYouWantStopSharing: `Do you want to stop sharing your <bold>{{productNames}}</bold>? By confirming, all the selected contacts that previously received your certificate will lose access to this. You cannot undo this action.`,
  orderConfirmation: {
    thanksForPurchase: 'Thank you for your order!',
    processingOrder: 'We are processing your order number <bold>#{{orderNumber}}</bold>',
    onceCompleted:
      'Once completed, you will receive a confirmation email with the full details of your purchase. This usually takes a few minutes.',
    inTheMeanwhile:
      'In the meanwhile, you can also track your order status by visiting your Dashboard.',
    questions: 'If you have any questions, please contact our <a>support team</a>.',
    goToDashboard: 'Go to Dashboard',
    viewOrderHistoryButton: 'View order history',
    errorTitle: 'Oops!',
    errorMessage: 'It looks like you have selected an incorrect link. Please try again.',
    print: 'Print'
  },
  pendingProductsNotification: 'You have <a>{{count}} test(s)</a> pending processing',
  cancelledOrderNotification: 'Your order was declined. <a>View order details</a>',
  cancelledOrder: {
    caption: 'Orders',
    title: 'Your order could not be completed',
    textLine1:
      'We regret to inform you that your recent order <bold>#{{orderNumber}}</bold> could not be completed at this time.',
    textLine2:
      "If you believe this is a mistake or need further assistance, please contact our <a>support team</a>. We're here to help clarify the situation and assist with any questions you may have.",
    viewOrderHistory: 'View order history',
    close: 'Close'
  },
  processingOrderBanner: {
    description: 'We are processing your latest order. Check back later to see your purchase...',
    orderId: 'Order ID:',
    dateOfPurchase: 'Date of purchase:'
  },
  gettingReadyPage: {
    heroHeader: 'Everything you need to let your English skills shine',
    heroText: 'Explore our free resources to help you get the most from your test experience.',
    heroPracticeButtonText: 'Start practice test',
    heroDownloadButtonText: 'Download test guide',
    getStartedHeader: 'How to get started',
    getStartedText: 'Your step-by-step guide to each stage of your test journey',
    getStarted1Title: 'Get ready',
    getStarted1Link: 'Explore preparation',
    getStarted1Points: [
      'Create your free account',
      'Download the test app to your computer',
      'Practice for FREE – as many times as you need',
      'Read our test guide'
    ],
    getStarted2Title: 'Take your test',
    getStarted2Link: 'Check requirements',
    getStarted2Points: [
      'Purchase your test',
      'Launch the test app when you’re ready',
      'Complete your pre-test set-up & ID check',
      'You’re ready to start! You’ll need up to an hour to complete your test'
    ],

    getStarted3Title: 'After your test',
    getStarted3Link: 'Read more about results',
    getStarted3Points: [
      'Get verified results within 48 hours',
      'Share your Certificate with potential employers',
      'Accept and share your Credly badge'
    ],
    howToHeader: 'Or watch our how-to guides',
    howToVideo1Title: 'Getting ready for the test',
    howToVideo1Subtitle:
      'Find out how to sign up for your test, prepare and download the test application',
    howToVideo2Title: 'Taking the Test',
    howToVideo2Subtitle: 'Find out how to start your test, check equipment and verify your ID',
    howToVideo3Title: 'Understanding your results',
    howToVideo3Subtitle: 'Understand your scores, certificate and MyTopScore™',
    howToVideo4Title: 'Sharing your results',
    howToVideo4Subtitle: 'Learn how to share your certificate with employers and institutions',
    prepareForTestHeader: 'Prepare for your test',
    prepareForTestLeftHeader: 'Practice the test',
    prepareForTestLeftText:
      'The best way to achieve a good score is to practice. Take mock tests for free, whenever you like and as often as you like.',
    prepareForTestLeftButton: 'Take a free practice test',
    prepareForTestRightHeader: 'Read the test guide',
    prepareForTestRightText:
      'The ultimate guide to the test itself, plus preparation, set-up guidance, scoring, and sharing. Available for free to download.',
    prepareForTestRightButton: 'Download the test guide',
    exploreTestHeader: 'Explore test question types',
    exploreTestText:
      'Get familiar with the question types in our tests, and how to respond to question prompts.',
    exploreTestVideo1title: 'English Certificate | 4-Skills',
    exploreTestVideo1Description: 'A video walkthrough of the 4-skills test',
    exploreTestVideo2title: 'English Certificate | Speaking & Listening',
    exploreTestVideo2Description: 'A video walkthrough of the Speaking & Listening test',
    exploreTestVideo3title: 'English Certificate | Reading & Writing',
    exploreTestVideo3Description: 'A video walkthrough of the Reading & Writing test',
    systemRequirementsHeader: 'Check system requirements',
    systemRequirementsText:
      'Before you start, make sure you have everything you need for a successful test.',
    systemRequirementsItem1Title: 'System',
    systemRequirementsItem1Line1: 'Windows or Mac computer',
    systemRequirementsItem1Line2: 'A speaker or microphone',
    systemRequirementsItem1Line3: 'Reliable internet',
    systemRequirementsItem2Title: 'Security',
    systemRequirementsItem2Line1: 'Government ID – a passport or driver’s license is preferred',
    systemRequirementsItem2Line2: 'A single monitor - dual screen setups will need to be disabled',
    systemRequirementsItem2Line3: 'A front-facing camera',
    systemRequirementsItem3Title: 'Environment',
    systemRequirementsItem3Line1: '60 mins – to complete your test uninterrupted',
    systemRequirementsItem3Line2: 'A well-lit space',
    systemRequirementsItem3Line3: 'A private space with no one else present',
    resultsHeader: 'Check and share your results',
    resultsText:
      "Our customer promise is to certify your score within 48 hours, so you don't waste time waiting for results. To be certified, your test must pass our security checks. Find out more about our certification policies in the Help center and in the Official guide.",
    resultsItem1Title: 'Within 1 hour',
    resultsItem1Subtitle: 'See your score indication',
    resultsItem1Description:
      'We will share your provisional score ahead of our final security checks',
    resultsItem2Title: 'Within 48 hours',
    resultsItem2Subtitle: 'Get your score certificate',
    resultsItem2Description:
      'We will let you know as soon as we complete our security checks. If your test met our security requirements, your Certificate will be ready for you in your results dashboard.',
    resultsItem3Title: 'You’re all set!',
    resultsItem3Subtitle: 'Share your results',
    resultsItem3Description:
      'You can share your score directly with employers from your results dashboard, or download your Certificate as a PDF. You can also take advantage of a Credly badge when you have all 4 skills certified.',
    resultsTitle2: 'MyTopScore at your fingertips',
    resultsText2:
      'We make sure you put your top score forward. Your certificate will automatically take your highest scores in Speaking, Listening, Reading and Writing over a 6-month period. So you can share your score with pride.',
    resultsTitle3: 'Shine with a Credly badge',
    resultsText3:
      'With each MyTopScore you get a globally recognized digital Credly badge to help you share and celebrate your achievement. Simply add it to your resume and share it via social media to allow potential employers to quickly see your English abilities.',
    resultsButton: 'Find out more about Credly',
    faqHeader: 'Common questions',
    faqReadMore: 'Read more questions in the Help center',
    downloadDialogSubtitle: 'Test Readiness',
    downloadDialogTitle: 'Download Test Taker Guide',
    startTestDialogSubtitle: 'BEFORE YOU START',
    startTestDialogTitle: 'A few things to keep in mind...',
    startTestDialogParagraph1:
      'In order to take the practice test, you’ll need to complete some steps:',
    startTestDialogLi1: 'Create an account',
    startTestDialogLi2: 'Download the Test app',
    startTestDialogParagraph2: 'Remember, for the practice test you will need:',
    startTestDialogLi3: 'A PC or Mac computer',
    startTestDialogLi4: 'A front-facing camera',
    startTestDialogButtonClose: 'Close',
    startTestDialogButtonContinue: 'Continue',
    downloadPopupTitle: 'Download Application',
    downloadPopupSubtitle: 'Get ready',
    formDownloadTitle: 'Taking a test',
    faqIAccordion1Title: 'Which IDs are accepted by the verification system?',
    faqIAccordion1Description:
      'Before the test, you will need to provide identification (ID) that meets the requirements outlined below:',
    faqIAccordion1List1: [
      'All IDs must be valid government-issued originals (i.e., not a photocopy)',
      "IDs must include the test-taker's name and a recent, recognizable photo",
      'The first and last name on the ID must match the first and the last name you registered in your profile information.',
      'Expired documents will not be accepted'
    ],
    faqIAccordion1Description2: 'Acceptable forms of ID:',
    faqIAccordion1List2: [
      'International travel passport',
      'Driver’s license',
      'Identification card (national, state or province ID card)',
      'Alien registration card (green card or permanent resident/visa)'
    ],
    faqIAccordion1Description3: 'Unacceptable forms of ID:',
    faqIAccordion1List3: [
      'Restricted IDs*, including the U.S. Department of Defense (DoD) Common Access Card (CAC) or certain secure access IDs',
      `IDs from countries where {{productName}} is restricted: North Korea, Cuba, Syria, Sudan, Iran, Russia, Belarus and the Sevastopol/Crimea region of Ukraine.`
    ],
    faqIAccordion1Description4: `*{{productName}} cannot accept restricted IDs (those prohibited by law from being photocopied, digitised, or captured on camera) as identification for online testing.`,
    faqIAccordion2Title: 'What is the proctoring window?',
    faqIAccordion2Description:
      'Throughout the testing experience our proctoring system analyses your behaviour to ensure no cheating or suspicious actions have occurred. It is vital that you follow our proctoring rules in order to get your test result certified.',
    faqIAccordion2Description2: 'Here are recommendations for you to keep in mind:',
    faqIAccordion2List1: [
      'Take the test in a quiet environment where there is no background noise',
      'Take the test in a well-lit place',
      'Make sure you are alone for the duration of the test.'
    ],
    faqIAccordion2Description3: 'ID:',
    faqIAccordion2Description4:
      'You can find more information under the FAQ section “Acceptable forms of ID”',
    faqIAccordion2List2: [
      'Your account details (name, last name, etc.) must match the information on your government-issued ID card.',
      'Do not use somebody else’s personal details or ID.'
    ],
    faqIAccordion3Title: 'My test appears as “pending review”. What does this mean?',
    faqIAccordion3Description:
      'It means that your test session is currently being analysed by our human reviewers. Depending on the outcome of their review, your test will appear as either “Certified” or “Unable to certify” in your dashboard.',
    faqIAccordion3Description2:
      'You will be notified via email as soon as our security review is complete. We are committed to completing this within 48 hours of your completing the test. If your test appears as “Certified” you can view, download, or directly <bold>share</bold> your certificate with as many organisations as you like.',
    formDownloadText:
      'Please note that to take the test, you will need to download the <bold>English Skills Certificate app</bold> on your desktop.',
    formDownloadClose: 'Close'
  },
  footerGetInTouchHeader: 'Get in touch',
  footerGetInTouchText: 'Start a conversation today with one of our trusted advisors',
  footerGetInTouchButton: 'Contact us',
  welcomePopup: {
    subtitle: 'Welcome',
    mainTitle: `Welcome to {{productName}}`,
    title: 'This is your dashboard. Get ready for test success.',
    button: 'Done',
    takeATest: 'Take a test'
  },
  signInPage: {
    haveAccount: 'Looks like you may already have an account.',
    enterPassword: 'Enter your password to continue.'
  },
  scoreLookup: {
    lookup: 'Certificate lookup',
    description1:
      'To access a certificate please enter the information below. If you are having trouble, visit our',
    description2: 'Support Page',
    description3: 'for more information.',
    name: 'Test taker name',
    required: 'This field is required',
    org: 'Name of Organization',
    country: 'Country (optional)',
    consent:
      'By clicking this box, I confirm that I have been given permission to access the certificate form the test taker.',
    viewCertificate: 'View Certificate',
    byClickingOn: 'By clicking on',
    youAreAgreeing: 'you are agreeing to our',
    privacyPolicy: 'Privacy Policy',
    termisOfUse: 'Terms of Use',
    cookiePolicy: 'Cookie Policy',
    tooltip: 'We’ll use this data for internal reporting purposes only.',
    expiredDescription:
      'Oops, the link has expired and the scores are no longer accessible. Please contact the test taker to request a new link.',
    supportLink: `If you are having trouble, visit our <a>Support page</a> for more information.`,
    expiredButton: 'Back to Homepage'
  },
  mins: ' mins',
  'No score': 'No score',
  toast: {
    somethingWentWrong: 'Oops! Something went wrong. Please try again'
  },
  mobileUploadId: {
    next: 'Next',
    takeAPhoto: 'Take a photo of your ID',
    toBegin: 'To begin put your ID on a solid, well-lit surface.',
    takePicture: 'Take picture',
    takeAPhotoBack:
      'Thank you for submitting a photo of the front of your ID. If you need to submit the back of your ID click on the empty frame.',
    frontAccepted: 'The front of your ID was captured',
    important: 'IMPORTANT',
    importantInfo:
      'For any card-style IDs you will need to submit both the front and the back of your ID. For passports, one image only will be sufficient.',
    frontOfId: 'Front of ID',
    use: 'Use',
    tryAgain: 'Try again',
    retry: 'Retry',
    tapToContinue: 'Click the frame below to submit the back of your ID or press Skip to continue.',
    skip: 'Skip Back of ID',
    backCaptured: 'The back of your ID was captured',
    backOfId: 'Back of ID',
    sessionExpired: 'Your session has expired!',
    outOfTime:
      'Please head back to the {{productName}} application on your computer to generate a new QR code',
    done: 'Done',
    allDone: 'ID submitted',
    toContinue:
      'To continue with the test, return to the {{productName}} application on your computer',
    thanksForCompleting: 'Thanks for completing the process. You can close this window now.',
    photoDidntMeetRequirements: 'The photo didn’t meet the requirements',
    pleaseTryAgain:
      'Please try again. Keep these guidelines in mind when taking photos of your ID.',
    guidelines: 'Photo guidelines',
    missingDetails: 'Missing details',
    blurry: 'Blurry',
    partiallyCovered: 'Partially covered',
    expired: 'Expired',
    clear: 'Clear, full details',
    frontTitle: 'Step 1 of 2: Front of ID',
    backTitle: 'Step 2 of 2: Back of ID',
    takeAClearPhoto:
      'Please take a <bold>clear photo of your valid ID.</bold> Here are a few examples of photos that will not be accepted.',
    usingPassport: 'Using a passport? <submit>Submit front of ID only</submit>',
    guidelinesLink: 'Check photo guidelines',
    frontLineUp: 'Place your ID in the frame and take a photo',
    backLineUp: 'Place the back of your ID in the frame and take a photo.',
    errorHeader: 'Use a smartphone instead',
    errorDescription:
      'This page can’t be accessed from a web browser. To submit your ID, make sure you use a smartphone to scan the QR code.',
    useQrCode: 'Please use your mobile phone to scan the QR code above to continue.',
    alreadySubmittedTitle: 'You have already submitted your ID',
    alreadySubmittedDescription: 'Thank you, there’s nothing left to do here',
    linkOnlyValidFor:
      'The link to upload your ID on mobile is only valid for <bold>14 days</bold>. Please contact <a>Support</a> for additional assistance.',
    timeLeftToSubmit: 'Time left to submit your ID',
    linkNotValidAnymore: 'This link is no longer valid',
    left: 'left',
    checkingPhoto: {
      checking: 'Checking your photo…',
      success: 'This photo meets our guidelines',
      retakePhoto: 'Retake photo',
      continue: 'Continue',
      submitPhotos: 'Submit ID photos',
      UNDEREXPOSED: {
        title: 'Not enough light',
        subtitle: 'Please take another photo of your ID. Make sura you are in a well-lit place.'
      },
      OVEREXPOSED: {
        title: 'Too much light',
        subtitle: 'Please take another photo of your ID. Make sure there is not too much light.'
      },
      BLURRY: {
        title: 'Photo is blurry',
        subtitle: 'Please take another photo of your ID.'
      },
      ATTEMPT_LIMIT_EXCEEDED: {
        title: 'Photo guidelines not met',
        subtitle:
          "Don't worry, you can continue with the test. Our security agents may ask you for another photo of your ID after the test."
      },
      LOW_RESOLUTION: {
        title: 'Low resolution photo',
        subtitle: 'Please take another photo of your ID. If possible, try using a different phone.'
      }
    }
  },
  skillsTile: {
    weWereUnable:
      'We were unable to certify your scores. Click on <bold>View my scores</bold> below to see score details.'
  },
  testTile: {
    weAreUnable:
      'We’re unable to provide you with a score since this test was not completed. Please retake the test for free.',
    ifNeedHelp: 'If you need help, please contact our <a>Support</a> team.',
    testId: 'Test ID',
    weCantProvide:
      'We can’t provide you with certified test results since the test rules were violated. If you have any questions, please contact our <a>Support</a> team.',
    dueToProblem:
      'We’re sorry. Due to a problem on our end, we couldn’t provide a score for this test. Please retake the test for free.'
  },
  certificateTypes: {
    readingWriting: 'English Certificate | Reading & Writing',
    speakingListening: 'English Certificate Speaking & Listening'
  },
  availableTestsNotification: 'You have <a>{{count}} test(s)</a> available',
  clickHereToSubmit: 'Click here to submit the back of your ID or press Skip to continue.',
  webResultsSurvey: {
    certified: {
      upperTitle: 'SHARE YOUR FEEDBACK',
      title: 'How was your test experience?',
      subTitle: 'Congratulations on your certificate! How was your experience with {{productName}}?'
    },
    incomplete: {
      question1: {
        title: 'Tell us what happened with your test',
        option1: 'I no longer wanted to complete the test',
        option2: 'I had a technical issue',
        option3: 'Other (please specify below)'
      },
      question2: "Anything you'd like to share?",
      question3: {
        title: 'Will you retake the test (for free) in the next 5 days?',
        option1: 'Yes',
        option2: 'No'
      }
    },
    uncertified: {
      subTitle:
        'Unfortunately, your test could not be certified. Please help us better understand your situation.',
      scaleMinLabel: 'Strongly agree',
      scaleMaxLabel: 'Strongly disagree',
      q1: 'I understand why my test could not be certified',
      q2: 'I know what to do to improve my test conditions',
      q3: 'I plan to take the test again'
    },
    firstPurchase: {
      title: 'How was your purchase experience?',
      subtitle: 'Thanks for buying a test! Please rate your experience.'
    }
  },
  limitReachedModal: {
    title: 'Limit reached',
    close: 'Close'
  },
  policiesLinks: {
    termsOfUse: 'Terms of Use',
    privacyPolicy: 'Privacy Policy',
    actOnSpecifiedCommercial: 'Act on Specified Commercial'
  },
  activationCodePopup: {
    title: 'Do you have a test to redeem?',
    inputTitle: 'Activation code',
    subtitle: 'ADD TEST',
    description:
      'If you received an activation code to redeem a test, enter it below. After using the code, you will have 90 days to take the test.',
    gotADiscountCodeInstead:
      '<bold>Got a discount code instead?</bold> Apply it to your order when you buy a test on our website',
    gotTestToRedeem: 'Got a test to redeem? <bold>Enter activation code</bold>',
    inputPlaceholder: 'ABC-XXXX-XXXX-XXXX',
    buttonText: 'Redeem test',
    tooltip:
      'If an organization sent you an English test, you would have received an email from us with the activation code',
    tooltipExtended:
      'If an organization sent you an English test, you would have received an email from us with the activation code <p><bold>Got a discount code instead?</bold> Apply it to your order when you buy a test on our website</p>',
    skip: 'Skip',
    next: 'Next',
    cancel: 'Cancel',
    done: 'Done',
    validMessage: 'You’ll find your test in your account',
    code: {
      email: {
        mismatch: 'The activation code doesn’t match your email'
      },
      already: {
        used: 'The activation code has already been used'
      },
      expired: 'The activation code has expired',
      not: {
        found: 'The activation code you entered is invalid',
        assigned: {
          to: {
            tt: 'The activation code doesn’t match your email'
          }
        }
      }
    },
    tooManyAttempts: 'Oops! You’ve made too many attempts. Please try again later'
  },
  pageNotFound: {
    title: 'Oops! Looks like you’re lost somewhere in space',
    description: 'The page you’re looking for cannot be found',
    button: 'Back to Homepage'
  },
  orderHistory: {
    testWasFree: 'This test was free and therefore cannot be refunded.',
    alreadyRefunded: 'This order has been refunded',
    testAlreadyRefunded: 'This test has been refunded'
  },
  downloadCertificatePopup: {
    wrapperTitle: 'Download certificate',
    subtitle: 'Select the language you would like to download in below:',
    buttonText: 'Download',
    myTopScore: 'MYTOPSCORE',
    speakingListening: 'SPEAKING & LISTENING',
    readingWriting: 'READING & WRITING',
    languages: {
      eng: 'English',
      jpn: 'Japanese',
      kor: 'Korean'
    }
  },
  sharedScoresDetailedTable: {
    headers: ['Name', 'Email', 'Date shared', 'Status', 'Sharing']
  },
  oAuthFailed: {
    title: 'Oops, account doesn’t exist',
    subtitle:
      'The email you entered does not have an existing account on Versant English Certificate. Please sign up to create an account.'
  },
  AOSCT: {
    title: 'Act on Specified Commercial Transactions',
    subtitle: 'Review our Act on Specified Commercial Transactions below',
    content: {
      1: {
        title: 'Organization name, address, representative name:',
        text: 'Pearson Japan K.K., 11F KANDA SQUARE 2-2-1 Kanda-Nishiki-cho, Chiyoda-ku, Tokyo 101-0054'
      },
      2: {
        title: 'Representative Director,',
        text: 'Yutaka Teranishi'
      },
      3: {
        title:
          'Contact Information: (including telephone number, telephone reception time and email address)',
        text: '9 am to 5 pm, except Saturdays, Sundays and holidays',
        text2: 'Tel: 03-4540-0380',
        text3: 'E-mail: '
      },
      4: {
        title:
          'Selling price: (including the selling price and any other costs such as consumption tax)',
        text: 'Listed at: '
      },
      5: {
        title: 'Payment Method:',
        text: 'The following credit cards are accepted: VISA and MasterCard. Google Pay is also accepted.'
      },
      6: {
        title: 'Payment Deadline:',
        text: 'For credit card payments, the fee will be charged upon completion of the transaction.'
      },
      7: {
        title: 'Time of Service Provision:',
        text: 'Immediately after the transaction.'
      },
      8: {
        title: 'Refund Policy:',
        text: 'You can apply for a refund within 90 days of purchase, provided the test is unused. The refund processing time will depend on your bank.'
      },
      9: {
        title: 'Cancellation Policy:',
        text: 'The same terms as the refund policy apply.'
      }
    }
  }
};
