import { theme } from '../../styles/theme';

export const availableTestsNotificationContainer = {
  borderRadius: '4px',
  border: `1px solid ${theme.palette.common.lightGrey1}`,
  background: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  whiteSpace: 'pre-wrap',
  position: 'relative',
  zIndex: '100',
  marginBottom: '8px',
  paddingRight: '36px',
  minHeight: '40px',
  '@media screen and (min-width: 720px)': {
    marginRight: '1rem'
  },
  a: {
    fontWeight: 600,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
};

export const availableTestsNotificationPipe = {
  width: '3px',
  backgroundColor: theme.palette.common.darkGreen,
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  position: 'absolute',
  left: '1px',
  top: '1px',
  bottom: '1px'
};

export const availableTestsNotificationClose = {
  position: 'absolute',
  right: '14px',
  top: '50%',
  transform: 'translateY(-50%)',
  cursor: 'pointer'
};
