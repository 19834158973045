import { theme } from '@theme';

export const orderCancelledPopupButtons = {
  gap: {
    sm: '24px'
  },
  mt: {
    xs: '26px',
    sm: '133px',
    md: '266px'
  }
};

export const orderCancelledPopupButton1 = {
  width: {
    xs: '100%',
    sm: 'auto'
  }
};

export const orderCancelledPopupButton2 = {
  mt: {
    xs: '16px',
    sm: 0
  },
  width: {
    xs: '100%',
    sm: 'auto'
  }
};

export const orderCancelledPopupLink = {
  textDecoration: 'underline',
  color: theme.palette.common.black,
  fontWeight: 600,
  cursor: 'pointer'
};
