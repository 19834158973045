import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import FourSkillTileImg from '../../assets/images/4skills-tile.svg';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { ELLCommerce, Loader, RetakeIndicator, User } from '@barracuda/shared/src';
import {
  CounterBox,
  FitWithCounter,
  LoaderContainer,
  ProductTileContainer,
  ProductTilePrice,
  ProductTileRow,
  ProductTileRowContainer,
  ProductTileSkills,
  ProductTileTime,
  ProductTileTitle,
  ReadyToStartBtn,
  RetakeIndicatorBox,
  SkillWrapper
} from './ProductTile.style';
import { Product } from '@pearson-ell/commerce-sdk';
import { SkillBullet } from '@barracuda/shared/src/components/PracticeTestLabel/PracticeTestLabel.style';
import ClockIcon from '@sharedImages/clock-updated.svg';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/App.context';
import { ellCommerceInstanceConfig } from '../../utils/ell-commerce';
import { buttonDark, buttonDarkSecondary } from '@barracuda/shared/src/styles/button';

export interface ProductTileProps {
  essId: string;
  data?: { skills: string[]; time: number };
  type: 'buy' | 'take';
  startTestHandle?: (retake?: boolean) => void;
  showCounter?: boolean;
  handleBuy: (pId: string, user?: User) => void;
  eCommerceCountryISO2: string;
  numberOfItems?: number;
  retake?: boolean;
}

export const ProductTile: FC<ProductTileProps> = ({
  showCounter,
  essId,
  data,
  type,
  startTestHandle,
  handleBuy,
  eCommerceCountryISO2,
  numberOfItems,
  retake
}) => {
  const [product, setProduct] = useState<Product | undefined>();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AppContext);
  const showSkills = useMediaQuery(
    '(min-width: 900px) and (max-width: 1385px), (max-width: 600px)'
  );
  const { t } = useTranslation();

  const eCommerce = ELLCommerce.getInstance({
    ...ellCommerceInstanceConfig,
    defaultCountryISO2: eCommerceCountryISO2
  });
  useEffect(() => {
    if (essId) {
      fetchProductDetails(essId);
    }
  }, [essId]);

  const fetchProductDetails = (essId: string) => {
    eCommerce
      .ProductService()
      .getProductBySku(essId, eCommerceCountryISO2)
      .then((r) => {
        setProduct(r || undefined);
        setLoading(false);
      })
      .catch((e) => {
        console.log('There was an error during fetching products.', e);
      });
  };

  return (
    <>
      {type === 'buy' && (
        <>
          {!loading && (
            <Stack sx={ProductTileContainer}>
              <Img
                width='100%'
                src={
                  product?.images?.find((i) => !i.isThumbNail)
                    ? product?.images?.find((i) => !i.isThumbNail)?.imageUrl
                    : FourSkillTileImg
                }
              />
              <Typography sx={ProductTileTitle}>{product?.name}</Typography>
              <Box sx={ProductTileSkills}>
                {data?.skills.map((skill, index) => (
                  <Box key={`skillBuyTest-${index}`} sx={SkillWrapper}>
                    {index !== 0 && <Box sx={SkillBullet} />}
                    <Typography>{t(skill.toLowerCase())}</Typography>
                  </Box>
                ))}
              </Box>
              <Box sx={ProductTileTime}>
                <Img sx={{ paddingRight: '5px' }} src={ClockIcon} />{' '}
                <Typography>
                  {data?.time} {t('dashboardPage.min')}
                </Typography>
              </Box>
              <Typography
                sx={ProductTilePrice}
              >{`${product?.currency?.symbol}${product?.price}`}</Typography>
              <Button
                sx={[buttonDarkSecondary, { marginTop: '25px', marginBottom: '15px' }]}
                onClick={() => handleBuy(essId, user)}
              >
                {t('dashboardPage.buyTest')}
              </Button>
            </Stack>
          )}
          {loading && (
            <Stack sx={LoaderContainer}>
              <Loader loading={loading} small size='50px' />
            </Stack>
          )}
        </>
      )}
      {type === 'take' && (
        <Box sx={ProductTileRowContainer(retake)}>
          <Img
            sx={{ width: '26%', minWidth: '100px' }}
            src={
              product?.images?.find((i) => i.isThumbNail)
                ? product?.images?.find((i) => i.isThumbNail)?.imageUrl
                : FourSkillTileImg
            }
          />
          {!retake && showCounter && numberOfItems && numberOfItems > 1 && (
            <Box sx={CounterBox}>
              {t('dashboardPage.productTileCounter', { items: numberOfItems })}
            </Box>
          )}
          {retake && (
            <Box sx={RetakeIndicatorBox}>
              <RetakeIndicator />
            </Box>
          )}
          <Box sx={{ marginLeft: '20px' }}>
            <Typography
              sx={[ProductTileRow, numberOfItems && numberOfItems > 1 ? FitWithCounter : {}]}
            >
              {product?.name}
            </Typography>
            {!showSkills && (
              <Box sx={ProductTileSkills}>
                {' '}
                {data?.skills.map((skill, index) => (
                  <Box key={`skill-${index}`} sx={SkillWrapper}>
                    <Typography>{skill}</Typography>{' '}
                    {index !== data?.skills.length - 1 && <Box sx={SkillBullet} />}
                  </Box>
                ))}
              </Box>
            )}
            <Box sx={ProductTileTime}>
              <Img sx={{ paddingRight: '5px' }} src={ClockIcon} />{' '}
              <Typography>
                {data?.time} {t('dashboardPage.min')}
              </Typography>
            </Box>
            <Button
              disabled={numberOfItems === 0}
              onClick={() => startTestHandle?.(retake)}
              sx={[buttonDark, ReadyToStartBtn]}
            >
              {retake
                ? t('dashboardPage.retakeTestButton')
                : t('dashboardPage.productTileButtonText')}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};
