import { BaseSyntheticEvent, FC, useContext, useState } from 'react';
import {
  CallToActionTop,
  CallToActionTopProps,
  DialogWrapper,
  EMAIL_REGEXP_PATTERN,
  FooterGetInTouch,
  ProductFeatures,
  ScalableWrapper,
  VideoPopup
} from '@barracuda/shared/src';
import { AppContext } from '../../context/App.context';
import { GettingReadyPageFooter } from '../GettingReadyPageFooter/GettingReadyPageFooter';
import { Box, Button, Grid, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import homePageCta from '../../assets/images/homePage--cta.png';
import homepageCtaFlexible from '../../assets/images/homePage--ctaFlexible.svg';
import homePageCtaMyTopScore from '../../assets/images/homePage--cta__myTopScore.png';
import showYourCredly from '@sharedImages/show-your-credly--image.png';
import fastFeature from '../../assets/images/featuresOfProduct--fast.svg';
import convenientFeature from '../../assets/images/featuresOfProduct--convenient.svg';
import authenticFeature from '../../assets/images/featuresOfProduct--authentic.svg';
import {
  homepageWrapperCallToActionTop,
  homepageWrapperCertifyYourSkills,
  homepageWrapperCertifyYourSkillsTextField,
  homepageWrapperCertifyYourSkillsTextFieldContainer,
  homepageWrapperCreateAccountButton,
  homepageWrapperDiscoverCredlyContainer,
  homepageWrapperErrorMessage,
  homepageWrapperMyTopScoreButton,
  homepageWrapperPaddingSmall,
  homepageWrapperSidePadding,
  homepageWrapperWatchVideoButton,
  limitContainerWidth
} from './HomepageWrapper.styles';
import Img from '@barracuda/shared/src/components/Primitive/Img';
import arrowRightBlue from '../../assets/images/arrow-right-blue--icon.svg';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { buttonOutlineDark } from '@barracuda/shared/src/styles/button';
import ArrowButtonIcon from '@sharedImages/watch-video-arrow-icon.svg';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { LocalizedNavLink } from '../LocalizedNavLink/LocalizedNavLink';

const REQUIRED = 'required';
const PATTERN = 'pattern';

export const HomepageWrapper: FC = () => {
  const { t } = useTranslation();
  const { isLoggedIn, ipCountry, eCommerceCountryISO2 } = useContext(AppContext);
  const theme = useTheme();
  const xsWindow = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useLocalizedNavigate();
  const [videoOpened, setVideoOpened] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isValid, touchedFields }
  } = useForm({
    criteriaMode: 'all',
    mode: 'all',
    reValidateMode: 'onChange'
  });

  const showAOSCT = isLoggedIn ? eCommerceCountryISO2 === 'JP' : ipCountry === 'JP';

  const featuresOfProduct = [
    {
      title: t('homePage.featuresOfProduct.fastTitle'),
      desc: t('homePage.featuresOfProduct.fastDescription'),
      image: fastFeature
    },
    {
      title: t('homePage.featuresOfProduct.convenientTitle'),
      desc: t('homePage.featuresOfProduct.convenientDescription'),
      image: convenientFeature
    },
    {
      title: t('homePage.featuresOfProduct.authenticTitle'),
      desc: t('homePage.featuresOfProduct.authenticDescription'),
      image: authenticFeature
    }
  ];

  const defaultFooterLinks = [
    { label: t('footerLinks.termsOfUse'), to: 'terms-of-use' },
    {
      label: t('footerLinks.privacy'),
      to: process.env.REACT_APP_PRIVACY_POLICY_LINK as string,
      external: true
    },
    {
      label: t('footerLinks.cookies'),
      to: process.env.REACT_APP_COOKIE_POLICY_LINK as string,
      external: true
    }
  ];

  const footerLinks = showAOSCT
    ? [
        { label: t('footerLinks.AOSCT'), to: 'act-on-specified-commercial-transactions' },
        ...defaultFooterLinks
      ]
    : defaultFooterLinks;

  const propsCallToAction: CallToActionTopProps = {
    textI18nKey: 'homePage.cta.text',
    subtitle: `${process.env.REACT_APP_GENERAL_PRODUCT_NAME}`,
    describeI18nKey: t('homePage.cta.describe'),
    image: homePageCta
  };
  const propsCallToActionFlexible: CallToActionTopProps = {
    textI18nKey: 'homePage.ctaFlexible.text',
    describeI18nKey: t('homePage.ctaFlexible.describe'),
    image: homepageCtaFlexible,
    smallTitle: true,
    columnGap: '158px'
  };
  const propsCallToActionMyTopScore: CallToActionTopProps = {
    textI18nKey: 'homePage.ctaMyTopScore.text',
    describeI18nKey: 'homePage.ctaMyTopScore.describe',
    image: homePageCtaMyTopScore,
    invert: true,
    smallTitle: true,
    columnGap: '123px'
  };
  const propsCallToActionShowYourCredly: CallToActionTopProps = {
    textI18nKey: 'homePage.ctaShowYourCredly.text',
    describeI18nKey: t('homePage.ctaShowYourCredly.describe', {
      productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
    }),
    image: showYourCredly,
    smallTitle: true,
    columnGap: '134px'
  };

  const handleNavigate = (url: string) => {
    const link = isLoggedIn ? `/dashboard/${url}` : `/${url}`;
    navigate(link);
  };
  const handleEmailChange = (event: BaseSyntheticEvent) => {
    setValue('email', event.target.value);
    trigger('email');
  };
  const signUpHandler = (data: any, e?: BaseSyntheticEvent) => {
    e?.preventDefault();
    navigate('/join', {
      state: {
        emailSignUp: data.email
      }
    });
  };
  return (
    <ScalableWrapper>
      <DialogWrapper
        key='homepage-video-modal'
        open={videoOpened}
        title=''
        subtitle=''
        handleClose={() => setVideoOpened(false)}
        clean
        videoWrapper
      >
        <VideoPopup
          onEnded={() => setVideoOpened(false)}
          url={`${process.env.REACT_APP_PESC_URL}${process.env.REACT_APP_PESC_INTRODUCTION_VIDEO_URL}`}
        />
      </DialogWrapper>
      <Grid
        container
        sx={{
          ...homepageWrapperCertifyYourSkills,
          ...(xsWindow && { padding: '10rem 2rem 5rem 2rem' })
        }}
      >
        <Grid
          item
          xs={12}
          sm={10}
          sx={{
            ...limitContainerWidth
          }}
        >
          <CallToActionTop {...propsCallToAction} invert={xsWindow}>
            <Box
              component='form'
              onSubmit={handleSubmit(signUpHandler)}
              sx={{
                display: 'flex',
                flexWrap: xsWindow ? 'wrap' : 'no-wrap',
                marginBottom: { xs: '3rem', sm: 0 },
                flexDirection: { xs: 'column' }
              }}
            >
              {!isLoggedIn && (
                <>
                  <Box sx={homepageWrapperCertifyYourSkillsTextFieldContainer}>
                    <TextField
                      {...register('email', {
                        required: t('thisIsRequired') as string,
                        pattern: {
                          value: EMAIL_REGEXP_PATTERN,
                          message: t('invalidEmail') as string
                        }
                      })}
                      error={
                        errors?.email?.type === REQUIRED ||
                        (errors?.email?.type === PATTERN && touchedFields.email)
                      }
                      type='text'
                      onChange={handleEmailChange}
                      label={t('homePage.enterYourEmail')}
                      id='enter-your-email'
                      sx={homepageWrapperCertifyYourSkillsTextField}
                      variant='outlined'
                    />
                    {errors?.email?.type === REQUIRED && (
                      <Typography sx={homepageWrapperErrorMessage}>
                        {t('fieldIsRequired')}
                      </Typography>
                    )}
                    {errors?.email?.type === PATTERN && touchedFields.email && (
                      <Typography sx={homepageWrapperErrorMessage}>
                        {t('results.invalidEmailAddress')}
                      </Typography>
                    )}
                  </Box>
                  <Button type='submit' disabled={!isValid} sx={homepageWrapperCreateAccountButton}>
                    {t('createAnAccount')}
                  </Button>
                </>
              )}
              <Button
                onClick={() => setVideoOpened(true)}
                sx={homepageWrapperWatchVideoButton(isLoggedIn)}
              >
                {t('watchTheVideo')}
                <Img id='arrow-icon' src={ArrowButtonIcon} />
              </Button>
            </Box>
          </CallToActionTop>
          <Box sx={{ height: '2rem' }}></Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          homepageWrapperCallToActionTop,
          display: 'flex',
          justifyContent: 'center',
          ...(xsWindow && { ...homepageWrapperSidePadding })
        }}
      >
        <Grid item xs={12} sm={10} sx={limitContainerWidth}>
          <Box
            sx={{
              ...homepageWrapperPaddingSmall,
              h1: { ...(xsWindow && { width: '100%' }) },
              ...(xsWindow && { width: '65%', margin: 'auto' })
            }}
          >
            <ProductFeatures
              features={featuresOfProduct}
              title={t('homePage.fasterWayToShowEmployers', {
                productName: process.env.REACT_APP_GENERAL_PRODUCT_NAME
              })}
            />
          </Box>
          <Box sx={homepageWrapperPaddingSmall}>
            <CallToActionTop {...propsCallToActionFlexible}>
              <LocalizedNavLink to={isLoggedIn ? '/dashboard/explore-tests' : '/explore-tests'}>
                <Button sx={buttonOutlineDark}>{t('homePage.exploreTests')}</Button>
              </LocalizedNavLink>
            </CallToActionTop>
          </Box>
          <Box sx={homepageWrapperPaddingSmall}>
            <CallToActionTop {...propsCallToActionMyTopScore}>
              <Button
                onClick={() =>
                  navigate(isLoggedIn ? 'dashboard/getting-ready' : 'getting-ready', {
                    state: '#my-top-score'
                  })
                }
                sx={{
                  homepageWrapperMyTopScoreButton,
                  ...(xsWindow && { marginBottom: '2rem' })
                }}
              >
                <span style={{ textTransform: 'initial' }}>{t('homePage.learnAboutTopscore')}</span>{' '}
                <Img sx={{ marginLeft: '.5rem' }} src={arrowRightBlue} />
              </Button>
            </CallToActionTop>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          ...homepageWrapperDiscoverCredlyContainer,
          ...(xsWindow && { ...homepageWrapperSidePadding })
        }}
      >
        <Grid item xs={12} sm={10} sx={limitContainerWidth}>
          <CallToActionTop {...propsCallToActionShowYourCredly}>
            <Button
              onClick={() => window.open('https://www.pearson.com/english/about-us/credly.html')}
              sx={[buttonOutlineDark, { marginBottom: { xs: '2rem', sm: 0 } }]}
            >
              {t('homePage.discoverCredly')}
            </Button>
          </CallToActionTop>
        </Grid>
      </Grid>
      <FooterGetInTouch />
      <GettingReadyPageFooter navigate={handleNavigate} footerLinks={footerLinks} />
    </ScalableWrapper>
  );
};
