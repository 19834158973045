import { Box } from '@mui/material';
import { FC, useState } from 'react';
import {
  availableTestsNotificationClose,
  availableTestsNotificationContainer,
  availableTestsNotificationPipe
} from './AvailableTestsNotification.styles';
import testsIcon from '../../assets/images/availableTestsNotification--tests.svg';
import closeIcon from '../../assets/images/availableTestsNotification--close.svg';
import { Img } from '../Primitive';
import { Trans } from 'react-i18next';

export interface AvailableTestsNotificationProps {
  count: number;
  action: () => void;
}

export const AvailableTestsNotification: FC<AvailableTestsNotificationProps> = ({
  count,
  action
}) => {
  const [closed, setClosed] = useState(false);

  return (
    <Box sx={[availableTestsNotificationContainer, { display: closed ? 'none' : 'flex' }]}>
      <Box sx={availableTestsNotificationPipe} />
      <Img sx={{ marginInline: '14px 10px' }} src={testsIcon} />
      <Box sx={{ py: '5px' }}>
        <Trans
          i18nKey='availableTestsNotification'
          components={{
            a: <a onClick={action} />
          }}
          values={{ count }}
        />
      </Box>
      <Img src={closeIcon} sx={availableTestsNotificationClose} onClick={() => setClosed(true)} />
    </Box>
  );
};
