import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { Img } from '@barracuda/shared/src/components/Primitive';
import {
  CounterBox,
  pendingProductTileFitWithContent,
  pendingProductTileProductName,
  pendingProductTile,
  pendingProductTileSkills,
  pendingProductTileTime,
  pendingProductTileAction,
  pendingProductTileSkill
} from './PendingProductTile.styles';
import { SkillBullet } from '@barracuda/shared/src/components/PracticeTestLabel/PracticeTestLabel.style';
import ClockIcon from '@sharedImages/clock-updated.svg';
import { useTranslation } from 'react-i18next';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import { buttonOutlineDark } from '@barracuda/shared/src/styles/button';
import { DashboardOrderTransactionProduct } from '../DashboardAccordionsWrapper/DashboardAccordionWrappers';

export interface PendingProductTileProps {
  product: DashboardOrderTransactionProduct;
  data: { skills: string[]; time: number };
}

export const PendingProductTile: FC<PendingProductTileProps> = ({ product, data }) => {
  const { t } = useTranslation();
  const navigate = useLocalizedNavigate();
  const showSkills = useMediaQuery(
    '(min-width: 900px) and (max-width: 1385px), (max-width: 600px)'
  );

  return (
    <>
      <Box sx={pendingProductTile}>
        <Img sx={{ width: '26%', minWidth: '100px' }} src={product.imageUrl} />
        {product.quantity > 1 && (
          <Box sx={CounterBox}>
            {t('dashboardPage.productTileCounter', { items: product.quantity })}
          </Box>
        )}
        <Box sx={{ marginLeft: '20px', flexGrow: 1 }}>
          <Typography
            sx={[
              pendingProductTileProductName,
              product.quantity > 1 ? pendingProductTileFitWithContent : {}
            ]}
          >
            {product.name}
          </Typography>
          {showSkills && (
            <Box sx={pendingProductTileSkills}>
              {data.skills.map((skill, index) => (
                <Box key={`skill-${index}`} sx={pendingProductTileSkill}>
                  <Typography>{skill}</Typography>{' '}
                  {index !== data.skills.length - 1 && <Box sx={SkillBullet} />}
                </Box>
              ))}
            </Box>
          )}
          <Box sx={pendingProductTileTime}>
            <Img sx={{ paddingRight: '5px' }} src={ClockIcon} />
            <Typography>
              {data?.time} {t('dashboardPage.min')}
            </Typography>
          </Box>
          <Button
            onClick={() => navigate('/dashboard/account', { state: { activeTabIndex: 1 } })}
            sx={[buttonOutlineDark, pendingProductTileAction]}
          >
            {t('dashboardPage.viewOrderDetails')}
          </Button>
        </Box>
      </Box>
    </>
  );
};
