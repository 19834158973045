import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack, Typography } from '@mui/material';
import {
  dashboardUploadIdContentContainerNewOne,
  dashboardUploadIdInstructionBox,
  dashboardUploadIdInstructionBoxTitle,
  dashboardUploadIdMobileButton,
  dashboardUploadIdProcessContainer
} from './DashboardUploadId.styles';
import { Img } from '../../../../shared/src/components/Primitive';
import UploadIdInstructionPhoto from '../../assets/images/mobile-upload-id-take-a-photo.svg';
import { ViewModeType } from './DashboardUploadId';
import { DashboardUploadIdResultMode } from './DashboardUploadIdResultMode';
import { DashboardUploadIdGuidelines } from './DashboardUploadIdGuidelines/DashboardUploadIdGuidelines';

const isResultModeArray = [
  'expired',
  'all-done',
  'already-submitted',
  'attempt-failed',
  'all-done-failed'
];

export interface DashboardUploadIdProcessModeProps {
  readonly viewMode: ViewModeType;
  readonly setViewMode: Dispatch<SetStateAction<ViewModeType>>;
  readonly manual: boolean;
  readonly flowRetry: () => void;
  readonly backIdSuccess: any;
  readonly onBackClick: () => void;
}

export const DashboardUploadIdProcessMode: FC<DashboardUploadIdProcessModeProps> = ({
  viewMode,
  setViewMode,
  onBackClick,
  flowRetry,
  manual
}) => {
  const { t } = useTranslation();
  const isResultMode = isResultModeArray.includes(viewMode);

  useEffect(() => {
    if (viewMode === 'normal') {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          stream.getTracks().forEach((track) => track.stop());
        })
        .catch((error) => {
          console.error('Error accessing camera', error);
        });
    }
  }, [viewMode]);

  return (
    <Stack sx={dashboardUploadIdProcessContainer}>
      <Box sx={dashboardUploadIdContentContainerNewOne}>
        {viewMode === 'normal' && (
          <Box sx={{ height: '100%' }}>
            <Box sx={dashboardUploadIdInstructionBox}>
              <Stack justifyContent='center' alignItems='center' sx={{ flex: 1 }}>
                <Img sx={{ paddingBottom: '24px' }} width={172} src={UploadIdInstructionPhoto} />
                <Typography sx={dashboardUploadIdInstructionBoxTitle}>
                  {t('mobileUploadId.takeAPhoto')}
                </Typography>
                <Typography sx={{ textAlign: 'center' }}>{t('mobileUploadId.toBegin')}</Typography>
              </Stack>

              <Button
                onClick={() => setViewMode('take-photo')}
                sx={[dashboardUploadIdMobileButton(), { marginBottom: '48px' }]}
              >
                {t('mobileUploadId.next')}
              </Button>
            </Box>
          </Box>
        )}
        {isResultMode && (
          <DashboardUploadIdResultMode viewMode={viewMode} manual={manual} flowRetry={flowRetry} />
        )}
        {viewMode === 'guidelines' && <DashboardUploadIdGuidelines onBackClick={onBackClick} />}
      </Box>
    </Stack>
  );
};
