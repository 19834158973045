import { Image } from 'image-js';

/*
  Image histogram is a distribution of pixel saturation, analogous to the chart on cameras and image editing programs,
  used for exposure and white balance optimization.
  This function combines all color values and distributes them into 8 buckets (0 to 32, 33 to 64 and so on).
  Underexposed images have a lot of their pixels in the first bucket (very low saturation),
  while overexposed images in the last bucket.
  Specific thresholds have to be arrived at empirically.
 */
export const exposureValidation = (
  image: Image
): 'UNDEREXPOSED' | 'OVEREXPOSED' | 'ACCEPTABLE_EXPOSURE' => {
  const histogram = image.combineChannels().getHistogram({ maxSlots: 8 });

  if (histogram[0] / image.size > 0.5) {
    return 'UNDEREXPOSED';
  }

  if (histogram[7] / image.size > 0.5) {
    return 'OVEREXPOSED';
  }

  return 'ACCEPTABLE_EXPOSURE';
};

/*
    Accutance is a rough measure of image sharpness
    This will require tweaking:
    - Accutance goes down if the image is over- or underexposed - we have to correct for that or prioritize the exposure rule
    - Our current measure is from 0 to 255; 0 to 1 might be more convenient
    - We have to set a threshold that roughly follows Regula rejections
    - Sharr filter, Sobel filter or a gradient filter with a custom kernel?
   */
export const accutanceValidation = (image: Image) => {
  const [accutance] = image.combineChannels().scharrFilter().getMean();
  if (accutance < 50) {
    return 'BLURRY';
  }
  return 'SHARP';
};

/* Regula documentations states that the minimum resolution for an ID photo is 1920x1080 pixels. */
export const resolutionValidation = (image: Image) => {
  const { width, height } = image;
  if (width >= 1920 || height >= 1080) {
    return 'ACCEPTABLE_RESOLUTION';
  }
  return 'LOW_RESOLUTION';
};

export type ImageValidationResult =
  | 'UNDEREXPOSED'
  | 'OVEREXPOSED'
  | 'BLURRY'
  | 'OK'
  | 'LOW_RESOLUTION';

export const imageValidation = async (filename: string): Promise<ImageValidationResult> => {
  const image = await Image.load(filename);
  const resolution = resolutionValidation(image);
  if (resolution !== 'ACCEPTABLE_RESOLUTION') {
    return resolution;
  }
  const exposure = exposureValidation(image);
  if (exposure !== 'ACCEPTABLE_EXPOSURE') {
    return exposure;
  }
  const accutance = accutanceValidation(image);
  if (accutance !== 'SHARP') {
    return accutance;
  }
  return 'OK';
};
