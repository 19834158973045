import { FC } from 'react';
import { Box, Stack, Button, Typography } from '@mui/material';
import { theme } from '@theme';
import { Trans, useTranslation } from 'react-i18next';
import { buttonDark, buttonOutlineDark } from '../../../../shared/src/styles/button';
import { useLocalizedNavigate } from '../../hooks/useLocalizedNavigate';
import {
  orderCancelledPopupButton1,
  orderCancelledPopupButton2,
  orderCancelledPopupButtons,
  orderCancelledPopupLink
} from './OrderCancelledPopup.styles';

export const OrderCancelledPopup: FC<{ orderNumber: string; handleClose: () => void }> = ({
  orderNumber,
  handleClose
}) => {
  const navigate = useLocalizedNavigate();
  const { t } = useTranslation();

  return (
    <Box sx={{ color: theme.palette.common.black }}>
      <Typography sx={{ mt: '24px', mb: '16px' }}>
        <Trans
          components={{ bold: <strong /> }}
          i18nKey='cancelledOrder.textLine1'
          values={{ orderNumber }}
        />
      </Typography>
      <Typography>
        <Trans
          components={{
            a: (
              <a
                style={orderCancelledPopupLink}
                href='https://pearson.tfaforms.net/3048'
                target='_blank'
              />
            )
          }}
          i18nKey='cancelledOrder.textLine2'
        />
      </Typography>
      <Stack justifyContent='flex-end' direction={{ sm: 'row' }} sx={orderCancelledPopupButtons}>
        <Button
          variant='outlined'
          sx={[buttonDark, orderCancelledPopupButton1]}
          onClick={() => {
            navigate('/dashboard/account', { state: { activeTabIndex: 1 } });
            handleClose();
          }}
        >
          {t('cancelledOrder.viewOrderHistory')}
        </Button>
        <Button sx={[buttonOutlineDark, orderCancelledPopupButton2]} onClick={handleClose}>
          {t('cancelledOrder.close')}
        </Button>
      </Stack>
    </Box>
  );
};
