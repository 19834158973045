import { Box } from '@mui/material';
import { FC, useState } from 'react';
import {
  ordersNotificationClose,
  ordersNotificationWrapper,
  ordersNotificationPipe
} from './OrdersNotification.styles';
import declinedIcon from '../../assets/images/ordersNotification--declined.svg';
import pendingIcon from '../../assets/images/ordersNotification--pending.svg';
import closeIcon from '../../assets/images/availableTestsNotification--close.svg';
import { Img } from '../Primitive';
import { Trans } from 'react-i18next';
import { OrderStatuses } from '../../interface/common.interfaces';

export interface OrdersNotificationProps {
  count?: number;
  action: () => void;
  type: OrderStatuses.CANCELLED | OrderStatuses.PENDING;
}

export const OrdersNotification: FC<OrdersNotificationProps> = ({ count, action, type }) => {
  const [closed, setClosed] = useState(false);

  return (
    <Box sx={[ordersNotificationWrapper, { display: closed ? 'none' : 'flex' }]}>
      <Box sx={ordersNotificationPipe(type)} />
      <Img
        sx={{ marginInline: '14px 10px' }}
        src={type === OrderStatuses.CANCELLED ? declinedIcon : pendingIcon}
      />
      <Box sx={{ py: '5px' }}>
        <Trans
          i18nKey={
            type === OrderStatuses.CANCELLED
              ? 'cancelledOrderNotification'
              : 'pendingProductsNotification'
          }
          components={{
            a: <a onClick={action} />
          }}
          values={{ count }}
        />
      </Box>
      <Img src={closeIcon} sx={ordersNotificationClose} onClick={() => setClosed(true)} />
    </Box>
  );
};
