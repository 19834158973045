import { theme } from '@theme';

export const dashboardUploadIdContainer = {
  '--counter-height': '64px',
  '--counter-top-space': '25px',
  '--content-container-top-space': '15px',
  '--content-container-bottom-space': '15px'
};

export const dashboardUploadIdProcessContainer = {
  padding: '20px',
  position: 'relative',
  height: '100vh',
  background: theme.palette.common.lightgrey13
};

export const dashboardUploadIdContentContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'fixed',
  left: '15px',
  right: '15px',
  overflowY: 'auto',
  overflowX: 'hidden'
};

export const dashboardUploadIdContentContainerNewOne = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100%',
  background: theme.palette.common.white,
  borderRadius: '12px'
};

export const dashboardUploadIdSubheader = {
  fontSize: '24px',
  fontWeight: 400,
  color: theme.palette.common.darkBlue1,
  marginBottom: '10px',
  textAlign: 'center'
};

export const dashboardUploadIdButton = {
  borderRadius: '79px',
  backgroundColor: theme.palette.common.lightGrey6,
  border: 'none',
  color: 'black',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.15)',
  textTransform: 'none',
  fontWeight: 600,
  padding: '15px',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '20px',
  '&:hover': {
    backgroundColor: theme.palette.common.lightGrey6,
    boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)'
  }
};

export const dashboardUploadIdTipsWrapper = {
  marginTop: '40px',
  display: 'flex',
  justifyContent: 'space-around',
  '> div': {
    position: 'relative'
  },
  img: {
    position: 'relative',
    zIndex: 0
  }
};

export const dashboardUploadIdThirdImageStyles = {
  '@media only screen and (min-width: 320px) and (max-width: 600px)': {
    marginLeft: '-5px',
    marginTop: '15px'
  }
};

export const dashboardUploadIdFourthImageStyles = {
  '@media only screen and (min-width: 320px) and (max-width: 600px)': {
    marginTop: '15px'
  }
};

export const dashboardUploadIdMobileTakePhotoContainer = {
  width: '100%',
  position: 'fixed',
  left: 0,
  height: '100vh',
  overflowY: 'auto'
};

export const dashboardUploadIdMobileTakePhotoTitles = {
  color: theme.palette.common.lightGrey6,
  width: '90%',
  position: 'absolute',
  left: 0,
  right: 0,
  top: '15%',
  margin: 'auto',
  zIndex: 500,
  textAlign: 'center'
};

export const dashboardUploadIdMobileTakePhotoButton = {
  position: 'fixed',
  bottom: '20px',
  left: 'calc(50% - 52px/2)',
  zIndex: 500
};

export const dashboardUploadIdPutYourIdTextTipsRecommendationText = {
  marginTop: '20px',
  fontSize: '18px',
  fontWeight: 600,
  textAlign: 'center'
};

export const dashboardUploadIdTipsContainer = {
  background: theme.palette.common.lightGrey4,
  border: '1px solid #F0F0F0',
  borderRadius: '8px',
  padding: '12px 12px 30px 12px'
};

export const dashboardUploadIdAllDoneContainer = {
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: '15vh',
  padding: '48px 24px',
  textAlign: 'center'
};

export const dashboardUploadIdAllDoneTitle = {
  fontSize: '25px',
  fontWeight: 700,
  marginTop: '20px',
  textAlign: 'center'
};
export const dashboardUploadIdAllDoneDesc = {
  fontSize: '16px',
  fontWeight: 400,
  textAlign: 'center',
  marginTop: '15px'
};

export const FlexCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

export const dashboardUploadIdBulbContainer = {
  background: theme.palette.common.lightBlue0,
  border: `1px solid ${theme.palette.common.lightBlue02}`,
  borderRadius: '4px',
  width: '90%',
  padding: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '20px auto 30px auto',
  gap: '10px',
  '*': {
    fontSize: '13px'
  }
};

export const dashboardUploadIdVideo = (preview: boolean) =>
  ({
    margin: 'auto',
    marginTop: '30vh',
    display: 'block',
    objectFit: 'cover',
    zIndex: 101,
    height: '32%',
    borderRadius: '10px',
    border: `3px ${preview ? 'solid' : 'dashed'} white`
  } as const);

export const dashboardUploadIdMobilePhotoButtons = {
  position: 'absolute',
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '90%',
  display: 'flex',
  gap: '12px',
  bottom: '48px',
  padding: '23px'
};

export const dashboardUploadIdMobileButton = (color?: string, backgroundColor?: string) => ({
  height: '49px',
  alignItems: 'center',
  justifyContent: 'center',
  display: 'flex',
  padding: '6px 20px',
  gap: '10px',
  borderRadius: '40px',
  border: `2px solid ${color}`,
  backgroundColor: backgroundColor || theme.palette.common.darkGrey3,
  color: color || theme.palette.common.lightGrey6,
  fontWeight: 600,
  fontSize: '16px',
  width: '100%',
  marginTop: 'auto'
});

export const dashboardUploadIdInstructionBox = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexFlow: 'column',
  paddingInline: '24px'
};
export const dashboardUploadIdInstructionBoxTitle = {
  fontSize: '25px',
  fontWeight: 700,
  textAlign: 'center'
};

export const dashboardUploadIdSkipBack = {
  color: theme.palette.common.lightGrey6,
  fontSize: '16px',
  fontWeight: 600,
  width: '90%',
  margin: 'auto',
  textAlign: 'center',
  position: 'fixed',
  bottom: '120px',
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto'
};

export const dashboardUploadIdWhiteCross = {
  position: 'fixed',
  left: 20,
  top: 20,
  zIndex: 500
};
