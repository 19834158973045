import { sopClient, User } from '@barracuda/shared/src';
import { AxiosResponse } from 'axios';
import { useContext } from 'react';
import { AppContext } from '../context/App.context';

const useCancelledOrder = () => {
  const { setUser } = useContext(AppContext);

  const requestCancelledOrderIds = async (): Promise<string[]> => {
    const {
      data: { results }
    } = await sopClient.getOrdersByStatus('CANCELLED');

    return results.map((result: { essId: string }) => result.essId);
  };

  const getCancelledOrderIds = async (idsString: string | undefined) => {
    if (typeof idsString !== 'string') {
      return null;
    }
    const orders = JSON.parse(idsString);
    const orderIds = await requestCancelledOrderIds();
    return orderIds.filter((id) => orders.includes(id));
  };

  const getAllIdsButSpecified = (
    orderId: string | undefined,
    idsString: string | undefined
  ): string => {
    if (!idsString) {
      return JSON.stringify([]);
    }
    const pendingOrderIds: string[] = JSON.parse(idsString);
    const updatedOrderIds = pendingOrderIds.filter((id) => id !== orderId);
    return JSON.stringify(updatedOrderIds);
  };

  const removeOrderIdFromPending = (
    id: string | undefined,
    pendingOrderIdsString: string | undefined
  ) => {
    const newOrders = getAllIdsButSpecified(id, pendingOrderIdsString);
    sopClient
      .addMetadataToProfile({ PENDING_ORDERS: newOrders })
      .then((response: AxiosResponse<User>) => {
        setUser(response.data);
      });
  };

  const addOrderIdToPending = (id: string, pendingOrderIdsString: string | undefined) => {
    const pendingOrderIds: string[] = pendingOrderIdsString
      ? JSON.parse(pendingOrderIdsString)
      : [];
    if (!pendingOrderIds.includes(id)) {
      pendingOrderIds.push(id);
    }
    const newOrders = JSON.stringify(pendingOrderIds);
    sopClient
      .addMetadataToProfile({ PENDING_ORDERS: newOrders })
      .then((response: AxiosResponse<User>) => {
        setUser(response.data);
      });
  };

  return {
    getCancelledOrderIds,
    removeOrderIdFromPending,
    addOrderIdToPending
  };
};

export default useCancelledOrder;
