import { FC, useRef } from 'react';
import {
  dashboardUploadIdMobileButton,
  dashboardUploadIdMobilePhotoButtons,
  dashboardUploadIdMobileTakePhotoButton,
  dashboardUploadIdMobileTakePhotoContainer,
  dashboardUploadIdMobileTakePhotoTitles,
  dashboardUploadIdProcessContainer,
  dashboardUploadIdSkipBack,
  dashboardUploadIdVideo,
  dashboardUploadIdWhiteCross
} from './DashboardUploadId.styles';
import { Box, Button, Stack, Typography } from '@mui/material';
import Webcam from 'react-webcam';
import {
  idVerificationMinHeight,
  idVerificationMinWidth
} from '../../../../shared/src/utils/utils';
import { Img } from '../../../../shared/src/components/Primitive';
import TakePhotoButton from '../../assets/images/take-photo-button.svg';
import { Trans, useTranslation } from 'react-i18next';
import takeAPhotoBackground from '../../assets/images/takeAPhotoBackground.png';
import whiteCross from '../../assets/images/white-cross.svg';
import { CheckingPhotoError, CheckingPhotoLabel } from './CheckingPhotoLabel/CheckingPhotoLabel';
import { theme } from '@theme';

export interface DashboardUploadIdPhotoModeProps {
  captureMobile: (screenshotSrc: string | null) => void;
  step: 'init' | 'process' | 'success' | 'failed';
  side: 'front' | 'back';
  photo?: string;
  backPhoto?: string;
  onContinueClick: () => void;
  onRetryClick: () => void;
  onSubmitIdPhotosClick: () => void;
  onCloseClick: () => void;
  onGuidelineClick: () => void;
  photoValidationError: CheckingPhotoError;
}

export const DashboardUploadIdPhotoMode: FC<DashboardUploadIdPhotoModeProps> = ({
  onCloseClick,
  onGuidelineClick,
  captureMobile,
  onContinueClick,
  step,
  onSubmitIdPhotosClick,
  side,
  photo,
  backPhoto,
  onRetryClick,
  photoValidationError
}) => {
  const { t } = useTranslation();
  const mobileWebcamRef = useRef<Webcam>(null);
  const takeScreenshot = () => mobileWebcamRef?.current?.getScreenshot() || null;

  const onCaptureClick = () => {
    if (mobileWebcamRef?.current?.state.hasUserMedia) {
      captureMobile(takeScreenshot());
    }
  };

  return (
    <Stack sx={[dashboardUploadIdProcessContainer, { background: `url(${takeAPhotoBackground})` }]}>
      <Img sx={dashboardUploadIdWhiteCross} onClick={onCloseClick} src={whiteCross} />
      <Box sx={dashboardUploadIdMobileTakePhotoContainer}>
        <Stack sx={dashboardUploadIdMobileTakePhotoTitles}>
          <Typography sx={{ fontWeight: 700, fontSize: '25px' }}>
            {t(`mobileUploadId.${side === 'back' ? 'backTitle' : 'frontTitle'}`)}
          </Typography>
          {step === 'init' && (
            <>
              <Typography sx={{ fontWeight: 400 }}>
                {t(`mobileUploadId.${side === 'back' ? 'backLineUp' : 'frontLineUp'}`)}
              </Typography>
              <Typography
                onClick={onGuidelineClick}
                sx={{ textDecoration: 'underline', fontSize: '16px' }}
              >
                {t('mobileUploadId.guidelinesLink')}
              </Typography>
            </>
          )}
        </Stack>
        {step !== 'init' ? (
          <Img
            sx={{ width: '90%' }}
            src={backPhoto || photo}
            style={dashboardUploadIdVideo(true)}
          />
        ) : (
          <Webcam
            screenshotFormat='image/jpeg'
            videoConstraints={{
              aspectRatio: 0.6,
              facingMode: { ideal: 'environment' }
            }}
            ref={mobileWebcamRef}
            minScreenshotWidth={idVerificationMinWidth}
            minScreenshotHeight={idVerificationMinHeight}
            width='90%'
            style={dashboardUploadIdVideo(false)}
          />
        )}
        {step === 'init' && side === 'back' && (
          <Box sx={dashboardUploadIdSkipBack}>
            <Trans
              i18nKey='mobileUploadId.usingPassport'
              components={{
                submit: (
                  <Typography
                    component='span'
                    sx={{ textDecoration: 'underline' }}
                    onClick={onSubmitIdPhotosClick}
                  ></Typography>
                )
              }}
            />
          </Box>
        )}
        {step !== 'init' ? (
          <CheckingPhotoLabel
            state={step}
            error={!photoValidationError ? undefined : photoValidationError}
          />
        ) : (
          <Img
            src={TakePhotoButton}
            onClick={onCaptureClick}
            sx={dashboardUploadIdMobileTakePhotoButton}
          ></Img>
        )}
        {step === 'success' ||
          (step === 'failed' && photoValidationError === 'ATTEMPT_LIMIT_EXCEEDED' && (
            <Stack sx={dashboardUploadIdMobilePhotoButtons}>
              <Button
                onClick={side === 'back' ? onSubmitIdPhotosClick : onContinueClick}
                sx={dashboardUploadIdMobileButton(
                  theme.palette.common.darkGrey2,
                  theme.palette.common.white
                )}
              >
                {t(`mobileUploadId.checkingPhoto.${side === 'back' ? 'submitPhotos' : 'continue'}`)}
              </Button>
              <Button
                onClick={onRetryClick}
                sx={dashboardUploadIdMobileButton(theme.palette.common.lightGrey6, 'unset')}
              >
                {t('mobileUploadId.checkingPhoto.retakePhoto')}
              </Button>
            </Stack>
          ))}
        {step === 'failed' && photoValidationError !== 'ATTEMPT_LIMIT_EXCEEDED' && (
          <Stack sx={{ ...dashboardUploadIdMobilePhotoButtons, gap: '36px' }}>
            <Button
              onClick={onRetryClick}
              sx={dashboardUploadIdMobileButton(
                theme.palette.common.darkGrey2,
                theme.palette.common.white
              )}
            >
              {t('mobileUploadId.checkingPhoto.retakePhoto')}
            </Button>
            <Box>
              <Typography
                onClick={onGuidelineClick}
                sx={{
                  textDecoration: 'underline',
                  fontSize: '16px',
                  color: theme.palette.common.white,
                  textAlign: 'center'
                }}
              >
                {t('mobileUploadId.guidelinesLink')}
              </Typography>
            </Box>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};
