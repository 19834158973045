import { theme } from '@theme';

export const takeTestAccordionWrapperNoTest = {
  background: theme.palette.common.white,
  border: '0.8px solid #727272',
  borderRadius: '8px',
  padding: { xs: '15px', md: '25px 20px' },
  display: 'flex',
  alignItems: 'center',
  height: '100%'
};

export const takeTestAccordionWrapperNoTestDescription = {
  display: 'flex',
  justifyContent: 'center',
  marginLeft: '30px'
};

export const takeTestAccordionWrapperLayout = {
  px: {
    md: '16px',
    xl: '65px'
  },
  pb: {
    xs: '20px',
    md: '50px',
    xl: '58px'
  }
};

export const takeTestAccordionWrapperTakeTestRefresh = {
  fontWeight: 600,
  ml: {
    sm: '32px'
  },
  mt: {
    xs: '5px',
    sm: 0
  },
  cursor: 'pointer'
};

export const takeTestAccordionWrapperTakeTestTitleText = {
  fontWeight: 600,
  fontSize: '18px',
  color: theme.palette.common.darkGrey2
};

export const takeTestAccordionWrapperCount = {
  fontSize: '14px',
  color: theme.palette.common.darkBlue2,
  fontWeight: 400,
  marginLeft: '12px'
};

export const takeTestAccordionWrapperNoTestText = {
  paddingBottom: '30px',
  '@media screen and (max-width: 700px)': {
    paddingBottom: '10px'
  }
};

export const takeTestAccordionWrapperTakeTestRefreshIcon = (reloading: boolean) => ({
  mr: '5px',
  animation: reloading ? 'spin 500ms linear infinite' : 'none',
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
});
