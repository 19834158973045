import { theme } from '@theme';

export const DashboardNavLink = {
  textDecoration: 'none',
  display: 'flex'
};

export const DashboardNavLinkActive = {
  background: '#ECEFF5'
};

export const DashboardListItem = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: '12px',
  color: theme.palette.common.darkGrey7,
  img: {
    marginBottom: '10px'
  }
};

const isWindows = /Win/i.test(navigator.userAgent);

export const DashboardOutlet = {
  paddingLeft: '140px',
  marginTop: 0,
  paddingTop: '90px',
  background: theme.palette.common.lightGrey4,
  '@media screen and (min-resolution: 1.5dppx)': {
    paddingTop: isWindows ? '60px' : '90px',
    paddingLeft: isWindows ? 'calc(140px * 0.67)' : '140px'
  },
  '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
    paddingTop: isWindows ? '70px' : '90px',
    paddingLeft: isWindows ? 'calc(140px * 0.8)' : '140px'
  },
  height: '100%',
  width: '100%',
  minHeight: '100vh',
  '@media only screen and (max-width: 1080px)': {
    padding: 0
  },
  '@media only print': {
    padding: 0
  }
};

export const DashboardList = {
  width: '140px',
  '@media screen and (min-resolution: 1.25dppx)': {
    width: '144px'
  }
};

export const DashboardSidebar = {
  '.MuiPaper-root': {
    justifyContent: 'center',
    zIndex: '1100'
  },
  '@media only screen and (max-width: 1080px)': {
    display: 'none'
  },
  '@media only print': {
    display: 'none'
  }
};

export const DashboardSidebarScaled = {
  ...DashboardSidebar,
  '.MuiPaper-root': {
    justifyContent: 'center',
    zIndex: '1100',
    '@media screen and (min-resolution: 1.25dppx) and (max-resolution: 1.49dppx)': {
      scale: '0.8',
      transformOrigin: '0 0',
      height: 'calc(100% * (1/0.8))'
    },
    '@media screen and (min-resolution: 1.5dppx)': {
      scale: '0.67',
      transformOrigin: '0 0',
      height: 'calc(100% * (1/0.67))'
    }
  }
};
