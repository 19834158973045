import { FC } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { FW600, MB10, MB20, AOSCTContentParagraph } from './AOSCTContent.styles';

interface AOSCTContentProps {
  t: (key: string) => string;
  language: string;
}

export const AOSCTContent: FC<AOSCTContentProps> = ({ t, language }) => {
  return (
    <>
      <Box
        sx={{
          fontFamily:
            language === 'jpn'
              ? '"Noto Sans JP", sans-serif !important'
              : '"Noto Sans", sans-serif !important',
          '> *': {
            fontFamily:
              language === 'jpn'
                ? '"Noto Sans JP", sans-serif !important'
                : '"Noto Sans", sans-serif !important'
          }
        }}
      >
        <Typography sx={[AOSCTContentParagraph, FW600, MB10]} component='h3'>
          {t('AOSCT.content.1.title')}
        </Typography>
        <Typography sx={[AOSCTContentParagraph, MB10]}>{t('AOSCT.content.1.text')}</Typography>
        <Typography sx={[AOSCTContentParagraph, FW600, MB10]} component='h3'>
          {t('AOSCT.content.2.title')}
        </Typography>
        <Typography sx={[AOSCTContentParagraph, MB20]}>{t('AOSCT.content.2.text')}</Typography>
        <Typography sx={[AOSCTContentParagraph, FW600, MB10]} component='h3'>
          {t('AOSCT.content.3.title')}
        </Typography>
        <Typography sx={[AOSCTContentParagraph, MB10]}>{t('AOSCT.content.3.text')}</Typography>
        <Typography sx={[AOSCTContentParagraph, MB10]}>{t('AOSCT.content.3.text2')}</Typography>
        <Typography sx={[AOSCTContentParagraph, MB20]}>
          {t('AOSCT.content.3.text3')}
          <Link href='mailto:jp-versant@pearson.com'>jp-versant@pearson.com</Link>
        </Typography>
        <Typography sx={[AOSCTContentParagraph, FW600, MB10]} component='h3'>
          {t('AOSCT.content.4.title')}
        </Typography>
        <Typography sx={[AOSCTContentParagraph, MB20]}>
          {t('AOSCT.content.4.text')}
          <Link href='https://www.versant.jp/campaign/englishcertificate/'>
            https://www.versant.jp/campaign/englishcertificate/
          </Link>
        </Typography>
        <Typography sx={[AOSCTContentParagraph, FW600, MB10]} component='h3'>
          {t('AOSCT.content.5.title')}
        </Typography>
        <Typography sx={[AOSCTContentParagraph, MB20]}>{t('AOSCT.content.5.text')}</Typography>
        <Typography sx={[AOSCTContentParagraph, FW600, MB10]} component='h3'>
          {t('AOSCT.content.6.title')}
        </Typography>
        <Typography sx={[AOSCTContentParagraph, MB20]}>{t('AOSCT.content.6.text')}</Typography>
        <Typography sx={[AOSCTContentParagraph, FW600, MB10]} component='h3'>
          {t('AOSCT.content.7.title')}
        </Typography>
        <Typography sx={[AOSCTContentParagraph, MB20]}>{t('AOSCT.content.7.text')}</Typography>
        <Typography sx={[AOSCTContentParagraph, FW600, MB10]} component='h3'>
          {t('AOSCT.content.8.title')}
        </Typography>
        <Typography sx={[AOSCTContentParagraph, MB20]}>{t('AOSCT.content.7.text')}</Typography>
        <Typography sx={[AOSCTContentParagraph, FW600, MB10]} component='h3'>
          {t('AOSCT.content.9.title')}
        </Typography>
        <Typography sx={[AOSCTContentParagraph, MB20]}>{t('AOSCT.content.9.text')}</Typography>
      </Box>
    </>
  );
};
