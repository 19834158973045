import { FC, useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/App.context';
import {
  AvailableTestsNotification,
  DialogWrapper,
  EventTypes,
  License,
  PracticeDownloadAppPopup,
  SoftProfileAllDone,
  sopClient,
  sopUtils,
  User,
  WhatCanDoNextTile
} from '@barracuda/shared/src';
import customProtocolCheck from 'custom-protocol-check';
import {
  GridBlueJourney,
  HeaderContainer,
  HeaderSubtitle,
  HeaderTitle,
  ImgPosition,
  HeaderTitleUsername,
  WelcomeBackMobile,
  GridContainer
} from './DashboardHeaderWrapper.style';
import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import AnchorVector from '@sharedImages/anchor-vector.svg';
import AnchorRotatedVector from '@sharedImages/anchor-rotated.svg';
import { Img } from '@barracuda/shared/src/components/Primitive';
import { isMobile } from '../../app.utils';
import { useTranslation, Trans } from 'react-i18next';
import { scrollToElement } from '@barracuda/shared/src/utils/utils';
import { useLocalizedNavigate } from 'src/hooks/useLocalizedNavigate';
import { theme } from '@theme';
import { sendSignedInGAEvent } from '../../utils/ga';
import { OrdersNotification } from '../../../../shared/src/components/OrdersNotification/OrdersNotification';
import { OrderStatuses } from '../../../../shared/src/interface/common.interfaces';

export type TileMode = 'practice/buy' | 'start/practice' | 'results/buy' | 'share/buy';

interface DashboardHeaderWrapperProps {
  buyTestExpanded: boolean;
  setBuyTestExpanded: (value: boolean) => void;
  startPracticeExpanded: boolean;
  setStartPracticeExpanded: (value: boolean) => void;
  takeTestExpanded: boolean;
  setTakeTestExpanded: (value: boolean) => void;
  setActivationCodeOpened: () => void;
  pendingProductsQuantity: number;
  hasCancelledOrders: boolean;
}

const hasNotLoggedBefore = (user: User | undefined) => !user?.profile?.loggedBefore;

export const DashboardHeaderWrapper: FC<DashboardHeaderWrapperProps> = ({
  buyTestExpanded,
  setBuyTestExpanded,
  setStartPracticeExpanded,
  startPracticeExpanded,
  takeTestExpanded,
  setTakeTestExpanded,
  setActivationCodeOpened,
  pendingProductsQuantity,
  hasCancelledOrders
}) => {
  const { user, availableTests } = useContext(AppContext);
  const [latestLicense, setLatestLicense] = useState<License | null>(null);
  const navigate = useLocalizedNavigate();
  const [beforeStartOpen, setBeforeStartOpen] = useState(false);
  const [beforeStartOpenMobile, setBeforeStartOpenMobile] = useState(false);
  const [tileMode, setTileMode] = useState<TileMode>('practice/buy');
  const below900px = useMediaQuery('(max-width: 900px');
  const below720px = useMediaQuery('(max-width: 720px');
  const { t } = useTranslation();

  const scrollToElementCustom = (id: string) => {
    switch (id) {
      case '#buyTest': {
        if (!buyTestExpanded) {
          setBuyTestExpanded(true);
        }
        break;
      }
      case '#startPractice': {
        if (!startPracticeExpanded) {
          setStartPracticeExpanded(true);
        }
        break;
      }
      case '#takeTest': {
        if (!takeTestExpanded) {
          setTakeTestExpanded(true);
        }
        break;
      }
    }
    setTimeout(() => {
      scrollToElement(id, 95);
    }, 500);
  };
  const computeTileMode = async () => {
    const response = await sopClient.getBlueJourneyTileData();
    const { data } = response;
    if (!data?.available && !data?.scored && !data?.shareable) {
      setTileMode('practice/buy');
    } else if (data?.available) {
      setTileMode('start/practice');
    } else if (!data?.available && data?.scored) {
      setTileMode('results/buy');
      setLatestLicense(data.scored);
    } else if (!data?.available && !data?.scored && data?.shareable) {
      setTileMode('share/buy');
      setLatestLicense(data.shareable);
    }
  };

  const viewResultsShareScoreAction = (license: License | null) => {
    if (license) {
      navigate(`results/test/${license.id}`);
    }
  };

  useEffect(() => {
    computeTileMode();
  }, []);

  const launchTest = (id: string) => {
    if (isMobile()) {
      setBeforeStartOpenMobile(true);
    } else {
      const anchor = document.createElement('a');
      anchor.href = `${process.env.REACT_APP_DEEPLINK_ID}://token=${sopUtils.getSopToken()}`;
      anchor.addEventListener('click', (event) => {
        event.preventDefault ? event.preventDefault() : (event.returnValue = false);
        customProtocolCheck(
          anchor.href,
          () => {
            scrollToElementCustom(id);
          },
          undefined,
          1000
        );
      });
      anchor.click();
      return;
    }
  };

  const handleCloseDialog = (value?: string) => {
    if (value && value === 'download') {
      sendSignedInGAEvent(EventTypes.APP_DOWNLOADED, user?.externalId);
    }
    setBeforeStartOpen(false);
  };

  const scrollToId = (id: string) => () => scrollToElementCustom(id);

  return (
    <>
      <Box sx={HeaderContainer}>
        <Grid container sx={GridContainer}>
          <Grid item md={6} sm={6} xs={12} sx={WelcomeBackMobile}>
            <Stack>
              <Box sx={{ position: 'relative' }}>
                <Typography component='h1' sx={HeaderTitle}>
                  <Trans
                    i18nKey={
                      hasNotLoggedBefore(user)
                        ? 'dashboardPage.welcome'
                        : 'dashboardPage.welcomeBack'
                    }
                    values={{ name: user?.firstName || user?.preferredName }}
                    components={{
                      highlight: <Typography component='span' sx={HeaderTitleUsername} />
                    }}
                  />
                </Typography>
                <Typography component='div' sx={HeaderSubtitle}>
                  <Trans
                    i18nKey='dashboardPage.headerText'
                    components={{
                      bold: (
                        <Typography
                          component={below900px ? 'span' : 'p'}
                          sx={{ fontWeight: 600 }}
                        />
                      )
                    }}
                  />
                </Typography>
                {hasCancelledOrders && (
                  <OrdersNotification
                    type={OrderStatuses.CANCELLED}
                    action={() => navigate('/dashboard/account', { state: { activeTabIndex: 1 } })}
                  />
                )}
                {pendingProductsQuantity > 0 && (
                  <OrdersNotification
                    type={OrderStatuses.PENDING}
                    action={scrollToId('#takeTest')}
                    count={pendingProductsQuantity}
                  />
                )}
                {!!availableTests && (
                  <AvailableTestsNotification
                    action={scrollToId('#takeTest')}
                    count={availableTests}
                  />
                )}
              </Box>
              {below720px && (
                <Box sx={{ position: 'relative', height: '150px' }}>
                  <Img sx={ImgPosition} src={AnchorRotatedVector} />
                </Box>
              )}
            </Stack>
          </Grid>
          <Grid item md={6} sm={6} xs={12} sx={GridBlueJourney}>
            <Box sx={{ position: 'relative' }}>
              {!below720px && <Img sx={ImgPosition} src={AnchorVector} />}
              <WhatCanDoNextTile
                viewResultsShareScoreAction={() => viewResultsShareScoreAction(latestLicense)}
                startTestAction={launchTest}
                tileMode={tileMode}
                buyTestAction={scrollToId('#buyTest')}
                handleRedeemCode={setActivationCodeOpened}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <DialogWrapper
        gridModifications={{ width: '980px' }}
        key='beforeStartDownload'
        open={beforeStartOpen}
        handleClose={() => setBeforeStartOpen(false)}
        scrollable
        subtitle={t('dashboardPage.beforeStartModalSubtitle')}
        title={t('dashboardPage.beforeStartModalTitle')}
        subtitleColor={theme.palette.common.violet}
        closeable
      >
        <PracticeDownloadAppPopup
          MSI_URL={process.env.REACT_APP_PESC_DOWNLOAD_MSI_PATH}
          MAC_URL={process.env.REACT_APP_PESC_DOWNLOAD_DMG_MAC_PATH}
          closeDialog={(value) => handleCloseDialog(value)}
        />
      </DialogWrapper>
      <DialogWrapper
        key='beforeStartDownloadMobile'
        open={beforeStartOpenMobile}
        handleClose={() => setBeforeStartOpenMobile(false)}
        scrollable
        subtitle={t('dashboardPage.getReady').toUpperCase()}
        title={t('dashboardPage.youAreAllDone')}
      >
        <SoftProfileAllDone closeDialog={() => setBeforeStartOpenMobile(false)} />
      </DialogWrapper>
    </>
  );
};
