import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Loader } from '@barracuda/shared/src/components/Loader/Loader';
import {
  CheckingPhotoLabelFailedSubtitle,
  CheckingPhotoLabelFailedTitle,
  CheckingPhotoLabelText
} from './CheckingPhotoLabel.styles';
import photoSuccess from '../../../assets/images/photoSuccess.svg';
import photoWarning from '../../../assets/images/photoWarning.svg';
import Img from '@barracuda/shared/src/components/Primitive/Img';
import { ImageValidationResult } from '../imageAnalysis';
import Stack from '@mui/material/Stack';

export type CheckingPhotoError = ImageValidationResult | null | 'ATTEMPT_LIMIT_EXCEEDED';

export const CheckingPhotoLabel: FC<{
  state: 'process' | 'success' | 'failed';
  error?: CheckingPhotoError;
}> = ({ state, error }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{ marginTop: '16px', paddingLeft: '29px', paddingRight: '29px' }}
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      {state === 'process' && <Loader loading size='20px' />}
      {state === 'success' && <Img src={photoSuccess} />}
      {state !== 'failed' && (
        <Typography sx={CheckingPhotoLabelText}>
          {t(`mobileUploadId.checkingPhoto.${state === 'process' ? 'checking' : 'success'}`)}
        </Typography>
      )}
      {state === 'failed' && (
        <Stack sx={{ textAlign: 'center' }}>
          <Typography sx={CheckingPhotoLabelFailedTitle}>
            {t(`mobileUploadId.checkingPhoto.${error}.title`)}
          </Typography>
          <Stack sx={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'start' }}>
            <Img src={photoWarning} sx={{ marginTop: '10px' }} />
            <Typography sx={CheckingPhotoLabelFailedSubtitle}>
              {t(`mobileUploadId.checkingPhoto.${error}.subtitle`)}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  );
};
