import { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Auth, sopClient } from '@barracuda/shared/src';
import { dashboardUploadIdContainer } from './DashboardUploadId.styles';
import { LottieRefCurrentProps } from 'lottie-react';
import { useSearchParams } from 'react-router-dom';
import { isMobile } from '../../app.utils';
import { UploadIdErrorScreen } from '../UploadIdErrorScreen/UploadIdErrorScreen';
import { UploadIdCounter } from '../UploadIdCounter/UploadIdCounter';
import { DashboardUploadIdPhotoMode } from './DashboardUploadIdPhotoMode';
import { DashboardUploadIdProcessMode } from './DashboardUploadIdProcessMode';
import { imageValidation, ImageValidationResult } from './imageAnalysis';

export type ViewModeType =
  | 'take-photo'
  | 'all-done'
  | 'expired'
  | 'normal'
  | 'already-submitted'
  | 'all-done-failed'
  | 'attempt-failed'
  | 'guidelines';

export const DashboardUploadId: FC = () => {
  const backIdSuccess = useRef<LottieRefCurrentProps>(null);
  const [searchParams] = useSearchParams();
  const manual = searchParams.get('manual') === 'true';
  const code = searchParams.get('code');
  const [token, setToken] = useState<string | null>(searchParams.get('token'));
  const [photo, setPhoto] = useState<string>('');
  const [backPhoto, setBackPhoto] = useState<string>('');
  const [viewMode, setViewMode] = useState<ViewModeType>('normal');
  const [photoStep, setPhotoStep] = useState<'init' | 'process' | 'success' | 'failed'>('init');
  const [photoSide, setPhotoSide] = useState<'front' | 'back'>('front');
  const showCounter = viewMode !== 'already-submitted' && viewMode !== 'all-done';
  const [photoValidationError, setPhotoValidationError] = useState<ImageValidationResult | null>(
    null
  );
  const [attemptCount, setAttemptCount] = useState(0);

  useEffect(() => {
    (async () => {
      if (!code && !token) {
        setViewMode('expired');
        return;
      }
      if (code) {
        const response = await sopClient.getTokenFromCode(code);
        setToken(response.data.token);
      }
    })();
  }, []);

  useEffect(() => {
    token && checkIfUploadIsPossible();
  }, [token]);

  const urlToFile = (url: string, filename: string) => {
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename);
      });
  };

  const checkIfUploadIsPossible = () => {
    sopClient.checkIfCanUploadId(token).then(
      (r) => {
        if (r.data?.canVerify) {
          setViewMode('normal');
          sopClient.userStartedVerification(token);
        } else {
          setViewMode('already-submitted');
        }
      },
      () => setViewMode('expired')
    );
  };

  useEffect(() => {
    if (photo || backPhoto) {
      imageValidation(photo || backPhoto).then((r) => {
        setAttemptCount((attemptCount) => attemptCount + 1);
        if (r !== 'OK') {
          setPhotoStep('failed');
          setPhotoValidationError(r);
        } else {
          setPhotoStep('success');
          setPhotoValidationError(null);
        }
      });
    }
  }, [photo, backPhoto]);

  const setExpired = () => setViewMode('expired');

  const captureMobile = async (screenshotSrc: string | null) => {
    setPhotoStep('process');
    if (screenshotSrc) {
      if (!photo) {
        setPhoto(screenshotSrc);
      } else {
        setBackPhoto(screenshotSrc);
      }
    }
  };

  const flowRetry = () => {
    if (status === 'rejected') {
      setPhoto('');
    } else {
      setBackPhoto('');
    }
    setViewMode('take-photo');
  };

  const sendSelectedIds = async () => {
    const form = new FormData();
    const imgFront = await urlToFile(photo, 'id-verification.jpg');
    form.append('page1', imgFront, 'id-verification.jpg');
    if (backPhoto) {
      const imgBack = await urlToFile(backPhoto, 'id-verification-back.jpg');
      form.append('page2', imgBack, 'id-verification-back.jpg');
    }
    form.append('device', 'mobile');
    form.append('source_flow', 'desktop');

    const idvFailedFeatureFlag = process.env.REACT_APP_IDV_FAILED_FEATURE_FLAG === 'true';

    sopClient.uploadUserId(form, token).then(
      (r) => {
        console.log(r);
        setViewMode('all-done');
      },
      (err) => {
        console.log(err);
        setViewMode(idvFailedFeatureFlag ? 'all-done-failed' : 'all-done'); // New screen will be implemented to handle idv verification fail. This is only for dev testing purposes.
      }
    );
  };

  const captureContinue = () => {
    setPhotoSide('back');
    setPhotoStep('init');
    setAttemptCount(0);
  };

  const resetView = () => {
    setPhoto('');
    setBackPhoto('');
    setViewMode('normal');
    setPhotoSide('front');
    setPhotoStep('init');
    setAttemptCount(0);
  };

  if (!isMobile()) return <UploadIdErrorScreen />;

  return (
    <Box sx={dashboardUploadIdContainer}>
      {showCounter && token && (
        <UploadIdCounter
          expirationTime={Auth.getQrCodeExpirationTime(token)}
          viewMode={viewMode}
          setExpired={setExpired}
        />
      )}
      {viewMode !== 'take-photo' && (
        <DashboardUploadIdProcessMode
          viewMode={viewMode}
          onBackClick={() => setViewMode('take-photo')}
          setViewMode={setViewMode}
          manual={manual}
          flowRetry={flowRetry}
          backIdSuccess={backIdSuccess}
        />
      )}
      {viewMode === 'take-photo' && (
        <DashboardUploadIdPhotoMode
          onCloseClick={resetView}
          onGuidelineClick={() => setViewMode('guidelines')}
          onSubmitIdPhotosClick={sendSelectedIds}
          onRetryClick={() => setPhotoStep('init')}
          onContinueClick={captureContinue}
          photo={photo}
          backPhoto={backPhoto}
          side={photoSide}
          captureMobile={captureMobile}
          step={photoStep}
          photoValidationError={attemptCount > 2 ? 'ATTEMPT_LIMIT_EXCEEDED' : photoValidationError}
        />
      )}
    </Box>
  );
};
