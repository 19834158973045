import { theme } from '@theme';

export const TextHeader = {
  fontWeight: 700,
  fontSize: '30px',
  lineHeight: 1.045,
  marginBottom: '63px',
  color: theme.palette.common.darkGrey3
};

export const TextContent = {
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: 1.6,
  '> a': {
    color: theme.palette.common.darkGrey2,
    fontWeight: 600,
    textDecoration: 'underline'
  },
  whiteSpace: 'pre-line',
  color: theme.palette.common.darkGrey2
};

export const ButtonsContainer = {
  display: 'flex',
  flexDirection: 'row',
  gap: '30px',
  marginTop: '60px',
  '@media only screen and (max-width: 740px)': {
    flexDirection: 'column'
  },
  '@media only print': {
    display: 'none'
  }
};

export const OrdersContainer = {
  maxWidth: '1181px',
  display: 'flex',
  flexDirection: 'row',
  gap: '70px',
  margin: 'auto',
  justifyContent: 'center',
  paddingLeft: '48px',
  paddingRight: '48px',
  '@media only screen and (min-width: 1513px)': {
    paddingTop: '135px'
  },
  '@media only screen and (max-width: 1512px) and (min-width: 1200px)': {
    paddingTop: '80px'
  },
  '@media only screen and (max-width: 1199px)': {
    paddingTop: '169px'
  },
  '@media only screen and (max-width: 1023px)': {
    flexDirection: 'column'
  },
  '@media only screen and (max-width: 740px)': {
    paddingLeft: '23px',
    paddingRight: '23px',
    paddingBottom: '80px'
  },
  '@media only print': {
    padding: 0,
    minWidth: '800px'
  },
  '[data-ui=CartOrderSummary]': {
    boxShadow: '0px 4px 4px 0px #00000040',
    maxWidth: 'unset'
  }
};

export const orderWrapperPrintWrapper = {
  position: 'absolute',
  right: '20px',
  top: '20px',
  display: 'flex'
};

export const orderWrapperPrint = {
  color: '#007FA3',
  fontSize: 14,
  cursor: 'pointer'
};

export const ErrorContainer = { display: 'flex', flexDirection: 'column', alignItems: 'center' };
