import { theme } from '@theme';

export const CheckingPhotoLabelText = {
  fontSize: '16px',
  color: theme.palette.common.lightGrey6,
  fontWeight: 400,
  marginLeft: '4px'
};

export const CheckingPhotoLabelFailedTitle = {
  color: theme.palette.common.white,
  fontSize: '25px',
  marginTop: '8px',
  fontWeight: '700',
  lineHeight: '34px'
};

export const CheckingPhotoLabelFailedSubtitle = {
  color: theme.palette.common.white,
  fontSize: '16px',
  marginTop: '8px',
  lineHeight: '26px'
};
