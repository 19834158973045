export const AOSCTContentParagraph = {
  fontWeight: 400,
  lineHeight: 1.5
};

export const MB20 = {
  marginBottom: '20px'
};

export const MB10 = {
  marginBottom: '10px'
};

export const FW600 = {
  fontWeight: 600
};
